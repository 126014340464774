import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import { Col, Container, Row } from '../../components/Grid'
import { Jumbotron } from '../../components/Jumbotron'
import { Spinner } from '../../components/Spinner'
import { LogoContainer } from '../../components/ui/home-page-layout'
import { authClient } from '../../util/auth-client'

const { REACT_APP_ENV } = process.env
const baseURL = '/api/shopify'

const Shopify = () => {
  const [activeOrDisabled, setActiveOrDisabled] = useState({
    disabled: true,
    isActive: true
  })
  const [checkingInstallStatus, setCheckingInstallStatus] = useState(false)
  const {
    user: { businessId: thryvId }
  } = useAuth0()
  const navigate = useNavigate()
  const axiosClient = authClient()

  const params = new URLSearchParams(window.location.search)
  const shopifyUsername = params.get('shop')
  const shopifyAccessToken = params.get('shop_access_token')

  useEffect(() => {
    async function getInstalledStatus() {
      setCheckingInstallStatus(true)
      try {
        const installationStatusURL = `${baseURL}/installation-status/${thryvId}`

        const {
          data: { isInstalled }
        } = await axiosClient(installationStatusURL)
        setCheckingInstallStatus(false)
        if (isInstalled === true) {
          navigate('/shopify-settings?status=existing')
        }
      } catch (err) {
        setCheckingInstallStatus(false)
        toast.error(
          `An error occurred checking Shopify's installation status. Please contact Thryv Support if this issue persists.`
        )
      }
    }
    getInstalledStatus()
  }, [])

  const handleChange = e => {
    if (e.target.checked) {
      setActiveOrDisabled(prevState => ({
        ...prevState,
        disabled: false,
        isActive: false
      }))
    } else {
      setActiveOrDisabled(prevState => ({
        ...prevState,
        disabled: true,
        isActive: true
      }))
    }
  }

  const handleClick = async e => {
    e.preventDefault()
    !(async () => {
      try {
        const payload = {
          thryvId,
          shopifyAccessToken,
          shopifyUsername,
          environment: REACT_APP_ENV
        }
        await axiosClient({
          method: 'post',
          url: `${baseURL}/install`,
          data: payload
        })

        navigate('/shopify-settings?status=new')
      } catch (err) {
        toast.error(
          'An error occurred while installing Shopify. Please contact Thryv support if the issue persists.'
        )
      }
    })()
  }

  return (
    <Container fluid>
      {checkingInstallStatus && <Spinner />}
      <ToastContainer />
      <Jumbotron>
        <LogoContainer>
          <img
            src="https://thryvapps.wpengine.com/media/thryvappSM.png"
            style={{
              marginRight: '10px',
              height: '75px',
              width: '75px',
              marginTop: '-20px',
              borderRadius: '10%'
            }}
          />
          <i className="fa fa-exchange fa-2x"></i>
          <img
            src="https://thryvapps.wpengine.com/media/shopify.png"
            style={{
              marginLeft: '20px',
              height: '80px',
              width: '80px',
              marginTop: '-20px',
              borderRadius: '10%'
            }}
          />
        </LogoContainer>
        <p className="lead mt-5 step1">
          <strong>STEP 2:</strong> Shopify is requesting permission to access
          your Thryv account.
        </p>
      </Jumbotron>
      <Row className="justify-content-center">
        <Col size="md-6">
          <div className="card">
            <h5 className="card-header txt17 p-4">
              What will this application need access to?
            </h5>
            <div className="card-body pt-3 pb-3">
              <p className="card-title pt-3 pb-3">
                <span
                  className="fa fa-check-circle ml-4 mr-2"
                  style={{ color: 'green', fontSize: 24 }}
                ></span>
                Customers.
              </p>
            </div>
            <hr></hr>
            <div className="card-body pt-3 pb-3">
              <p className="card-title pt-3 pb-3">
                <span
                  className="fa fa-check-circle ml-4 mr-2"
                  style={{ color: 'green', fontSize: 24 }}
                ></span>
                Payments.
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col size="md-6">
          <div className="card">
            <h5 className="card-header txt17 p-4">
              What will this application do?
            </h5>
            <div className="card-body pt-3 pb-3">
              <p className="card-title pt-3 pb-3">
                <span
                  className="fa fa-check-circle ml-4 mr-2"
                  style={{ color: 'green', fontSize: 24 }}
                ></span>
                Sync customers from Shopify to Thryv.
              </p>
            </div>
            <hr></hr>
            <div className="card-body pt-3 pb-3">
              <p className="card-title pt-3 pb-3">
                <span
                  className="fa fa-check-circle ml-4 mr-2"
                  style={{ color: 'green', fontSize: 24 }}
                ></span>
                Sync full payments from Shopify to Thryv.
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col size="md-6">
          <div className="txt12" style={{ paddingBottom: 25, paddingTop: 15 }}>
            Thryv® Marketplace is an aggregated offering of Applications that
            may be of interest or use to you. Applications not branded Thryv®
            are provided by third-party developers, licensors or distributors.
            When you link out from the Marketplace’s app details page to a
            third-party site, you agree that your business information will be
            passed to that provider’s site for the purpose of facilitating a
            transaction with that provider. Your purchase, download or use of
            third-party applications is governed by such third party’s terms and
            conditions and privacy policy, as applicable. Thryv, Inc. disclaims
            all liability for the functionality of any third-party applications
            and, unless otherwise expressly provided, for their interoperability
            with Thryv.
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col size="md-6" className="text-center">
          {activeOrDisabled.isActive && (
            <span style={{ color: 'red' }}>
              *Please accept our Terms & Conditions.
            </span>
          )}
          <form style={{ paddingBottom: 15 }}>
            <input type="checkbox" onChange={handleChange} />{' '}
            <span className="txt12">
              By checking this box, client agrees to our{' '}
              <u>
                <a
                  className="thryv-blue semibold"
                  style={{ color: '#666' }}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://corporate.thryv.com/thryv-terms/"
                >
                  Terms &amp; Conditions
                </a>
              </u>
              .
            </span>
          </form>
          <button
            className="btn btn-fill-orange"
            disabled={activeOrDisabled.disabled}
            onClick={handleClick}
          >
            Submit
          </button>
        </Col>
      </Row>
    </Container>
  )
}

export default Shopify
