import {
  NavbarContainer,
  NavbarTitle,
  NavbarButtonGroup,
  NavbarButton,
  NavbarSeparator
} from '../../../components/ui/ringcentral-style'

const RingCentralNavbar = ({
  openSettingView,
  openSpamFilter,
  setShouldShowUnSubscribeModal
}) => {
  return (
    <NavbarContainer>
      <NavbarTitle>RingCentral</NavbarTitle>
      <NavbarButtonGroup>
        <NavbarButton onClick={() => openSettingView()}>Settings</NavbarButton>
        <NavbarSeparator>|</NavbarSeparator>
        <NavbarButton onClick={() => openSpamFilter()}>
          Spam Filter
        </NavbarButton>
        <NavbarSeparator>|</NavbarSeparator>
        <NavbarButton onClick={() => setShouldShowUnSubscribeModal(true)}>
          Unsubscribe
        </NavbarButton>
      </NavbarButtonGroup>
    </NavbarContainer>
  )
}

export default RingCentralNavbar
