import { Modal, ModalTitle, ParagraphText as Text } from '@thryvlabs/maverick'

import thryvExcitedGuy from '../../assets/thryvguy_excited.png'

const CloverSuccessModal = ({ closeModal }) => {
  return (
    <Modal
      variant="default"
      btnActionText="Back to App Center"
      btnAction={closeModal}
      onClose={closeModal}
      openOnLoad
      hideX
      footer
      action
      tabIndex={0}
    >
      <ModalTitle>
        <span className="flex justify-center">Success!</span>
      </ModalTitle>
      <div className="text-center">
        <Text variant="reg">
          Congrats! You have integrated your Thryv with Clover.
        </Text>
        <img className="mx-4 mt-5 mx-auto" src={thryvExcitedGuy} />
      </div>
    </Modal>
  )
}

export default CloverSuccessModal
