import { useState } from 'react'

import { Input, Modal, ModalTitle } from '@thryvlabs/maverick'

import {
  TitleInputContainer,
  InputContainer,
  ModalContainer
} from '../ui/twilio-style'

export default function EditEmailModal({ onSave, onCloseModal }) {
  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)

  function handleEmailInputChange(e) {
    setIsEmailValid(validateEmail(e.target.value))
    setEmail(e.target.value)
  }

  const validateEmail = email => {
    return /.+@.+\..+/.test(email)
  }

  return (
    <ModalContainer>
      <Modal
        variant="default"
        data-testid="edit-email-modal"
        altBtn={<></>}
        btnActionText="Save"
        btnAction={() => onSave({ email: email })}
        btnActionDisabled={!isEmailValid}
        onClose={onCloseModal}
        openOnLoad
        footerCancel
        hideX
        footer
        action
      >
        <TitleInputContainer>
          <ModalTitle variant="subtitle" size="md">
            Edit Email
          </ModalTitle>
          <InputContainer>
            <Input
              withLabel={true}
              labelType="floating"
              type="text"
              data-testid="email-input"
              value={email}
              onChange={handleEmailInputChange}
              placeholder="Email Address"
              variant="default"
              className="w-[500px]"
              errorMessage="Please enter a valid email address"
              isValid={isEmailValid}
            />
          </InputContainer>
        </TitleInputContainer>
      </Modal>
    </ModalContainer>
  )
}
