import { useEffect, useState } from 'react'

import {
  Table,
  TableContent,
  TableHeader,
  TableInfo,
  TableMenu,
  TableRow
} from '@thryvlabs/maverick'
import { useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import cloverAppLogo from '../../assets/clover.png'
import thryvAppLogo from '../../assets/thryvappSM.png'
import NavLayout from '../../components/nav-layout/nav-layout'
import {
  CloverAppLogo,
  TableCell,
  ThryvAppLogo
} from '../../components/ui/clover-style'
import { LogoContainer } from '../../components/ui/home-page-layout'
import { authClient } from '../../util/auth-client'

function CloverError() {
  const [error, setError] = useState({})
  const { id } = useParams()
  const axiosClient = authClient()

  useEffect(() => {
    const getImportError = async () => {
      try {
        const { data } = await axiosClient(`/api/clover/import-errors/${id}`)
        setError(data.error)
      } catch (error) {
        toast.error(
          `An error occurred fetching your Clover access token. Please contact Thryv Support if the issue persists.`
        )
      }
    }
    getImportError()
  }, [id])

  return (
    <>
      <ToastContainer />
      <NavLayout title={'Clover'} />
      <div className="wrapper pb-0 mb-5 pt-0">
        <div className="container-fluid">
          <div className="jumbotron jumbotron-fluid">
            <div className="container text-center">
              <LogoContainer>
                <ThryvAppLogo src={thryvAppLogo} alt="Thryv" />
                <i className="fa fa-exchange fa-2x"></i>
                <CloverAppLogo src={cloverAppLogo} alt="Clover" />
              </LogoContainer>
              <p className="lead mt-3">
                Here is the error that occurred during your Thryv and Clover
                configuration.
              </p>
            </div>
          </div>
          <div className="w-full justify-content-center align-self-center mt-5">
            <Table>
              <TableHeader title="Errors" />
              <TableInfo date={new Date()} data={[error]} label="Error(s)" />
              <TableMenu>
                <div className="flex justify-start items-center w-full">
                  <TableCell>
                    <h6 className="text-thryv-steel font-semibold font-montserrat text-[14px] capitalize">
                      Error
                    </h6>
                  </TableCell>
                  <TableCell>
                    <h6 className="text-thryv-steel font-semibold font-montserrat text-[14px] capitalize">
                      Clover Client ID
                    </h6>
                  </TableCell>
                  <TableCell>
                    <h6 className="text-thryv-steel font-semibold font-montserrat text-[14px] capitalize">
                      Clover Merchant ID
                    </h6>
                  </TableCell>
                  <TableCell>
                    <h6 className="text-thryv-steel font-semibold font-montserrat text-[14px] capitalize">
                      Thryv Business ID
                    </h6>
                  </TableCell>
                </div>
              </TableMenu>
              <TableContent>
                {error ? (
                  <TableRow variant="static" key={error?.id}>
                    <TableCell>
                      <span>{error.import_error}</span>
                    </TableCell>
                    <TableCell>
                      <span>{error.clover_client_id}</span>
                    </TableCell>
                    <TableCell>
                      <span>{error.clover_merchant_id}</span>
                    </TableCell>
                    <TableCell>
                      <span>{error.thryv_business_id}</span>
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableContent>
            </Table>
          </div>
        </div>
      </div>
    </>
  )
}

export default CloverError
