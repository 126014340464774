import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { toast, ToastContainer } from 'react-toastify'

import RingcentralNavbar from './Components/RingCentralNavbar'
import UnsubscribeModal from './Components/UnSubscribeModal'
import RingCentralSpamFilter from './RingCentralSpamFilter/RingCentralSpamFilter'
import Settings from './Settings/Settings'
import AuthLoader from '../../components/AuthenticatedApps/AuthLoader'
import InstallSuccessModal from '../../components/modals/install-success-modal'
import UninstallSuccessModal from '../../components/modals/uninstall-success-modal'
import { authClient } from '../../util/auth-client'

const SettingsSpamFilterContainer = () => {
  const [shouldShowSuccesModal, setShouldShowSuccessModal] = useState(false)
  const [shouldShowUnSubscribeModal, setShouldShowUnSubscribeModal] =
    useState(false)
  const [toggleSettingView, setToggleSettingView] = useState(true)
  const [shouldUpdate, setShouldUpdate] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [unsubscribed, setUnsubscribed] = useState(false)
  const [settingsOptions, setSettingsOptions] = useState({
    calllogs: null,
    contacts: null,
    quarantine: null,
    voicemail: null
  })
  const [accessToken, setAccessToken] = useState('')

  const {
    user: { businessId: thryvId }
  } = useAuth0()
  const axiosClient = authClient()

  const ringCentralServiceURL = `/api/ring-central`

  useEffect(() => {
    //checks user setting status- if any of the values are null this is the user's first visit so they must configure settings
    //otherwise, sets settings to selected options, sets should update true and redirects them to the spam filters
    const checkSettingsStatus = async () => {
      try {
        setIsLoading(true)
        const { data } = await axiosClient({
          url: `${ringCentralServiceURL}/user/${thryvId}`
        })

        const areAllSettingsNull = Object.values(data).every(
          setting => setting === null
        )

        if (areAllSettingsNull) {
          setSettingsOptions({
            calllogs: true,
            contacts: true,
            quarantine: true,
            voicemail: true
          })
        } else {
          setSettingsOptions({
            calllogs: data.calllogs,
            contacts: data.contacts,
            quarantine: data.quarantine,
            voicemail: data.voicemail
          })
          setAccessToken(data.access_token)
          setShouldUpdate(true)
          setToggleSettingView(false)
        }
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        toast.error(
          'Something went wrong while retrieving your RingCentral settings. If the problem persists please contact Thryv support.'
        )
      }
    }
    checkSettingsStatus()
  }, [])

  const handleSettingsChange = settingName => {
    setSettingsOptions(prevOptions => ({
      ...prevOptions,
      [settingName]: !prevOptions[settingName]
    }))
  }

  const submitSettings = async () => {
    const settingsObject = {
      thryvId,
      voicemail: settingsOptions.voicemail ? 1 : 0,
      contacts: settingsOptions.contacts ? 1 : 0,
      callLogs: settingsOptions.calllogs ? 1 : 0,
      quarantine: settingsOptions.quarantine ? 1 : 0,
      method: shouldUpdate ? 'update' : 'install'
    }

    try {
      setIsLoading(true)
      await axiosClient({
        method: 'patch',
        url: `${ringCentralServiceURL}/user`,
        data: settingsObject
      })
      setIsLoading(false)
      if (shouldUpdate) {
        openSpamFilter()
      } else {
        setShouldShowSuccessModal(true)
      }
    } catch (err) {
      setIsLoading(false)
      toast.error(
        'Something went wrong while updating your RingCentral settings. If the problem persists please contact Thryv support.'
      )
    }
  }

  const unSubscribeHandler = async () => {
    try {
      setIsLoading(true)
      await axiosClient({
        method: 'delete',
        url: `${ringCentralServiceURL}/user/unsubscribe/${thryvId}`
      })
      setUnsubscribed(true)
      setIsLoading(false)
    } catch (err) {
      toast.error(
        'Something went wrong while unsubscribing from RingCentral. If the problem persists please contact Thryv support.'
      )
      setIsLoading(false)
    }
  }

  const openSettingView = () => {
    setToggleSettingView(true)
  }

  const openSpamFilter = () => {
    setToggleSettingView(false)
  }

  return (
    <div>
      <ToastContainer />

      {isLoading ? (
        <AuthLoader />
      ) : (
        <>
          <InstallSuccessModal
            app="RingCentral"
            shouldShow={shouldShowSuccesModal}
            closeModal={() => {
              setShouldShowSuccessModal(false)
              setShouldUpdate(true)
              openSpamFilter()
            }}
          />

          <RingcentralNavbar
            openSettingView={openSettingView}
            openSpamFilter={openSpamFilter}
            setShouldShowUnSubscribeModal={setShouldShowUnSubscribeModal}
          />

          {toggleSettingView ? (
            <Settings
              settingsOptions={settingsOptions}
              handleSettingsChange={handleSettingsChange}
              submitSettings={submitSettings}
            />
          ) : (
            <RingCentralSpamFilter accessToken={accessToken} />
          )}

          <UnsubscribeModal
            shouldShow={shouldShowUnSubscribeModal}
            closeModal={() => setShouldShowUnSubscribeModal(false)}
            unSubscribeHandler={unSubscribeHandler}
          />

          <UninstallSuccessModal app="RingCentral" shouldShow={unsubscribed} />
        </>
      )}
    </div>
  )
}

export default SettingsSpamFilterContainer