import { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Button, Form, Header, Input } from '@thryvlabs/maverick'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import cloverAppLogo from '../../assets/clover.png'
import thryvLogo from '../../assets/thryvappSM.png'
import { ErrorText, FlexCol, Wrapper } from '../../components/ui/clover-style'
import {
  AppLogo,
  HeaderContainer,
  LogoContainer
} from '../../components/ui/home-page-layout'
import { authClient } from '../../util/auth-client'

const CloverRegister = () => {
  const { user } = useAuth0()
  const navigate = useNavigate()
  const axiosClient = authClient()

  const [merchantName, setMerchantName] = useState('')
  const [merchantId, setMerchantId] = useState('')
  const [merchantNameError, setMerchantNameError] = useState('')
  const [merchantIdError, setMerchantIdError] = useState('')

  const handleSignUp = async () => {
    setMerchantNameError('')
    setMerchantIdError('')

    if (!validateInput()) {
      return
    }

    try {
      await axiosClient({
        method: 'post',
        url: '/api/clover/register-merchant',
        data: {
          merchantName,
          merchantId,
          thryvId: user.businessId
        }
      })
      navigate('/clover-registered')
    } catch (error) {
      toast.error(
        'An error occurred registering Clover. Please contact Thryv Support if the issue persists.'
      )
    }
  }

  const validateInput = () => {
    let valid = true
    if (!merchantName) {
      setMerchantNameError('Please provide your Merchant Name.')
      valid = false
    }

    if (!merchantId) {
      setMerchantIdError('Please provide your Merchant ID.')
      valid = false
    }

    return valid
  }

  return (
    <>
      <Wrapper className="wrapper mb-5">
        <ToastContainer />

        <div className="container-fluid pb-3">
          <HeaderContainer style={{ paddingBottom: '20px' }}>
            <LogoContainer>
              <img
                src={thryvLogo}
                className="thryv-logo"
                data-testid="thryv-logo"
              />
              <i className="fa fa-exchange fa-2x"></i>
              <AppLogo
                appIconStyle={{
                  marginLeft: '20px',
                  height: '80px',
                  marginTop: '-20px'
                }}
              >
                <img
                  src={cloverAppLogo}
                  className="app-logo"
                  data-testid="app-logo"
                />
              </AppLogo>
            </LogoContainer>
            <Header fontWeight="normal" variant="h3">
              Want to sign up for a Thryv Clover integration?
            </Header>
          </HeaderContainer>

          <div className="row justify-content-center align-self-center mt-3">
            <div className="col-xs-12 col-lg-3 text-center">
              <Form className="w-full" handleOnSubmit={handleSignUp}>
                {() => (
                  <FlexCol>
                    <div className="w-full">
                      <Input
                        type="text"
                        withLabel
                        name="merchant name"
                        placeholder="Merchant Name"
                        labelType="floating"
                        variant="input-isolated"
                        value={merchantName}
                        className="w-full"
                        onChange={e => setMerchantName(e.target.value)}
                      />
                    </div>
                    {merchantNameError ? (
                      <ErrorText className="merchant-name-error txt12">
                        {merchantNameError}
                      </ErrorText>
                    ) : null}

                    <div className="w-full mt-3">
                      <Input
                        type="text"
                        withLabel
                        name="merchant id"
                        placeholder="Merchant ID"
                        labelType="floating"
                        variant="input-isolated"
                        value={merchantId}
                        onChange={e => setMerchantId(e.target.value)}
                      />
                    </div>
                    {merchantIdError ? (
                      <ErrorText className="merchant-id-error txt12">
                        {merchantIdError}
                      </ErrorText>
                    ) : null}
                  </FlexCol>
                )}
              </Form>

              <Button
                className="w-3/4 p-2 mt-4"
                variant="primary"
                onClick={handleSignUp}
              >
                Sign Up
              </Button>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  )
}

export default CloverRegister
