import { useNavigate } from 'react-router-dom'

import copperLogo from '../../assets/copperLogo.png'
import HomePageLayout from '../../components/home-page-layouts/home-page-layout'

const Copper = () => {
  const navigate = useNavigate()
  const handleClick = e => {
    e.preventDefault()
    navigate('/copper-settings')
  }
  const copperSections = [
    {
      header: 'What will this application need access to?',
      statements: ['Your User details', 'Client details', 'Leads details']
    },

    {
      header: 'What will this application do?',
      statements: [
        'Sync current Clients between Thryv and Copper',
        'Sync future Clients between Thryv and Copper',
        'Sync current Leads between Thryv and Copper',
        'Sync future Leads between Thryv and Copper'
      ]
    }
  ]

  const appIconStyle = {
    marginLeft: '15px',
    height: '80px',
    width: '300px',
    marginTop: '-18px'
  }

  return (
    <HomePageLayout
      sections={copperSections}
      onSubmit={handleClick}
      appName="Copper"
      appIconSource={copperLogo}
      appIconStyle={appIconStyle}
    />
  )
}

export default Copper
