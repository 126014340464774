import { useNavigate } from 'react-router-dom'

import jotformLogo from '../../assets/JotformLogo.png'
import HomePageLayout from '../../components/home-page-layouts/home-page-layout'

import '../../css/App.css'

const Jotform = () => {
  const appName = 'Jotform'
  const navigate = useNavigate()
  const handleClick = e => {
    e.preventDefault()
    navigate('/jotform-settings')
  }
  const jotFormSelections = [
    {
      header: 'What will this application need access to?',
      statements: [
        'Your user details',
        'Your form details',
        'Your form submissions'
      ]
    },
    {
      header: 'What will this application do?',
      statements: [
        'Add new Contacts in Thryv.',
        'Update existing Clients in Thryv.',
        'Inject the Jotform into the Inbox of Thryv as well as the conversation history on the client card.'
      ]
    }
  ]

  return (
    <HomePageLayout
      sections={jotFormSelections}
      onSubmit={handleClick}
      appName={appName}
      appIconSource={jotformLogo}
      appIconStyle={{ marginLeft: '10px', height: '80px', marginTop: '-20px' }}
    />
  )
}

export default Jotform
