import styled from '@emotion/styled'

export const Wrapper = styled.div`
  padding-top: 0;
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`

export const ErrorText = styled.div`
  color: red;
`

export const ThryvAppLogo = styled.img`
  margin-right: 10px;
`

export const CloverAppLogo = styled.img`
  margin-left: 15px;
  max-width: 300px;
  height: auto;
`

export const TableCell = styled.div`
  width: 25%;
`
