import { Modal, ModalTitle, ParagraphText } from '@thryvlabs/maverick/'

export default function UninstallSuccessModal({ app, shouldShow }) {
  const redirectAppMarket = () => {
    window.location.href = 'https://go.thryv.com/app/app-market'
  }

  return (
    <>
      {shouldShow && (
        <Modal
          variant="default"
          openOnLoad
          footer
          action
          btnActionText="App Market"
          btnAction={redirectAppMarket}
          altBtn={<></>}
          hideX
          modalClassNames="center"
          disableBackdropClick
        >
          <div className="text-center">
            <ModalTitle variant="subtitle" size="lg">
              Unsubscribed from {app}
            </ModalTitle>
            <ParagraphText variant="reg">
              You have successfully unsubscribed from the {app}
              application. You may close this tab at any time or click the
              button below to be redirected to the App Marketplace.
            </ParagraphText>
          </div>
        </Modal>
      )}
    </>
  )
}
