import { useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

import facebookLogo from '../../assets/facebook-logo.png'
import HomePageLayout from '../../components/home-page-layouts/home-page-layout'
import { appIconStyle } from '../../components/ui/app-icon-style'
import { authClient } from '../../util/auth-client'

export function Messenger() {
  const { user } = useAuth0()
  const navigate = useNavigate()
  const axiosClient = authClient()

  const handleClick = e => {
    e.preventDefault()
    navigate('/messenger-next')
  }

  useEffect(() => {
    async function checkUserInstall() {
      try {
        const { data } = await axiosClient(
          `/api/thryv-user/fbm/user/${user.businessId}`
        )
        if (data.isUser) {
          navigate('/messenger-next-uninstall')
        }
      } catch (error) {
        toast.error(
          'Failed to retrieve user installation status. Please contact Thryv Support if this issue persists.'
        )
      }
    }

    checkUserInstall()
  }, [])

  const messengerSections = [
    {
      header: 'What will this application do?',
      statements: [
        'Link your selected Facebook Business Page(s) to your Thryv account.',
        'Inject Facebook Messenger messages into your Thryv account.'
      ]
    }
  ]

  return (
    <>
      <ToastContainer />
      <HomePageLayout
        sections={messengerSections}
        onSubmit={handleClick}
        appName="Facebook"
        appIconSource={facebookLogo}
        appIconStyle={appIconStyle}
      />
    </>
  )
}
