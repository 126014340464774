import { useNavigate } from 'react-router-dom'

import hubSpotLogo from '../../assets/hubSpot-logo.svg'
import HomePageLayout from '../../components/home-page-layouts/home-page-layout'

import '../../css/App.css'

const HubSpot = () => {
  const appName = 'HubSpot'
  const navigate = useNavigate()
  const handleClick = e => {
    e.preventDefault()
    navigate('/hubspot-settings')
  }
  const hubSpotSections = [
    {
      header: 'What will this application need access to?',
      statements: ['Your User details', 'Client details', 'Estimate details']
    },

    {
      header: 'What will this application do?',
      statements: [
        'Sync current Clients between Thryv and HubSpot.',
        'Sync future Clients between Thryv and HubSpot.',
        'Sync current Estimates between Thryv and HubSpot.',
        'Sync future Estimates between Thryv and HubSpot.'
      ]
    }
  ]

  const appIconStyle = {
    height: '80px',
    width: '',
    marginTop: '-6px',
    marginLeft: '15px'
  }

  return (
    <HomePageLayout
      sections={hubSpotSections}
      onSubmit={handleClick}
      appName={appName}
      appIconSource={hubSpotLogo}
      appIconStyle={appIconStyle}
    />
  )
}

export default HubSpot
