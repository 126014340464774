import {
  ThryvLogo,
  Header,
  Modal,
  ParagraphText as Text,
  ModalTitle,
  Button
} from '@thryvlabs/maverick'

import { NavContainer } from '../ui/nav-layout'

function NavLayout({
  title,
  showButton,
  handleUninstall,
  handleSettingsClick,
  handleSpamFilterClick,
  showSettingsButton,
  showSpamFilterButton,
  customMessage
}) {
  return (
    <NavContainer data-testid="thryv-navbar">
      <ThryvLogo className="thryv-logo" data-testid="thryv-logo" />
      <Header fontWeight="medium" variant="h1" className="app-name">
        | {title}
      </Header>
      <div className="nav-buttons-container">
        {showSettingsButton && (
          <div className="nav-button-settings-container ">
            <Button variant="primary" onClick={handleSettingsClick}>
              Settings
            </Button>
          </div>
        )}
        {showSpamFilterButton && (
          <div className="nav-button-settings-container ">
            <Button variant="primary" onClick={handleSpamFilterClick}>
              Spam Filter
            </Button>
          </div>
        )}
        {showButton && (
          <div className="nav-button-uninstall-container ">
            <Modal
              id="uninstall-order-modal"
              data-testid="uninstall-modal-test"
              variant="default"
              btnText="Uninstall"
              btnType="primary"
              btnActionText="Uninstall"
              btnAction={handleUninstall}
              footer
              action
              footerCancel
            >
              <div>
                <ModalTitle variant="subtitle" size="lg">
                  <span className="flex justify-center">
                    Are you sure you want to uninstall this app?
                  </span>
                </ModalTitle>
                {!!customMessage && (
                  <Text variant="lg" className="text-center">
                    {customMessage}
                  </Text>
                )}
              </div>
            </Modal>
          </div>
        )}
      </div>
    </NavContainer>
  )
}

export default NavLayout
