import { useState } from 'react'

import { ParagraphText, Checkbox, Button } from '@thryvlabs/maverick'

import { TermsContainer, CheckBoxContainer } from '../../ui/home-page-layout'

const TermsAndConditions = ({ handleClick }) => {
  const [isTermsAccepted, setIsTermsAccepted] = useState(false)
  const termsNotAccepted = !isTermsAccepted

  const handleChange = () => {
    setIsTermsAccepted(!isTermsAccepted)
  }

  return (
    <>
      <TermsContainer>
        <ParagraphText
          variant="lg"
          color="thryv-black-700"
          className="terms-text"
        >
          Thryv® Marketplace is an aggregated offering of Applications that may
          be of interest or use to you. Applications not branded Thryv® are
          provided by third-party developers, licensors or distributors. When
          you link out from the Marketplace’s app details page to a third-party
          site, you agree that your business information will be passed to that
          provider’s site for the purpose of facilitating a transaction with
          that provider. Your purchase, download or use of third-party
          applications is governed by such third party’s terms and conditions
          and privacy policy, as applicable. Thryv, Inc. disclaims all liability
          for the functionality of any third-party applications and, unless
          otherwise expressly provided, for their interoperability with Thryv.
        </ParagraphText>
        {termsNotAccepted ? (
          <ParagraphText variant="sm" color="thryv-orange-500">
            *Please accept our Terms & Conditions.
          </ParagraphText>
        ) : null}
        <CheckBoxContainer>
          <Checkbox
            hasLabel
            name="HTML"
            isChecked={isTermsAccepted}
            onChange={handleChange}
            label="By checking this box, client agrees to our"
            data-testid="checkbox"
          />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://corporate.thryv.com/thryv-terms/"
          >
            Terms &amp; Conditions
          </a>
          .
        </CheckBoxContainer>
        <Button
          variant="primary"
          disabled={termsNotAccepted}
          onClick={handleClick}
          className="submit-button"
        >
          Submit
        </Button>
      </TermsContainer>
    </>
  )
}

export default TermsAndConditions
