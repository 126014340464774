import { useState, useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import Loader from 'react-loader-spinner'

import { authClient } from '../../util/auth-client'

const { REACT_APP_THRYV_URL } = process.env

export function GoogleAnalytics() {
  const [errorOccured, setErrorOccured] = useState(false)
  const { user } = useAuth0()
  const axiosClient = authClient()

  useEffect(() => {
    async function patchUserId() {
      try {
        await axiosClient({
          method: 'patch',
          url: `/api/thryv-user/ga4/install/${user.businessId}`
        })
        setErrorOccured(false)
        window.location.href = `${REACT_APP_THRYV_URL}/app/tracking_settings`
      } catch (err) {
        setErrorOccured(true)
      }
    }
    patchUserId()
  }, [])
  return (
    <>
      {errorOccured ? (
        <div className="center">
          <h1>Unauthorized User</h1>
        </div>
      ) : (
        <div className="centerSpinner">
          <Loader
            className="loading-dots"
            type="ThreeDots"
            color="#FF5000"
            height={80}
            width={80}
          />
        </div>
      )}
    </>
  )
}
