import { useState } from 'react'

import { Input, Modal, ModalTitle } from '@thryvlabs/maverick'

import {
  ModalContainer,
  ModalInputContainer,
  ModalTextBox
} from '../ui/twilio-style'

const UpdateTokensModal = ({ closeModal, onSave, updateUserInfo }) => {
  const [authToken, setAuthToken] = useState('')
  const [accountSID, setAccountSID] = useState('')

  const onCloseModal = () => {
    setAuthToken('')
    setAccountSID('')
    closeModal()
  }

  const onSaveModal = async () => {
    const twilioPhoneNumber = await onSave(authToken, accountSID, true)
    if (twilioPhoneNumber) {
      updateUserInfo({ authToken, accountSID, twilioPhoneNumber })
      closeModal()
    }
  }

  return (
    <>
      <ModalContainer>
        <Modal
          variant="default"
          btnActionText="Save"
          btnAction={onSaveModal}
          onClose={onCloseModal}
          cancelAction={onCloseModal}
          openOnLoad
          footerCancel
          footer
          action
        >
          <ModalTextBox>
            <ModalTitle variant="subtitle" size="md">
              Update Twilio Tokens
            </ModalTitle>
            <ModalInputContainer>
              <Input
                withLabel
                labelType="floating"
                name="twilio_account_sid"
                type="text"
                data-testid="account-sid-input"
                value={accountSID}
                onChange={e => setAccountSID(e.target.value)}
                placeholder="Account SID"
                variant="default"
                errorMessage="Field Required"
                isValid={accountSID?.length > 0}
              />
            </ModalInputContainer>
            <ModalInputContainer>
              <Input
                withLabel
                labelType="floating"
                name="twilio_access_token"
                type="text"
                data-testid="auth-token-input"
                value={authToken}
                onChange={e => setAuthToken(e.target.value)}
                placeholder="Auth Token"
                variant="default"
                errorMessage="Field Required"
                isValid={authToken?.length > 0}
              />
            </ModalInputContainer>
          </ModalTextBox>
        </Modal>
      </ModalContainer>
    </>
  )
}

export default UpdateTokensModal
