import { useEffect, useState } from 'react'

import { Button, ParagraphText } from '@thryvlabs/maverick'
import { ToastContainer, toast } from 'react-toastify'

import AuthLoader from '../../components/AuthenticatedApps/AuthLoader'
import InstallSuccessModal from '../../components/modals/install-success-modal'
import UpdateSuccessModal from '../../components/modals/update-success-modal'
import { SwitchButton } from '../../components/switch-button'
import TokensInputs from '../../components/twilio/tokens-inputs'
import UpdateTokensModal from '../../components/twilio/update-tokens-modal'
import {
  FlexColumn,
  FlexContainer,
  ListContainer,
  UnorderedList,
  SettingsContainer,
  TwilioParagraphSection,
  FlexRow
} from '../../components/ui/twilio-style'
import { authClient } from '../../util/auth-client'

function TwilioSettings({
  twilioUser,
  setShowSettingsButton,
  setShowTwilioSettings,
  setShowSpamFilter
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [userSettings, setUserSettings] = useState({
    phone: true,
    sms: true,
    spamFilter: true
  })
  const [pageState, setPageState] = useState({
    showSuccessModal: false,
    showTokensInputs: true,
    showTokensModal: false,
    showUpdatedModal: false
  })
  const [userInfo, setUserInfo] = useState({
    authToken: '',
    accountSID: '',
    twilioPhoneNumber: ''
  })

  const axiosClient = authClient()
  const thryvId = twilioUser.thryvId

  const toggleOption = option => {
    setUserSettings(prevState => ({
      ...prevState,
      [option]: !prevState[option]
    }))
  }

  const updatePageState = updates => {
    setPageState(prevState => ({
      ...prevState,
      ...updates
    }))
  }

  const updateUserInfo = updates => {
    setUserInfo(prevState => ({
      ...prevState,
      ...updates
    }))
  }

  const openTokensModal = () => {
    updatePageState({ showTokensModal: true })
  }

  const closeTokensModal = () => {
    updatePageState({ showTokensModal: false })
  }

  const goToSpamFilterView = () => {
    setShowSettingsButton(true)
    setShowTwilioSettings(false)
    setShowSpamFilter(true)
  }

  const submitTokens = async (accessToken, accountSID, updatedTokens) => {
    try {
      setIsLoading(true)
      const {
        data: { twilioPhoneNumber }
      } = await axiosClient({
        method: 'PATCH',
        url: `/api/twilio/users/tokens/${thryvId}`,
        data: {
          twilioAccountSID: accountSID,
          twilioAccessToken: accessToken
        }
      })
      setIsLoading(false)
      updatePageState({
        showUpdatedModal: true,
        showTokensInputs: false
      })
      updateUserInfo({ twilioPhoneNumber: twilioPhoneNumber })
      if (updatedTokens) {
        return twilioPhoneNumber
      }
    } catch (error) {
      setIsLoading(false)
      toast.error(
        'There was an error setting your Twilio tokens. Please contact Thryv Support if the issue persists.'
      )
    }
  }

  const submitSettings = async () => {
    const payload = {
      phone: userSettings.phone ? 1 : 0,
      sms: userSettings.sms ? 1 : 0,
      quarantine: userSettings.spamFilter ? 1 : 0
    }

    try {
      setIsLoading(true)
      await axiosClient({
        method: 'PATCH',
        url: `/api/twilio/users/phone/${thryvId}`,
        data: payload
      })
      setIsLoading(false)
      updatePageState({ showSuccessModal: true })
    } catch (err) {
      setIsLoading(false)
      toast.error(
        'Something went wrong while updating your Twilio settings. If the problem persists please contact Thryv Support.'
      )
    }
  }

  useEffect(() => {
    if (twilioUser) {
      const {
        twilioAccessToken,
        twilioAccountSid,
        phone,
        sms,
        quarantine,
        userHasIntegration
      } = twilioUser
      // checks if the user has already completed the integration process to match their settings
      if (userHasIntegration) {
        // sets the toggles off if the value from the db is 0
        if (phone === 0) {
          toggleOption('phone')
        }
        if (sms === 0) {
          toggleOption('sms')
        }
        if (quarantine === 0) {
          toggleOption('spamFilter')
        }
      }

      // shows masked tokens if the user has previously submitted tokens
      if (twilioAccessToken && twilioAccountSid) {
        updateUserInfo({
          authToken: twilioAccessToken,
          accountSID: twilioAccountSid
        })
        updatePageState({ showTokensInputs: false })
      }
      // display user's twilioPhoneNumber without making an additional call to backend
      if (twilioUser.twilioPhoneNumber) {
        updateUserInfo({ twilioPhoneNumber: twilioUser.twilioPhoneNumber })
      }
    }
  }, [])

  const settings = [
    {
      title: 'Would you like to sync incoming Twilio phone calls with Thryv?',
      sentence:
        'Switching this option on will allow new incoming call notifications to arrive in your Thryv inbox.',
      switchState: {
        onChange: () => toggleOption('phone'),
        name: 'phone_setting',
        isToggled: userSettings.phone
      }
    },
    {
      title: 'Would you like to sync SMS with Thryv?',
      sentence:
        'Switching this option on will allow new incoming SMS notifications to arrive in your Thryv inbox. Reply to messages directly from your Thryv Inbox.',
      switchState: {
        onChange: () => toggleOption('sms'),
        name: 'sms_setting',
        isToggled: userSettings.sms
      }
    },
    {
      title: 'Would you like to enable the Spam Filter?',
      sentence:
        'This will block any notifications from your inbox and function as a spam filter until you go to your Twilio Spam Filter Queue and choose to Release or Delete the message.',
      warning: !userSettings.spamFilter
        ? 'WARNING! Disabling the Spam Filter will permanently delete all of the current entries.'
        : '',
      switchState: {
        onChange: () => toggleOption('spamFilter'),
        name: 'spamfilter_setting',
        isToggled: userSettings.spamFilter
      }
    }
  ]

  return (
    <>
      <ToastContainer />

      {isLoading ? (
        <AuthLoader />
      ) : (
        <>
          <InstallSuccessModal
            app="Twilio"
            shouldShow={pageState.showSuccessModal}
            closeModal={() => {
              updatePageState({ showSuccessModal: false })
            }}
          />
          <SettingsContainer>
            <TokensInputs
              showTokensModal={openTokensModal}
              setTwilioTokens={submitTokens}
              userInfo={userInfo}
              setInputValue={updateUserInfo}
              showTokensInputs={pageState.showTokensInputs}
            />
            <FlexContainer>
              <FlexColumn>
                {settings.map(setting => {
                  return (
                    <FlexContainer key={setting.title}>
                      <TwilioParagraphSection>
                        <ParagraphText variant="lg" className="section-title">
                          {setting.title}
                        </ParagraphText>
                        <ListContainer>
                          <UnorderedList>
                            <li>
                              <ParagraphText variant="lg">
                                {setting.sentence}
                              </ParagraphText>
                            </li>
                            {setting.warning && (
                              <li>
                                <ParagraphText
                                  variant="lg"
                                  color="utility-error-danger"
                                >
                                  {setting.warning}
                                </ParagraphText>
                              </li>
                            )}
                          </UnorderedList>
                        </ListContainer>
                      </TwilioParagraphSection>
                      <SwitchButton
                        defaultValue={setting.switchState.isToggled}
                        changeFunc={setting.switchState.onChange}
                        disabled={pageState.showTokensInputs}
                      />
                    </FlexContainer>
                  )
                })}
              </FlexColumn>
            </FlexContainer>
            <FlexRow>
              {twilioUser.userHasIntegration && (
                <Button
                  variant="secondary"
                  className="back-button"
                  onClick={goToSpamFilterView}
                >
                  Back
                </Button>
              )}
              <div className="px-2">
                <Button
                  variant="primary"
                  onClick={() => submitSettings()}
                  disabled={pageState.showTokensInputs}
                >
                  Submit Settings
                </Button>
              </div>
            </FlexRow>
          </SettingsContainer>
          {pageState.showTokensModal && (
            <UpdateTokensModal
              closeModal={closeTokensModal}
              onSave={submitTokens}
              updateUserInfo={updateUserInfo}
            />
          )}
          {pageState.showUpdatedModal && (
            <UpdateSuccessModal
              closeModal={() => updatePageState({ showUpdatedModal: false })}
            />
          )}
        </>
      )}
    </>
  )
}

export default TwilioSettings
