import { Button, Header, ParagraphText } from '@thryvlabs/maverick'

export const SelectionDetails = ({ optionName, createXeroAccount }) => {
  return (
    <div className="col-10">
      <ParagraphText variant="reg" color="thryv-black-500" className="ml-5">
        Please select an account that you wish Thryv to write{' '}
        {optionName.toLowerCase()} to.
      </ParagraphText>
      <ParagraphText variant="reg" color="thryv-black-500" className="ml-5">
        If you do not wish to use one of your own accounts, Thryv can create an
        account for you! Just click &nbsp;
        <Button
          variant="text"
          level={1}
          onClick={async () => await createXeroAccount(`Thryv ${optionName}`)}
          data-testid={
            optionName === 'Invoices'
              ? 'create-invoice-account-button'
              : 'create-payment-account-button'
          }
          color="#FF5000"
        >
          here
        </Button>
        .
      </ParagraphText>
    </div>
  )
}

export const SuccessCard = ({ option }) => {
  return (
    <div className="row center mx-auto">
      <div className="col-6">
        <Header fontWeight="light" variant="h1">
          Success!
        </Header>
      </div>
      <div className="col-6">
        <ParagraphText
          variant="reg"
          color="thryv-black-500"
          className="text-justify"
        >
          We have successfully created a new account in Xero with Account Name:
          Thryv {option}. If you still wish to use one of your own accounts
          please select one from the drop down before clicking install and we
          will make sure to write all payments to that account.
        </ParagraphText>
      </div>
    </div>
  )
}

export const AccountExistsCard = ({ option }) => {
  return (
    <div className="row center mx-auto">
      <div className="col-6">
        <Header
          fontWeight="light"
          variant="h1"
          className="account-exists-header"
        >
          Attention!
        </Header>
      </div>
      <div className="col-6">
        <ParagraphText
          variant="reg"
          color="thryv-orange-500"
          className="text-justify"
        >
          It looks like you already have an account with the name Thryv {option}
          . We have selected that account for you; however, if you wish to use a
          different account, please select one from the drop down before
          clicking install.
        </ParagraphText>
      </div>
    </div>
  )
}
