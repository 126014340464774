import { Component } from 'react'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import logo from './../assets/thryv_guy_alternative.png'
import { Container } from '../components/Grid'
import { Jumbotron } from '../components/Jumbotron'

function SuccessModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h4 style={{ textAlign: 'center', color: 'green' }}>Error!</h4>
        <p>
          <br />
          {localStorage.getItem('error')}
        </p>
      </Modal.Body>
      <center>
        <Button
          onClick={props.onHide}
          style={{
            backgroundColor: '#ff5000',
            border: 'none',
            fontSize: '16px',
            marginBottom: '15px',
            width: '120px'
          }}
        >
          Ok
        </Button>
      </center>
    </Modal>
  )
}

class ErrorModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShow: true
    }

    this.oAuthData = {}
  }

  render() {
    return (
      <Container fluid>
        <Jumbotron fluid>
          <Container text-center>
            <div
              className="row"
              style={{
                margin: 'auto',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <img src={logo} alt="Thryv Guy mascot" />
            </div>
          </Container>
        </Jumbotron>

        <img src={logo} alt="Thryv Guy mascot" />

        <SuccessModal
          show={this.state.modalShow}
          onHide={() => {
            this.setState({ modalShow: false })
            window.location.href =
              'https://learn.thryv.com/hc/en-us/articles/4416846880781-Centralized-Inbox'
          }}
        />
      </Container>
    )
  }
}

export default ErrorModal
