import { useAuth0 } from '@auth0/auth0-react'

const LoginPopup = ({ error }) => {
  const { loginWithPopup } = useAuth0()

  return (
    <div
      className="flex flex-column justify-start items-center gap-2 pt-4"
      style={{ textAlign: 'center', marginTop: '45px' }}
    >
      <div>
        {error?.message
          ? `There was an error: ${error.message}. Please log in to access the application.`
          : 'Please sign in to access the application.'}
      </div>
      <button
        className="btn btn-fill-orange"
        type="button"
        style={{ marginTop: '15px' }}
        onClick={() => loginWithPopup()}
      >
        Login
      </button>
    </div>
  )
}

export default LoginPopup
