import { Button, Header, Input, ParagraphText } from '@thryvlabs/maverick'

import { InputRow, TokensInputsContainer } from '../ui/twilio-style'

function TokensInputs({
  showTokensModal,
  setTwilioTokens,
  userInfo,
  setInputValue,
  showTokensInputs
}) {
  const maskToken = str => str.slice(-20).replace(/.(?=.{4})/g, '*')
  const isSubmitDisabled = !userInfo.authToken || !userInfo.accountSID

  return (
    <TokensInputsContainer>
      <Header variant="h1" fontWeight="bold" className="title">
        Settings
      </Header>
      {showTokensInputs ? (
        <>
          <InputRow className="mt-4">
            <ParagraphText
              variant="lg"
              color="thryv-black-400"
              className="label-text"
            >
              Twilio Account SID:
            </ParagraphText>
            <Input
              className="input-box"
              variant="default"
              name="twilio_account_sid"
              type="text"
              data-testid="account-sid-input"
              value={userInfo.accountSID}
              placeholder="Account SID"
              onChange={e => setInputValue({ accountSID: e.target.value })}
            />
          </InputRow>
          <InputRow>
            <ParagraphText
              variant="lg"
              color="thryv-black-400"
              className="label-text"
            >
              Twilio Auth Token:
            </ParagraphText>
            <Input
              className="input-box"
              variant="default"
              name="twilio_access_token"
              type="text"
              data-testid="auth-token-input"
              value={userInfo.authToken}
              placeholder="Auth Token"
              onChange={e => setInputValue({ authToken: e.target.value })}
            />
          </InputRow>
          <Button
            variant="primary"
            onClick={() =>
              setTwilioTokens(userInfo.authToken, userInfo.accountSID)
            }
            disabled={isSubmitDisabled}
          >
            Set Twilio Tokens
          </Button>
        </>
      ) : (
        <>
          <InputRow className="mt-4">
            <ParagraphText
              className="label-text"
              variant="lg"
              color="thryv-black-400"
            >
              Twilio Account SID:
            </ParagraphText>
            <ParagraphText
              variant="reg"
              color="thryv-black-400"
              className="masked-text"
            >
              {maskToken(userInfo.accountSID)}
            </ParagraphText>
          </InputRow>
          <InputRow>
            <ParagraphText
              className="label-text"
              variant="lg"
              color="thryv-black-400"
            >
              Twilio Auth Token:
            </ParagraphText>
            <ParagraphText
              variant="reg"
              color="thryv-black-400"
              className="masked-text"
            >
              {maskToken(userInfo.authToken)}
            </ParagraphText>
          </InputRow>
          {userInfo.twilioPhoneNumber && (
            <InputRow>
              <ParagraphText
                className="label-text"
                variant="lg"
                color="thryv-black-400"
              >
                Twilio Phone Number:
              </ParagraphText>
              <ParagraphText
                variant="reg"
                color="thryv-thryv-black-400"
                className="masked-text"
              >
                {userInfo.twilioPhoneNumber}
              </ParagraphText>
            </InputRow>
          )}
          <Button variant="primary" onClick={showTokensModal}>
            Update Twilio Tokens
          </Button>
        </>
      )}
    </TokensInputsContainer>
  )
}

export default TokensInputs
