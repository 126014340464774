import SettingsLayout from '../../components/settings-layout/settings-layout'
import { appIdConfig } from '../../util/integry-app-id'

const TypeformSettings = () => {
  const appKey = process.env.REACT_APP_INTEGRY_APP_KEY
  const appId = appIdConfig.typeform //this should change for Typeform

  return <SettingsLayout appName="Typeform" appKey={appKey} appId={appId} />
}
export default TypeformSettings
