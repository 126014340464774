import SettingsLayout from '../../components/settings-layout/settings-layout'
import { appIdConfig } from '../../util/integry-app-id'

const QuickbooksOnlineSettings = () => {
  const appName = 'Quickbooks Online'
  const appKey = process.env.REACT_APP_INTEGRY_APP_KEY
  const appId = appIdConfig.quickbooksOnline

  return <SettingsLayout appName={appName} appKey={appKey} appId={appId} />
}
export default QuickbooksOnlineSettings
