import { Header, Button } from '@thryvlabs/maverick'

import SettingsItem from './SettingsItem'
import { Col, Row } from '../../../components/Grid'
import { HeaderContainer } from '../../../components/ui/ringcentral-style'

const Settings = ({
  settingsOptions,
  handleSettingsChange,
  submitSettings
}) => {
  const ringCentralSettingsOptions = [
    {
      value: settingsOptions.voicemail,
      setValue: () => handleSettingsChange('voicemail'),
      header: 'Subscribe to voicemails?',
      statement:
        'Switching this option on will allow new voicemail notifications to arrive in your Thryv inbox.'
    },
    {
      value: settingsOptions.contacts,
      setValue: () => handleSettingsChange('contacts'),
      header: 'Import your contacts?',
      statement:
        'This is a 1-time import of your existing RingCentral contacts.'
    },
    {
      value: settingsOptions.calllogs,
      setValue: () => handleSettingsChange('calllogs'),
      header: 'Subscribe to call logs?',
      statement:
        'Switching this option on will allow new incoming call notifications to arrive in your Thryv inbox.'
    },
    {
      value: settingsOptions.quarantine,
      setValue: () => handleSettingsChange('quarantine'),
      header: 'Spam Filter all incoming notifications?',
      statement: `This will block any notifications from your inbox and function as a filter until you go to your RingCentral Spam Filter and choose
            to Release or Delete the message.`,
      warning:
        'WARNING! Disabling the Spam Filter will permanently delete all of the current entries.'
    }
  ]

  return (
    <>
      <Row className="justify-content-center">
        <Col size="10">
          <HeaderContainer>
            <Header fontWeight="bold" variant="h1">
              Settings
            </Header>
          </HeaderContainer>
          <div className="text-center">
            {ringCentralSettingsOptions.map(item => (
              <SettingsItem
                key={item.header}
                header={item.header}
                statement={item.statement}
                itemValue={item.value}
                warning={item.warning}
                onChangeItem={item.setValue}
              />
            ))}
            <Button variant="primary" onClick={() => submitSettings()}>
              Submit Settings
            </Button>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Settings
