import thryvExcitedGuy from '../../assets/thryvguy_excited.png'
import NavLayout from '../../components/nav-layout/nav-layout'

const CloverRegistered = () => {
  return (
    <>
      <NavLayout title="Clover" />
      <div className="wrapper mt-5">
        <div className="container text-center">
          <p className="h2 black">
            Congrats! You have registered for the Thryv Clover App. We are
            preparing your app and will send a notification to your Thryv inbox
            when it is ready for the next step. You may now close this screen.
          </p>
          <img className="mx-4 mt-5 mx-auto" src={thryvExcitedGuy} />
        </div>
      </div>
    </>
  )
}

export default CloverRegistered
