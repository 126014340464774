import { Modal, ModalTitle, ParagraphText } from '@thryvlabs/maverick/'

function UnsubscribeModal({ closeModal, shouldShow, unSubscribeHandler }) {
  return (
    <>
      {shouldShow && (
        <Modal
          variant="default"
          openOnLoad
          footer
          action
          btnActionText="Unsubscribe"
          btnAction={unSubscribeHandler}
          altBtn={<></>}
          hideX
          modalClassNames="center"
          footerCancel
          cancelAction={() => closeModal()}
          disableBackdropClick
        >
          <div className="text-center">
            <ModalTitle variant="subtitle" size="lg">
              Are you sure you want to unsubscribe?
            </ModalTitle>
            <ParagraphText variant="reg">
              Unsubscribing from the RingCentral App will delete all existing
              quarantine entries and they will be unrecoverable. Please clear
              your quarantine first if you still have notifications you would
              like to approve.
            </ParagraphText>
          </div>
        </Modal>
      )}
    </>
  )
}

export default UnsubscribeModal
