import SettingsLayout from '../../components/settings-layout/settings-layout'
import { appIdConfig } from '../../util/integry-app-id'

const MailChimpSettings = () => {
  const appName = 'MailChimp'
  const appKey = process.env.REACT_APP_INTEGRY_APP_KEY
  const appId = appIdConfig.mailchimp

  return <SettingsLayout appName={appName} appKey={appKey} appId={appId} />
}
export default MailChimpSettings
