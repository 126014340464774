import { Component } from 'react'

import refreshIcon from '@iconify-icons/fa/refresh'
import { Icon } from '@iconify/react'
import axios from 'axios'
import Modal from 'react-modal'

import { Col, Container, Row } from '../../components/Grid'
import Nav from '../../components/Nav'
import '../../css/App.css'

const backendUrl = process.env.REACT_APP_BACKEND_URL

class MyObAccountingSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: [true, false, true],
      errorsList: [],
      checked: false,
      ongoing_client_thryv_checked: 0,
      errorsButtonDisplay: true,
      settingsButtonDisplay: false,
      errorsDisplay: false,
      settingsDisplay: true,
      errors: false,
      settings: true,
      ongoing_client_thryv_value: '',
      historicalClientsClicked: false,
      historicalInvoicesClicked: false,
      historicalPaymentsClicked: false,
      historicalClientsFromMYOBClicked: false,
      historicalInvoicesFromMYOBClicked: false,
      historicalPaymentsFromMYOBClicked: false,
      ongoingClientsClicked: false,
      ongoingInvoicesClicked: false,
      ongoingPaymentsClicked: false,
      ongoingClientsFromMYOBClicked: false,
      ongoingInvoicesFromMYOBClicked: false,
      ongoingPaymentsFromMYOBClicked: false,
      reAuthDisplay: '',
      updateDisplay: '',
      ongoingpaymentsfrommyobtooltip: true,
      ongoingclientsenabled: false,
      ongoingclientsfrommyobenabled: true,
      ongoingpaymentstooltip: true,
      ongoingpaymentsdisabled: true,
      ongoinginvoicesdisabled: true,
      ongoinginvoicesfrommyobdisabled: true,
      historicalpaymentsfrommyobdisabled: true,
      historicalpaymentsfrommyobtooltip: true,
      historicalinvoicesfrommyobtooltip: true,
      sync_from_date_codat_myob: '',
      historicalinvoicesfrommyobdisabled: true,
      historicalpaymentsenabled: false,
      historicalpaymentstooltip: true,
      historicalpaymentsdisabled: true,
      sync_from_date_codat: '',
      historicalinvoicesenabled: false,
      historicalinvoicesdisabled: true,
      historicalclientsenabled: false,
      ongoing: false,
      historical: false,
      showNext: false,
      historicalDisplay: 'none',
      ongoingDisplay: 'none',
      showPrevious: false,
      showOngoingNext: false,
      showHistoricalPrevious: false,
      show_historical_settings: 'hide',
      show_settings: 'hide',
      db_auth_status: '',
      ongoing_client_thryv: '',
      ongoing_client_codat: '',
      ongoing_payment_codat: '',
      ongoing_payment_thryv: '',
      ongoing_invoice_codat: '',
      ongoing_invoice_thryv: '',
      showHistorical: true,
      currency: '',
      code: '',
      checkedHistoricalInvoices: false,
      checkedHistoricalClients: false,
      checkedHistoricalClientsValue: 0,
      checkedHistoricalInvoicesValue: 0,
      checkedHistoricalPayments: false,
      checkedHistoricalPaymentsValue: 0,
      checkedHistoricalInvoicesFromMYOB: false,
      checkedHistoricalPaymentsFromMYOBValue: 0,
      checkedHistoricalInvoicesFromMYOBValue: 0,
      checkedHistoricalClientsFromMYOBValue: 0,
      checkedHistoricalClientsFromMYOB: false,
      checkedHistoricalPaymentsFromMYOB: false,
      checkedOngoingInvoices: false,
      checkedOngoingInvoicesValue: 1,
      checkedOngoingInvoicesFromMYOB: false,
      checkedOngoingInvoicesFromMYOBValue: 0,
      checkedOngoingPaymentsFromMYOB: false,
      checkedOngoingPaymentsFromMYOBValue: 1,
      checkedOngoingPayments: false,
      checkedOngoingPaymentsValue: 1,
      checkedOngoingClients: false,
      checkedOngoingClientsValue: 1,
      checkedOngoingClientsFromMYOB: false,
      checkedOngoingClientsFromMYOBValue: 1,
      isOpen: false,
      historicalinvoicestooltip: true,
      ongoinginvoicestooltip: true,
      ongoinginvoicesfrommyobtooltip: true,
      ongoingpaymentsfrommyobdisabled: true
    }

    this.handleChange = this.handleChange.bind(this)
    this.myRef = React.createRef()
    this.checkedHistoricalInvoices = React.createRef()
    this.checkedHistoricalInvoicesFromMYOB = React.createRef()
    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this)
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this)
  }

  handleChange(checked) {
    this.setState({ checked })
    this.handleCheckbox1()
  }

  onMouseEnterHandler(key) {
    this.setState({
      [key]: true
    })
  }

  onMouseLeaveHandler(key) {
    this.setState({
      [key]: false
    })
  }

  async componentWillMount() {
    let thryv_id = this.props.oauth.oauth.usersData.business_id
    let ongoing_invoice_thryv = ''
    let ongoing_invoice_codat = ''
    let ongoing_payment_thryv = ''
    let ongoing_payment_codat = ''
    let ongoing_client_thryv = ''
    let ongoing_client_codat = ''
    let db_auth_status = ''

    //code for setting db_auth_status, show_historical_settings , show_settings
    axios
      .get(`${backendUrl}/api/myob/thryvUser/getusertableinfo`, {
        params: {
          id: thryv_id
        }
      })
      .then(response => {
        let length = ''
        length = response.data.length
        if (length > 0) {
          this.setState({ show_historical_settings: 'hide' })
          while (length > 0) {
            ongoing_client_thryv =
              response.data[length - 1].ongoing_client_thryv
            ongoing_client_codat =
              response.data[length - 1].ongoing_client_codat
            ongoing_invoice_thryv =
              response.data[length - 1].ongoing_invoice_thryv
            ongoing_invoice_codat =
              response.data[length - 1].ongoing_invoice_codat
            ongoing_payment_thryv =
              response.data[length - 1].ongoing_payment_thryv
            ongoing_payment_codat =
              response.data[length - 1].ongoing_payment_codat
            db_auth_status = response.data[length - 1].status
            length = length - 1
          }
        } else {
          this.setState({ show_historical_settings: 'show' })
        }

        if (
          ongoing_invoice_thryv === '' ||
          ongoing_invoice_codat === '' ||
          ongoing_payment_thryv === '' ||
          ongoing_payment_codat === '' ||
          ongoing_client_thryv === '' ||
          ongoing_client_codat === ''
        ) {
          this.setState({ show_settings: 'show' })
        } else this.setState({ show_settings: 'hide' })
        this.setState({ db_auth_status: db_auth_status })
        this.setState({ ongoing_payment_thryv: ongoing_payment_thryv })
        this.setState({ ongoing_invoice_thryv: ongoing_invoice_thryv })
        this.setState({ ongoing_payment_codat: ongoing_payment_codat })
        this.setState({ ongoing_invoice_codat: ongoing_invoice_codat })
        this.setState({ ongoing_client_thryv: ongoing_client_thryv })
        this.setState({ ongoing_client_codat: ongoing_client_codat })

        if (this.state.ongoing_client_thryv == '1') {
          this.refs.checkedOngoingClients.value = 1
          this.refs.checkedOngoingClients.checked = true
          this.refs.checkedOngoingClients.defaultChecked = 1
          this.refs.checkedOngoingClientsFromMYOB.value = 1
          this.refs.checkedOngoingClientsFromMYOB.checked = true
          this.refs.checkedOngoingClientsFromMYOB.defaultChecked = 1
          this.state.checkedOngoingClientsValue = 1
          this.state.checkedOngoingClients = true
          this.state.ongoinginvoicesdisabled = false
          this.state.ongoinginvoicestooltip = false
          this.state.ongoingpaymentsfrommyob = true
          this.state.ongoinginvoicesfrommyobdisabled = false
          this.state.ongoinginvoicesfrommyobtooltip = false
          this.state.ongoingpaymentsfrommyobdisabled = true
        }

        if (this.state.ongoing_invoice_thryv == '1') {
          this.refs.checkedOngoingInvoices.value = 1
          this.refs.checkedOngoingInvoices.checked = true
          this.refs.checkedOngoingInvoices.defaultChecked = 1
          this.state.checkedOngoingInvoicesValue = 1
          this.state.checkedOngoingInvoices = true
          this.state.ongoingpaymentsdisabled = false
          this.state.ongoingpaymentstooltip = false
        }

        if (this.state.ongoing_payment_thryv == '1') {
          this.refs.checkedOngoingPayments.value = 1
          this.refs.checkedOngoingPayments.checked = true
          this.refs.checkedOngoingPayments.defaultChecked = 1
          this.state.checkedOngoingPaymentsValue = 1
          this.state.checkedOngoingPayments = true
          this.state.ongoingpaymentsdisabled = false
          this.state.ongoingpaymentstooltip = false
        }

        if (this.state.ongoing_invoice_codat == '1') {
          this.refs.checkedOngoingInvoicesFromMYOB.value = 1
          this.refs.checkedOngoingInvoicesFromMYOB.checked = true
          this.refs.checkedOngoingInvoicesFromMYOB.defaultChecked = 1
          this.state.checkedOngoingInvoicesFromMYOBValue = 1
          this.state.checkedOngoingInvoicesFromMYOB = true
          this.state.ongoingpaymentsfrommyobdisabled = false
          this.state.ongoingpaymentsfrommyobtooltip = false
        }

        if (this.state.ongoing_payment_codat == '1') {
          this.refs.checkedOngoingPaymentsFromMYOB.value = 1
          this.refs.checkedOngoingPaymentsFromMYOB.checked = true
          this.refs.checkedOngoingPaymentsFromMYOB.defaultChecked = 1
          this.state.checkedOngoingPaymentsFromMYOBValue = 1
          this.state.checkedOngoingPaymentsFromMYOB = true
          this.state.ongoingpaymentsfrommyobdisabled = false
          this.state.ongoingpaymentsfrommyobtooltip = false
        }

        if (this.state.show_historical_settings == 'show')
          this.setState({ historicalDisplay: '' })
        else this.setState({ historicalDisplay: 'none' })

        if (this.state.show_historical_settings == 'show')
          this.setState({ ongoingDisplay: 'none' })
        else this.setState({ ongoingDisplay: '' })

        if (this.state.show_historical_settings == 'show') {
          this.setState({ reAuthDisplay: 'none' })
        } else if (this.state.db_auth_status == 'Linked') {
          this.setState({ reAuthDisplay: 'none' })
        }

        if (this.state.show_historical_settings == 'show') {
          this.setState({ updateDisplay: 'none' })
        } else if (this.state.db_auth_status != 'Linked') {
          this.setState({ updateDisplay: 'none' })
        }
      })
      .catch(() => {})
  }

  async componentDidMount() {
    let currency = ''
    let access_token = localStorage.getItem('access_token')
    let final_token = 'Bearer ' + access_token
    axios
      .get(`${backendUrl}/api/myob/thryvUser/paymentsettings`, {
        params: {
          foo: 'bar',
          token: final_token
        }
      })
      .then(response => {
        currency = response.data
        this.state.currency = currency
      })
      .catch(error => {
        console.log(error.messages)
      })
  }

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen })
    setTimeout(() => {
      this.setState({ isOpen: !this.state.isOpen })
    }, 3000)
  }

  handleCheckboxClient = () => {
    this.state.historicalClientsClicked = true

    let checkedHistoricalClientsValue = this.refs.checkedHistoricalClients.value
    if (checkedHistoricalClientsValue == 0)
      this.setState({ checkedHistoricalClientsValue: 1 })

    if (checkedHistoricalClientsValue == 1)
      this.setState({ checkedHistoricalClientsValue: 0 })

    this.setState({
      checkedHistoricalClients: !this.state.checkedHistoricalClients
    })

    if (this.refs.checkedHistoricalClients.defaultChecked == 0) {
      this.setState({ historicalinvoicesdisabled: false })

      this.state.historicalinvoicestooltip = false
      this.state.historicalclientsenabled = true
    } else if (this.refs.checkedHistoricalClients.defaultChecked == 1) {
      this.refs.checkedHistoricalInvoices.defaultChecked = false
      this.state.checkedHistoricalInvoices = false
      this.state.checkedHistoricalInvoicesValue = 0
      this.setState({ historicalinvoicesdisabled: true })
      this.state.historicalinvoicestooltip = true

      this.refs.checkedHistoricalPayments.defaultChecked = false
      this.state.checkedHistoricalPayments = false
      this.state.checkedHistoricalPaymentsValue = 0
      this.setState({ historicalpaymentsdisabled: true })
      this.state.historicalpaymentstooltip = true
      this.state.historicalclientsenabled = false
      this.state.sync_from_date_codat = ''
      this.refs.checkedHistoricalInvoices.checked = false
      this.state.historicalinvoicesenabled = false
      this.refs.checkedHistoricalPayments.checked = false
    }
  }

  handleCheckbox1 = () => {
    this.state.historicalInvoicesClicked = true

    const settingObject = {
      thryvid: 'thryvid',
      accesstoken: 'thryvid2',
      environment: 'env',
      businessname: 'yg'
    }

    const dualArray = {
      test: 'test',
      setting: settingObject
    }

    let checkedHistoricalInvoicesValue =
      this.refs.checkedHistoricalInvoices.value
    if (checkedHistoricalInvoicesValue == 0) {
      this.setState({ checkedHistoricalInvoicesValue: 1 })
      this.setState({ historicalinvoicesenabled: true })
    }
    if (checkedHistoricalInvoicesValue == 1) {
      this.setState({ checkedHistoricalInvoicesValue: 0 })
      this.setState({ historicalinvoicesenabled: false })
    }

    this.setState({
      checkedHistoricalInvoices: !this.state.checkedHistoricalInvoices
    })

    if (this.refs.checkedHistoricalInvoices.defaultChecked == 0) {
      this.setState({ historicalpaymentsdisabled: false })
      this.state.historicalpaymentstooltip = false
      this.state.historicalinvoicesenabled = true
    } else if (this.refs.checkedHistoricalInvoices.defaultChecked == 1) {
      this.refs.checkedHistoricalPayments.defaultChecked = false
      this.state.checkedHistoricalPayments = false
      this.state.checkedHistoricalPaymentsValue = 0
      this.setState({ historicalpaymentsdisabled: true })
      this.state.historicalpaymentstooltip = true
      this.state.historicalinvoicesenabled = false
      this.setState({ sync_from_date_codat: '' })
      this.refs.checkedHistoricalPayments.checked = false
    }
    console.log(
      'checked historical invoices value is ' +
        this.state.checkedHistoricalInvoicesValue
    )
  }

  handleCheckbox7 = () => {
    this.state.historicalClientsFromMYOBClicked = true

    let checkedHistoricalClientsFromMYOBValue =
      this.refs.checkedHistoricalClientsFromMYOB.value
    if (checkedHistoricalClientsFromMYOBValue == 0)
      this.setState({ checkedHistoricalClientsFromMYOBValue: 1 })
    if (checkedHistoricalClientsFromMYOBValue == 1)
      this.setState({ checkedHistoricalClientsFromMYOBValue: 0 })
    this.setState({
      checkedHistoricalClientsFromMYOB:
        !this.state.checkedHistoricalClientsFromMYOB
    })

    if (this.refs.checkedHistoricalClientsFromMYOB.defaultChecked == 0) {
      this.setState({ historicalinvoicesfrommyobdisabled: false })
      this.state.historicalinvoicesfrommyobtooltip = false
      this.state.historicalclientsfrommyobenabled = true
    } else if (this.refs.checkedHistoricalClientsFromMYOB.defaultChecked == 1) {
      this.refs.checkedHistoricalInvoicesFromMYOB.defaultChecked = false
      this.state.checkedHistoricalInvoicesFromMYOB = false
      this.state.checkedHistoricalInvoicesFromMYOBValue = 0
      this.setState({ historicalinvoicesfrommyobdisabled: true })
      this.state.historicalpaymentsfrommyobtooltip = false
      this.state.historicalinvoicesfrommyobtooltip = true

      this.refs.checkedHistoricalPaymentsFromMYOB.defaultChecked = false
      this.state.checkedHistoricalPaymentsFromMYOB = false
      this.state.checkedHistoricalPaymentsFromMYOBValue = 0
      this.setState({ historicalpaymentsfrommyobdisabled: true })
      this.state.historicalpaymentsfrommyobtooltip = true
      this.state.historicalclientsfrommyobenabled = false
      this.state.sync_from_date_codat_myob = ''
      this.refs.checkedHistoricalInvoicesFromMYOB.checked = false
      this.refs.checkedHistoricalPaymentsFromMYOB.checked = false
      this.state.historicalinvoicesfrommyobenabled = false
    }
  }

  handleCheckbox2 = () => {
    this.state.historicalInvoicesFromMYOBClicked = true

    let checkedHistoricalInvoicesFromMYOBValue =
      this.refs.checkedHistoricalInvoicesFromMYOB.value
    if (checkedHistoricalInvoicesFromMYOBValue == 0)
      this.setState({ checkedHistoricalInvoicesFromMYOBValue: 1 })
    if (checkedHistoricalInvoicesFromMYOBValue == 1)
      this.setState({ checkedHistoricalInvoicesFromMYOBValue: 0 })
    this.setState({
      checkedHistoricalInvoicesFromMYOB:
        !this.state.checkedHistoricalInvoicesFromMYOB
    })

    if (this.refs.checkedHistoricalInvoicesFromMYOB.defaultChecked == 0) {
      this.setState({ historicalpaymentsfrommyobdisabled: false })
      this.state.historicalpaymentsfrommyobtooltip = false
      this.state.historicalinvoicesfrommyobenabled = true
    } else if (
      this.refs.checkedHistoricalInvoicesFromMYOB.defaultChecked == 1
    ) {
      this.refs.checkedHistoricalPaymentsFromMYOB.defaultChecked = false
      this.state.checkedHistoricalPaymentsFromMYOB = false
      this.state.checkedHistoricalPaymentsFromMYOBValue = 0
      this.setState({ historicalpaymentsfrommyobdisabled: true })
      this.state.historicalpaymentsfrommyobtooltip = true
      this.state.historicalinvoicesfrommyobenabled = false
      this.state.sync_from_date_codat_myob = ''
      this.refs.checkedHistoricalInvoicesFromMYOB.checked = false
      this.refs.checkedHistoricalPaymentsFromMYOB.checked = false
    }
  }

  handleCheckbox3 = () => {
    this.state.historicalPaymentsFromMYOBClicked = true
    let checkedHistoricalPaymentsFromMYOBValue =
      this.refs.checkedHistoricalPaymentsFromMYOB.value
    if (checkedHistoricalPaymentsFromMYOBValue == 0)
      this.setState({ checkedHistoricalPaymentsFromMYOBValue: 1 })
    if (checkedHistoricalPaymentsFromMYOBValue == 1)
      this.setState({ checkedHistoricalPaymentsFromMYOBValue: 0 })
    this.setState({
      checkedHistoricalPaymentsFromMYOB:
        !this.state.checkedHistoricalPaymentsFromMYOB
    })
  }

  handleCheckbox4 = () => {
    this.state.historicalPaymentsClicked = true
    let checkedHistoricalPaymentsValue =
      this.refs.checkedHistoricalPayments.value
    if (checkedHistoricalPaymentsValue == 0)
      this.setState({ checkedHistoricalPaymentsValue: 1 })
    if (checkedHistoricalPaymentsValue == 1)
      this.setState({ checkedHistoricalPaymentsValue: 0 })
    this.setState({
      checkedHistoricalPayments: !this.state.checkedHistoricalPayments
    })
  }

  handleCheckboxOngoing1 = () => {
    this.state.ongoingInvoicesClicked = true
    let checkedOngoingInvoicesValue = this.refs.checkedOngoingInvoices.value
    if (checkedOngoingInvoicesValue == 0)
      this.setState({ checkedOngoingInvoicesValue: 1 })
    if (checkedOngoingInvoicesValue == 1)
      this.setState({ checkedOngoingInvoicesValue: 0 })
    this.setState({
      checkedOngoingInvoices: !this.state.checkedOngoingInvoices
    })

    if (this.refs.checkedOngoingInvoices.defaultChecked == 0) {
      this.setState({ ongoingpaymentsdisabled: false })
      this.state.ongoingpaymentstooltip = false
      this.state.ongoinginvoicesenabled = true
    } else if (this.refs.checkedOngoingInvoices.defaultChecked == 1) {
      this.refs.checkedOngoingPayments.defaultChecked = false
      this.state.checkedOngoingPayments = false
      this.state.checkedOngoingPaymentsValue = 0
      this.setState({ ongoingpaymentsdisabled: true })
      this.state.ongoingpaymentstooltip = true
      this.state.ongoinginvoicesenabled = false
      this.state.sync_from_date_codat_myob = ''

      this.refs.checkedHistoricalInvoices.defaultChecked = false
      this.state.checkedHistoricalInvoices = false
      this.state.checkedHistoricalInvoicesValue = 0
      this.setState({ historicalinvoicesdisabled: true })
      this.state.historicalinvoicestooltip = true

      this.refs.checkedHistoricalPayments.defaultChecked = false
      this.state.checkedHistoricalPayments = false
      this.state.checkedHistoricalPaymentsValue = 0
      this.setState({ historicalpaymentsdisabled: true })
      this.state.historicalpaymentstooltip = true
      this.state.historicalclientsenabled = false
      this.state.sync_from_date_codat = ''
      this.refs.checkedOngoingPayments.checked = false
    }
  }

  handleCheckboxOngoing2 = () => {
    let checkedOngoingInvoicesFromMYOBValue =
      this.refs.checkedOngoingInvoicesFromMYOB.value
    if (checkedOngoingInvoicesFromMYOBValue == 0)
      this.setState({ checkedOngoingInvoicesFromMYOBValue: 1 })
    if (checkedOngoingInvoicesFromMYOBValue == 1)
      this.setState({ checkedOngoingInvoicesFromMYOBValue: 0 })
    this.setState({
      checkedOngoingInvoicesFromMYOB: !this.state.checkedOngoingInvoicesFromMYOB
    })

    if (this.refs.checkedOngoingInvoicesFromMYOB.defaultChecked == 0) {
      this.setState({ ongoingpaymentsfrommyobdisabled: false })
      this.state.ongoingpaymentsfrommyobtooltip = false
      this.state.ongoinginvoicesfrommyobenabled = true
    } else if (this.refs.checkedOngoingInvoicesFromMYOB.defaultChecked == 1) {
      this.refs.checkedOngoingPaymentsFromMYOB.defaultChecked = false
      this.state.checkedOngoingPaymentsFromMYOB = false
      this.state.checkedOngoingPaymentsFromMYOBValue = 0
      this.setState({ ongoingpaymentsfrommyobdisabled: true })
      this.state.ongoingpaymentsfrommyobtooltip = true
      this.state.ongoinginvoicesfrommyobenabled = false
      this.state.sync_from_date_codat_myob = ''
      this.refs.checkedOngoingPaymentsFromMYOB.checked = false
    }
  }

  handleCheckboxOngoing3 = () => {
    this.state.ongoingPaymentsFromMYOBClicked = true
    let checkedOngoingPaymentsFromMYOBValue =
      this.refs.checkedOngoingPaymentsFromMYOB.value
    if (checkedOngoingPaymentsFromMYOBValue == 0)
      this.setState({ checkedOngoingPaymentsFromMYOBValue: 1 })
    if (checkedOngoingPaymentsFromMYOBValue == 1)
      this.setState({ checkedOngoingPaymentsFromMYOBValue: 0 })
    this.setState({
      checkedOngoingPaymentsFromMYOB: !this.state.checkedOngoingPaymentsFromMYOB
    })
  }

  handleCheckboxOngoing4 = () => {
    this.state.ongoingPaymentsClicked = true
    let checkedOngoingPaymentsValue = this.refs.checkedOngoingPayments.value
    if (checkedOngoingPaymentsValue == 0)
      this.setState({ checkedOngoingPaymentsValue: 1 })
    if (checkedOngoingPaymentsValue == 1)
      this.setState({ checkedOngoingPaymentsValue: 0 })
    this.setState({
      checkedOngoingPayments: !this.state.checkedOngoingPayments
    })
  }

  handleCheckboxOngoing5 = () => {
    this.state.ongoingClientsClicked = true
    let checkedOngoingClientsValue = this.refs.checkedOngoingClients.value
    if (checkedOngoingClientsValue == 0)
      this.setState({ checkedOngoingClientsValue: 1 })
    if (checkedOngoingClientsValue == 1)
      this.setState({ checkedOngoingClientsValue: 0 })
    this.setState({ checkedOngoingClients: !this.state.checkedOngoingClients })

    if (this.refs.checkedOngoingClients.defaultChecked == 0) {
      this.setState({ ongoinginvoicesdisabled: false })
      this.state.ongoinginvoicestooltip = false
      this.state.ongoingclientsenabled = true
    } else if (this.refs.checkedOngoingClients.defaultChecked == 1) {
      this.refs.checkedOngoingInvoices.defaultChecked = false
      this.state.checkedOngoingInvoices = false
      this.state.checkedOngoingInvoicesValue = 0
      this.setState({ ongoinginvoicesdisabled: true })
      this.state.ongoinginvoicestooltip = true
      this.refs.checkedOngoingPayments.defaultChecked = false
      this.state.checkedOngoingPayments = false
      this.state.checkedOngoingPaymentsValue = 0
      this.setState({ ongoingpaymentsdisabled: true })
      this.state.ongoingpaymentstooltip = true
      this.state.ongoingclientsenabled = false
      this.state.sync_from_date_codat = ''
      this.refs.checkedOngoingInvoices.checked = false
      this.refs.checkedOngoingPayments.checked = false
    }
  }

  handleCheckboxOngoing6 = () => {
    this.state.ongoingClientsFromMYOBClicked = true
    let checkedOngoingClientsFromMYOBValue =
      this.refs.checkedOngoingClientsFromMYOB.value
    if (checkedOngoingClientsFromMYOBValue == 0)
      this.setState({ checkedOngoingClientsFromMYOBValue: 1 })
    if (checkedOngoingClientsFromMYOBValue == 1)
      this.setState({ checkedOngoingClientsFromMYOBValue: 0 })
    this.setState({
      checkedOngoingClientsFromMYOB: !this.state.checkedOngoingClientsFromMYOB
    })

    if (this.refs.checkedOngoingClientsFromMYOB.defaultChecked == 0) {
      this.setState({ ongoinginvoicesfrommyobdisabled: false })
      this.state.ongoinginvoicesfrommyobtooltip = false
      this.state.ongoingclientsfrommyobenabled = true
    } else if (this.refs.checkedOngoingClientsFromMYOB.defaultChecked == 1) {
      this.refs.checkedOngoingInvoicesFromMYOB.defaultChecked = false
      this.state.checkedOngoingInvoicesFromMYOB = false
      this.state.checkedOngoingInvoicesFromMYOBValue = 0
      this.setState({ ongoinginvoicesfrommyobdisabled: true })
      this.state.ongoinginvoicesfrommyobtooltip = true

      this.refs.checkedOngoingPaymentsFromMYOB.defaultChecked = false
      this.state.checkedOngoingPaymentsFromMYOB = false
      this.state.checkedOngoingPaymentsFromMYOBValue = 0
      this.setState({ ongoingpaymentsfrommyobdisabled: true })
      this.state.ongoingpaymentsfrommyobtooltip = true
      this.state.ongoingclientsfrommyobenabled = false
      this.state.sync_from_date_codat_myob = ''
      this.refs.checkedOngoingInvoicesFromMYOB.checked = false
      this.refs.checkedOngoingPaymentsFromMYOB.checked = false
    }
  }

  updateApp = () => {
    let thryv_id = this.props.oauth.oauth.usersData.business_id
    let thryv_access_token = this.props.oauth.oauth.response.access_token
    let thryv_business_name = this.props.oauth.oauth.usersData.business_name
    let environment = 'staging'
    let myob_type = 'update'
    let platform = 'MYOB AccountRight and Essentials'
    let method = 'update'
    let sync_from_date_thryv = this.state.sync_from_date_codat
    let sync_from_date_codat_myob = this.state.sync_from_date_codat_myob
    let sync_from_date_thryv_formatted = ''
    let sync_from_date_codat_myob_formatted = ''

    if (sync_from_date_thryv == '') {
      sync_from_date_thryv_formatted = 'n/a'
    } else {
      sync_from_date_thryv_formatted = sync_from_date_thryv + 'T00:00:00'
    }

    if (sync_from_date_codat_myob == '') {
      sync_from_date_codat_myob_formatted = 'n/a'
    } else {
      sync_from_date_codat_myob_formatted =
        sync_from_date_codat_myob + 'T00:00:00'
    }

    let checkedOngoingInvoicesValue = this.state.checkedOngoingInvoicesValue
    let checkedOngoingInvoicesFromMYOBValue =
      this.state.checkedOngoingInvoicesFromMYOBValue
    let checkedOngoingPaymentsFromMYOBValue =
      this.state.checkedOngoingPaymentsFromMYOBValue
    let checkedOngoingPaymentsValue = this.state.checkedOngoingPaymentsValue
    let checkedOngoingClientsValue = this.refs.checkedOngoingClients.value
    let checkedOngoingClientsFromMYOBValue =
      this.refs.checkedOngoingClientsFromMYOB.value

    const settingObject = {
      thryvid: thryv_id,
      accesstoken: thryv_access_token,
      environment: environment,
      businessname: thryv_business_name,
      platform: platform,
      myob_type: myob_type,
      currency: this.state.currency,
      method: method,
      sync_from_date_thryv: sync_from_date_thryv_formatted,
      sync_from_date_codat: sync_from_date_codat_myob_formatted,
      ongoingclientsfromthryv: checkedOngoingClientsValue,
      ongoingclientsfromcodat: checkedOngoingClientsFromMYOBValue,
      ongoinginvoicesfromthryv: checkedOngoingInvoicesValue,
      ongoinginvoicesfromcodat: checkedOngoingInvoicesFromMYOBValue,
      ongoingpaymentsfromthryv: checkedOngoingPaymentsFromMYOBValue,
      ongoingpaymentsfromcodat: checkedOngoingPaymentsValue
    }

    axios
      .post(`${backendUrl}/api/myob/thryvUser/installApp`, {
        payload: settingObject
      })
      .then(response => {
        console.log(response.data)

        this.toggleModal()
      })
      .catch(error => {
        console.log(error)
      })
  }

  unsubscribeUser = () => {
    let platform = 'MYOB AccountRight and Essentials'
    let method = 'uninstall'
    let thryv_id = this.props.oauth.oauth.usersData.business_id
    let siteurl = window.location.href
    let environment = 'staging'
    let myob_type = 'accountRight'
    let uninstall_payload = {
      thryvid: thryv_id,
      environment: environment,
      method: method,
      platform: platform,
      myob_type: myob_type
    }

    axios
      .post(`${backendUrl}/api/myob/thryvUser/installApp`, {
        payload: uninstall_payload
      })
      .then(response => {
        if (siteurl.indexOf('staging') >= 0)
          window.location.href =
            'https://thryvapps.staging.wpengine.com/thryv-myob-accounting/'
        else
          window.location.href =
            'https://apps.thryv.store/thryv-myob-accounting/'
      })
      .catch(error => {
        console.log(error)
      })
  }

  reAuth = () => {
    let thryv_id = this.props.oauth.oauth.usersData.business_id
    let thryv_access_token = this.props.oauth.oauth.response.access_token
    let thryv_business_name = this.props.oauth.oauth.usersData.business_name
    let environment = 'staging'
    let platform = 'MYOB AccountRight and Essentials'
    let myob_type = 'accountRight'
    let method = 'reauth'

    const settingObject = {
      thryvid: thryv_id,
      accesstoken: thryv_access_token,
      environment: environment,
      businessname: thryv_business_name,
      platform: platform,
      currency: this.state.currency,
      method: method,
      myob_type: myob_type
    }

    axios
      .post(`${backendUrl}/api/myob/thryvUser/installApp`, {
        payload: settingObject
      })
      .then(response => {
        console.log(response.data)
        window.location.href = response.data
      })
      .catch(error => {
        console.log(error)
      })
  }

  installApp = () => {
    let thryv_id = this.props.oauth.oauth.usersData.business_id
    let thryv_access_token = this.props.oauth.oauth.response.access_token
    let thryv_business_name = this.props.oauth.oauth.usersData.business_name

    let environment = 'staging'
    let platform = 'MYOB AccountRight and Essentials'
    let method = 'install'

    let checkedOngoingInvoicesValue = this.state.checkedOngoingInvoicesValue
    let checkedOngoingInvoicesFromMYOBValue =
      this.state.checkedOngoingInvoicesFromMYOBValue
    let checkedOngoingPaymentsFromMYOBValue =
      this.state.checkedOngoingPaymentsFromMYOBValue
    let checkedOngoingPaymentsValue = this.state.checkedOngoingPaymentsValue
    let checkedHistoricalInvoicesFromMYOBValue =
      this.state.checkedHistoricalInvoicesFromMYOBValue
    let checkedHistoricalPaymentsValue =
      this.state.checkedHistoricalPaymentsValue
    let checkedHistoricalInvoicesValue =
      this.state.checkedHistoricalInvoicesValue
    let checkedHistoricalPaymentsFromMYOBValue =
      this.state.checkedHistoricalPaymentsFromMYOBValue
    let checkedHistoricalClientsValue = this.state.checkedHistoricalClientsValue
    let checkedHistoricalClientsFromMYOBValue =
      this.state.checkedHistoricalClientsFromMYOBValue
    let checkedOngoingClientsValue = this.refs.checkedOngoingClients.value
    let checkedOngoingClientsFromMYOBValue =
      this.refs.checkedOngoingClientsFromMYOB.value
    let sync_from_date_thryv = this.state.sync_from_date_codat
    let sync_from_date_codat_myob = this.state.sync_from_date_codat_myob
    let sync_from_date_thryv_formatted = ''
    let sync_from_date_codat_myob_formatted = ''

    if (sync_from_date_thryv == '') {
      sync_from_date_thryv_formatted = 'n/a'
    } else {
      sync_from_date_thryv_formatted = sync_from_date_thryv + 'T00:00:00'
    }

    if (sync_from_date_codat_myob == '') {
      sync_from_date_codat_myob_formatted = 'n/a'
    } else {
      sync_from_date_codat_myob_formatted =
        sync_from_date_codat_myob + 'T00:00:00'
    }
    const settingObject = {
      thryvid: thryv_id,
      accesstoken: thryv_access_token,
      environment: environment,
      businessname: thryv_business_name,
      platform: platform,
      currency: this.state.currency,
      myob_type: 'accountRight',
      method: method,
      sync_from_date_thryv: sync_from_date_thryv_formatted,
      sync_from_date_codat: sync_from_date_codat_myob_formatted,
      historicalclientsfromthryv: checkedHistoricalClientsValue,
      historicalclientsfromcodat: checkedHistoricalClientsFromMYOBValue,
      historicalinvoicesfromthryv: checkedHistoricalInvoicesValue,
      historicalinvoicesfromcodat: checkedHistoricalInvoicesFromMYOBValue,
      historicalpaymentsfromthryv: checkedHistoricalPaymentsFromMYOBValue,
      historicalpaymentsfromcodat: checkedHistoricalPaymentsValue,
      ongoingclientsfromthryv: checkedOngoingClientsValue,
      ongoingclientsfromcodat: checkedOngoingClientsFromMYOBValue,
      ongoinginvoicesfromthryv: checkedOngoingInvoicesValue,
      ongoinginvoicesfromcodat: checkedOngoingInvoicesFromMYOBValue,
      ongoingpaymentsfromthryv: checkedOngoingPaymentsFromMYOBValue,
      ongoingpaymentsfromcodat: checkedOngoingPaymentsValue
    }

    console.log('setting object is' + JSON.stringify(settingObject))

    axios
      .post(`${backendUrl}/api/myob/thryvUser/installApp`, {
        payload: settingObject
      })
      .then(response => {
        window.location.href = response.data
      })
      .catch(error => {
        console.log(error)
      })
  }

  settingsSwitch = () => {
    this.setState({ errorsButtonDisplay: false })
    this.setState({ settingsButtonDisplay: true })
    this.setState({ errorsDisplay: true })
    this.setState({ settingsDisplay: false })
  }

  errorsSwitch = () => {
    this.setState({ errorsButtonDisplay: true })
    this.setState({ settingsButtonDisplay: false })
    this.setState({ errorsDisplay: false })
    this.setState({ settingsDisplay: true })
  }

  handleDateValueChange = ev => {
    if (this.state.historicalinvoicesenabled)
      this.state.sync_from_date_codat = ev.target.value
    if (this.state.historicalinvoicesfrommyobenabled)
      this.state.sync_from_date_codat_myob = ev.target.value
  }

  renderTableData() {
    let thryv_id = this.props.oauth.oauth.usersData.business_id

    axios
      .get(`${backendUrl}/api/myob/thryvUser/getselectanddisplay`, {
        params: {
          id: thryv_id
        }
      })
      .then(response => {
        let length = ''
        length = response.data.length
        console.log('length of response is ' + length)

        if (length === 0) {
          console.log('length of response is ' + length)
        }
        let data = response.data
        this.setState({
          errorsList: []
        })

        while (length > 0) {
          let error_db = data[length - 1].error
          this.setState({
            errorsList: this.state.errorsList.concat([error_db])
          })
          length = length - 1
        }
      })
      .catch(error => {
        console.log(error.messages)
      })
    let errors = this.state.errorsList
    let errorsList = []
    errors.forEach((error, index) => {
      errorsList.push(
        <tr style={{ borderBottom: 'solid 1px lightgrey' }}>
          <span style={{ fontWeight: 'bold' }}>Error: </span>
          <td key={index}>{error}</td>
        </tr>
      )
    })
    console.log(errors)
    if (errors == '') {
      errorsList.push(
        <tr style={{ borderBottom: 'solid 1px lightgrey' }}>
          <td
            className="table-body"
            colSpan="1"
            style={{ textAlign: 'center', fontSize: '20px' }}
          >
            There are no errors to display.
          </td>
        </tr>
      )
    }

    return errorsList
  }

  render() {
    if (this.state.showHistoricalPrevious) {
      this.state.historicalDisplay = ''
      this.state.ongoingDisplay = 'none'
    }

    if (this.state.showOngoingNext) {
      this.state.ongoingDisplay = ''
      this.state.historicalDisplay = 'none'
    }

    let defaultCheckedOngoingPaymentCodat = false

    if (this.state.ongoing_payment_codat == '1') {
      defaultCheckedOngoingPaymentCodat = true
    }

    return (
      <section id="content">
        <Modal
          isOpen={this.state.isOpen}
          //visible={this.state.isOpen}
          onRequestClose={this.toggleModal}
          contentLabel="My dialog"
        >
          <div>
            We have updated your MYOB settings.{' '}
            <p>XXXX Successfully Updated XXXX </p>
          </div>
          <button onClick={this.toggleModal}>X</button>
        </Modal>
        <Container fluid>
          <Nav>
            <Col size="7">
              <span className="nav-text">
                <strong>
                  <span
                    style={{
                      fontSize: '15px',
                      color: 'white',
                      letterSpacing: '0'
                    }}
                  >
                    MYOB AccountRight
                  </span>
                </strong>
              </span>
            </Col>
            <Col size="5" className="text-right">
              {this.state.settingsButtonDisplay && (
                <button
                  className="nav-text btn"
                  onClick={e => this.errorsSwitch(e)}
                  style={{ backgroundColor: '#161616', color: 'white' }}
                >
                  Settings
                </button>
              )}

              <button
                className="nav-text btn"
                onClick={this.unsubscribeUser}
                style={{ color: 'white' }}
              >
                Unsubscribe
              </button>
            </Col>
          </Nav>
          {this.state.settingsDisplay && (
            <Row
              className="justify-content-center"
              style={{ display: this.state.historicalDisplay }}
            >
              <Col size="10">
                <Row
                  className="text-center"
                  style={{ padding: 40, marginTop: '10px' }}
                >
                  <Col size="12">
                    <h1>Historical Import Options</h1>
                  </Col>
                </Row>

                <div className="text-center">
                  <Row>
                    <Col size="1" className="text-center"></Col>
                    <Col size="7" className="text-left">
                      <span>
                        <strong>
                          Import historical clients from Thryv to MYOB
                          AccountRight?
                        </strong>
                      </span>
                    </Col>
                    &nbsp;&nbsp;&nbsp;
                    <Col size="2" className="text-left">
                      <span
                        className=" mr-1 txt10"
                        style={{
                          color: '#D5D5D6',
                          position: 'absolute',
                          top: '3px',
                          left: '-9px'
                        }}
                      >
                        No
                      </span>
                      <label className="switch">
                        <input
                          type="checkbox"
                          ref="checkedHistoricalClients"
                          value={this.state.checkedHistoricalClientsValue}
                          defaultChecked={this.state.checkedHistoricalClients}
                          onChange={this.handleCheckboxClient}
                        />

                        <span className="slider round" />
                      </label>
                      <span
                        className=" mr-1 txt10"
                        style={{
                          color: '#ff5000',
                          position: 'absolute',
                          top: '3px',
                          left: '75px'
                        }}
                      >
                        Yes
                      </span>
                    </Col>
                  </Row>
                  <br></br>

                  <Row>
                    <Col size="1" className="text-center"></Col>
                    <Col size="7" className="text-left">
                      <span>
                        <strong>
                          Import historical invoices from Thryv to MYOB
                          AccountRight?
                        </strong>
                      </span>
                    </Col>
                    &nbsp;&nbsp;&nbsp;
                    <Col size="2" className="text-left">
                      <span
                        className=" mr-1 txt10"
                        style={{
                          color: '#D5D5D6',
                          position: 'absolute',
                          top: '3px',
                          left: '-9px'
                        }}
                      >
                        No
                      </span>
                      <label className="switch">
                        <input
                          type="checkbox"
                          ref="checkedHistoricalInvoices"
                          value={this.state.checkedHistoricalInvoicesValue}
                          defaultChecked={this.state.checkedHistoricalInvoices}
                          onChange={this.handleCheckbox1}
                        />

                        <span className="slider round" />
                      </label>
                      <span
                        className=" mr-1 txt10"
                        style={{
                          color: '#ff5000',
                          position: 'absolute',
                          top: '3px',
                          left: '75px'
                        }}
                      >
                        Yes
                      </span>
                    </Col>
                  </Row>
                  <br></br>

                  {this.state.historicalinvoicesenabled && (
                    <Row>
                      <Col size="9" className="text-center">
                        <span>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A date is required
                          in order to enable historical invoices from MYOB
                          AccountRight. Please select a date.
                        </span>
                      </Col>
                      <Col size="3" className="text-left">
                        <input
                          type="date"
                          onChange={ev => this.handleDateValueChange(ev)}
                        />

                        <br></br>
                      </Col>
                    </Row>
                  )}

                  {this.state.historicalinvoicesenabled && <br></br>}
                  {this.state.historicalinvoicesenabled && <br></br>}

                  <Row>
                    <Col size="1" className="text-center"></Col>
                    <Col size="7" className="text-left">
                      <span>
                        <strong>
                          Import historical payments from Thryv to MYOB
                          AccountRight?
                        </strong>
                      </span>
                    </Col>
                    &nbsp;&nbsp;&nbsp;
                    <Col size="2" className="text-left">
                      <span
                        className=" mr-1 txt10"
                        style={{
                          color: '#D5D5D6',
                          position: 'absolute',
                          top: '3px',
                          left: '-9px'
                        }}
                      >
                        No
                      </span>
                      <label className="switch">
                        <input
                          type="checkbox"
                          ref="checkedHistoricalPayments"
                          value={this.state.checkedHistoricalPaymentsValue}
                          defaultChecked={this.state.checkedHistoricalPayments}
                          onChange={this.handleCheckbox4}
                          disabled={this.state.historicalpaymentsdisabled}
                        />

                        <span
                          className="slider round"
                          onMouseEnter={() =>
                            this.onMouseEnterHandler('historicalpayments')
                          }
                          onMouseLeave={() =>
                            this.onMouseLeaveHandler('historicalpayments')
                          }
                        />

                        <span
                          className=" mr-1 txt10"
                          style={{
                            color: '#ff5000',
                            position: 'absolute',
                            top: '3px',
                            left: '60px'
                          }}
                        >
                          Yes
                        </span>
                        {this.state.historicalpayments &&
                        this.state.historicalpaymentstooltip ? (
                          <span
                            ref="historicalinvoicesfromthryvtooltip"
                            className="field-text-hover"
                          >
                            In order to enable historical payments from Thryv,
                            historical invoices from Thryv must be enabled.
                          </span>
                        ) : (
                          <span></span>
                        )}
                      </label>
                    </Col>
                  </Row>
                  <br></br>
                  <Row>
                    <Col size="1" className="text-center"></Col>
                    <Col size="7" className="text-left">
                      <span>
                        <strong>
                          Import historical clients from MYOB AccountRight to
                          Thryv?
                        </strong>
                      </span>
                    </Col>
                    &nbsp;&nbsp;&nbsp;
                    <Col size="2" className="text-left">
                      <span
                        className=" mr-1 txt10"
                        style={{
                          color: '#D5D5D6',
                          position: 'absolute',
                          top: '3px',
                          left: '-9px'
                        }}
                      >
                        No
                      </span>
                      <label className="switch">
                        <input
                          type="checkbox"
                          ref="checkedHistoricalClientsFromMYOB"
                          value={
                            this.state.checkedHistoricalClientsFromMYOBValue
                          }
                          defaultChecked={
                            this.state.checkedHistoricalClientsFromMYOB
                          }
                          onChange={this.handleCheckbox7}
                        />

                        <span className="slider round" />
                      </label>
                      <span
                        className=" mr-1 txt10"
                        style={{
                          color: '#ff5000',
                          position: 'absolute',
                          top: '3px',
                          left: '75px'
                        }}
                      >
                        Yes
                      </span>
                    </Col>
                  </Row>
                  <br></br>

                  <Row>
                    <Col size="1" className="text-center"></Col>
                    <Col size="7" className="text-left">
                      <span>
                        <strong>
                          Import historical invoices from MYOB AccountRight to
                          Thryv?
                        </strong>
                      </span>
                    </Col>
                    &nbsp;&nbsp;&nbsp;
                    <Col size="2" className="text-left">
                      <span
                        className=" mr-1 txt10"
                        style={{
                          color: '#D5D5D6',
                          position: 'absolute',
                          top: '3px',
                          left: '-9px'
                        }}
                      >
                        No
                      </span>
                      <label className="switch">
                        <input
                          type="checkbox"
                          ref="checkedHistoricalInvoicesFromMYOB"
                          value={
                            this.state.checkedHistoricalInvoicesFromMYOBValue
                          }
                          defaultChecked={
                            this.state.checkedHistoricalInvoicesFromMYOB
                          }
                          onChange={this.handleCheckbox2}
                        />

                        <span className="slider round" />
                      </label>
                      <span
                        className=" mr-1 txt10"
                        style={{
                          color: '#ff5000',
                          position: 'absolute',
                          top: '3px',
                          left: '75px'
                        }}
                      >
                        Yes
                      </span>
                    </Col>
                  </Row>
                  <br></br>

                  {this.state.historicalinvoicesfrommyobenabled && (
                    <Row>
                      <Col size="9" className="text-center">
                        <span>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A date is required
                          in order to enable historical invoices from MYOB
                          AccountRight. Please select a date.
                        </span>
                      </Col>
                      <Col size="3" className="text-left">
                        <input
                          type="date"
                          onChange={ev => this.handleDateValueChange(ev)}
                        />
                      </Col>
                    </Row>
                  )}

                  {this.state.historicalinvoicesfrommyobenabled && <br></br>}
                  {this.state.historicalinvoicesfrommyobenabled && <br></br>}

                  <Row>
                    <Col size="1" className="text-center"></Col>
                    <Col size="7" className="text-left">
                      <span>
                        <strong>
                          Import historical payments from MYOB AccountRight to
                          Thryv?
                        </strong>
                      </span>
                    </Col>
                    &nbsp;&nbsp;&nbsp;
                    <Col size="2" className="text-left">
                      <span
                        className=" mr-1 txt10"
                        style={{
                          color: '#D5D5D6',
                          position: 'absolute',
                          top: '3px',
                          left: '-9px'
                        }}
                      >
                        No
                      </span>
                      <label className="switch">
                        <input
                          type="checkbox"
                          ref="checkedHistoricalPaymentsFromMYOB"
                          value={
                            this.state.checkedHistoricalPaymentsFromMYOBValue
                          }
                          defaultChecked={
                            this.state.checkedHistoricalPaymentsFromMYOB
                          }
                          onChange={this.handleCheckbox3}
                          disabled={
                            this.state.historicalpaymentsfrommyobdisabled
                          }
                        />{' '}
                        {/*  this.state.ongoingPaymentsFromMYOBClicked ?  this.state.checkedOngoingPaymentsFromMYOBValue : this.state.ongoing_payment_codat */}
                        {/*<input id="ongoinginvoicesfromcodat" type="checkbox" value="<?php if (is_null($ongoing_invoice_codat)) {?>1<?php } else { echo $ongoing_invoice_codat;} ?>" <?php if ($show_settings == "show") { ?> checked <?php } else { if ($ongoing_invoice_codat == "1") { ?>checked<?php } else {}} ?>>
                         */}
                        <span
                          className="slider round"
                          onMouseEnter={() =>
                            this.onMouseEnterHandler('historicalpaymentscodat')
                          }
                          onMouseLeave={() =>
                            this.onMouseLeaveHandler('historicalpaymentscodat')
                          }
                        />
                        <span
                          className="txt10"
                          style={{
                            color: '#ff5000',
                            position: 'absolute',
                            top: '3px',
                            left: '60px'
                          }}
                        >
                          Yes
                        </span>
                        {this.state.historicalpaymentscodat &&
                        this.state.historicalpaymentsfrommyobtooltip ? (
                          <span
                            ref="historicalinvoicesfromthryvtooltip"
                            className="field-text-hover"
                          >
                            In order to enable historical payments from MYOB
                            AccountRight, historical invoices from MYOB
                            AccountRight must be enabled.
                          </span>
                        ) : (
                          <span></span>
                        )}
                      </label>
                    </Col>
                  </Row>
                  <br></br>
                  <br></br>
                </div>

                <Row style={{ justifyContent: 'center' }}>
                  <div className="col-md-6 col-lg-2">
                    <button
                      className="orange-btn btn btn-primary btn-block"
                      onClick={() => this.showNext(0)}
                      style={{
                        border: '1px solid black',
                        height: '50px',
                        backgroundColor: 'blue'
                      }}
                    >
                      Next
                    </button>
                  </div>
                  &nbsp;
                </Row>
              </Col>
            </Row>
          )}

          {this.state.settingsDisplay && (
            <Row
              className="justify-content-center"
              style={{ display: this.state.ongoingDisplay }}
            >
              <Col size="10">
                <Row
                  className="text-center"
                  style={{ padding: 40, marginTop: '10px' }}
                >
                  <Col size="11">
                    <h1>
                      <strong>Ongoing Import Options </strong>
                    </h1>
                  </Col>
                </Row>

                <br></br>
                <Row>
                  <Col size="1" className="text-center"></Col>
                  <Col size="7" className="text-left">
                    <span>
                      <strong>
                        Import ongoing clients from Thryv to MYOB AccountRight?
                      </strong>
                    </span>
                  </Col>
                  &nbsp;&nbsp;&nbsp;
                  <Col size="2" className="text-left">
                    <span
                      className=" mr-1 txt10"
                      style={{
                        color: '#D5D5D6',
                        position: 'absolute',
                        top: '3px',
                        left: '-9px'
                      }}
                    >
                      No
                    </span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        ref="checkedOngoingClients"
                        value={this.state.checkedOngoingClientsValue}
                        defaultChecked={this.state.checkedOngoingClients}
                        onChange={this.handleCheckboxOngoing5}
                      />

                      <span className="slider round" />
                    </label>
                    <span
                      className=" mr-1 txt10"
                      style={{
                        color: '#ff5000',
                        position: 'absolute',
                        top: '3px',
                        left: '75px'
                      }}
                    >
                      Yes
                    </span>
                  </Col>
                </Row>
                <br></br>

                <Row>
                  <Col size="1" className="text-center"></Col>
                  <Col size="7" className="text-left">
                    <span>
                      <strong>
                        Import ongoing invoices from Thryv to MYOB AccountRight?
                      </strong>
                    </span>
                  </Col>
                  &nbsp;&nbsp;&nbsp;
                  <Col size="2" className="text-left">
                    <span
                      className=" mr-1 txt10"
                      style={{
                        color: '#D5D5D6',
                        position: 'absolute',
                        top: '3px',
                        left: '-9px'
                      }}
                    >
                      No
                    </span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        ref="checkedOngoingInvoices"
                        value={this.state.checkedOngoingInvoicesValue}
                        defaultChecked={this.state.checkedOngoingInvoices}
                        onChange={this.handleCheckboxOngoing1}
                      />

                      <span className="slider round" />
                    </label>
                    <span
                      className=" mr-1 txt10"
                      style={{
                        color: '#ff5000',
                        position: 'absolute',
                        top: '3px',
                        left: '75px'
                      }}
                    >
                      Yes
                    </span>
                  </Col>
                </Row>
                <br></br>

                <Row>
                  <Col size="1" className="text-center"></Col>
                  <Col size="7" className="text-left">
                    <span>
                      <strong>
                        Import ongoing payments from Thryv to MYOB AccountRight?
                      </strong>
                    </span>
                  </Col>
                  &nbsp;&nbsp;&nbsp;
                  <Col size="2" className="text-left">
                    <span
                      className=" mr-1 txt10"
                      style={{
                        color: '#D5D5D6',
                        position: 'absolute',
                        top: '3px',
                        left: '-9px'
                      }}
                    >
                      No
                    </span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        ref="checkedOngoingPayments"
                        value={this.state.checkedOngoingPaymentsValue}
                        defaultChecked={this.state.checkedOngoingPayments}
                        onChange={this.handleCheckboxOngoing4}
                        disabled={this.state.ongoingpaymentsdisabled}
                      />

                      <span
                        className="slider round"
                        onMouseEnter={() =>
                          this.onMouseEnterHandler('ongoingpayments')
                        }
                        onMouseLeave={() =>
                          this.onMouseLeaveHandler('ongoingpayments')
                        }
                      />

                      <span
                        className=" mr-1 txt10"
                        style={{
                          color: '#ff5000',
                          position: 'absolute',
                          top: '3px',
                          left: '60px'
                        }}
                      >
                        Yes
                      </span>
                      {this.state.ongoingpayments &&
                      this.state.ongoingpaymentstooltip ? (
                        <span
                          ref="historicalinvoicesfromthryvtooltip"
                          className="field-text-hover"
                        >
                          In order to enable ongoing payments from Thryv,
                          ongoing invoices from Thryv must be enabled.
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </label>
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col size="1" className="text-center"></Col>
                  <Col size="7" className="text-left">
                    <span>
                      <strong>
                        Import ongoing clients from MYOB AccountRight to Thryv?
                      </strong>
                    </span>
                  </Col>
                  &nbsp;&nbsp;&nbsp;
                  <Col size="2" className="text-left">
                    <span
                      className=" mr-1 txt10"
                      style={{
                        color: '#D5D5D6',
                        position: 'absolute',
                        top: '3px',
                        left: '-9px'
                      }}
                    >
                      No
                    </span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        ref="checkedOngoingClientsFromMYOB"
                        value={this.state.checkedOngoingClientsFromMYOBValue}
                        defaultChecked={
                          this.state.checkedOngoingClientsFromMYOB
                        }
                        onChange={this.handleCheckboxOngoing6}
                      />

                      <span className="slider round" />
                    </label>
                    <span
                      className=" mr-1 txt10"
                      style={{
                        color: '#ff5000',
                        position: 'absolute',
                        top: '3px',
                        left: '75px'
                      }}
                    >
                      Yes
                    </span>
                  </Col>
                </Row>
                <br></br>

                <Row>
                  <Col size="1" className="text-center"></Col>
                  <Col size="7" className="text-left">
                    <span>
                      <strong>
                        Import ongoing invoices from MYOB AccountRight to Thryv?
                      </strong>
                    </span>
                  </Col>
                  &nbsp;&nbsp;&nbsp;
                  <Col size="2" className="text-left">
                    <span
                      className=" mr-1 txt10"
                      style={{
                        color: '#D5D5D6',
                        position: 'absolute',
                        top: '3px',
                        left: '-9px'
                      }}
                    >
                      No
                    </span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        ref="checkedOngoingInvoicesFromMYOB"
                        value={this.state.checkedOngoingInvoicesFromMYOBValue}
                        defaultChecked={
                          this.state.checkedOngoingInvoicesFromMYOB
                        }
                        onChange={this.handleCheckboxOngoing2}
                      />

                      <span className="slider round" />
                    </label>
                    <span
                      className=" mr-1 txt10"
                      style={{
                        color: '#ff5000',
                        position: 'absolute',
                        top: '3px',
                        left: '75px'
                      }}
                    >
                      Yes
                    </span>
                  </Col>
                </Row>
                <br></br>

                <Row>
                  <Col size="1" className="text-center"></Col>
                  <Col size="7" className="text-left">
                    <span>
                      <strong>
                        Import ongoing payments from MYOB AccountRight to Thryv?
                      </strong>
                    </span>
                  </Col>
                  &nbsp;&nbsp;&nbsp;
                  <Col size="2" className="text-left">
                    <span
                      className=" mr-1 txt10"
                      style={{
                        color: '#D5D5D6',
                        position: 'absolute',
                        top: '3px',
                        left: '-9px'
                      }}
                    >
                      No
                    </span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        ref="checkedOngoingPaymentsFromMYOB"
                        value={
                          this.state.ongoing_payment_codat
                            ? '1'
                            : this.state.checkedOngoingPaymentsFromMYOBValue
                        }
                        defaultChecked={
                          this.state.checkedOngoingPaymentsFromMYOB ||
                          defaultCheckedOngoingPaymentCodat
                        }
                        onChange={this.handleCheckboxOngoing3}
                        disabled={this.state.ongoingpaymentsfrommyobdisabled}
                      />{' '}
                      {/*  this.state.ongoingPaymentsFromMYOBClicked ?  this.state.checkedOngoingPaymentsFromMYOBValue : this.state.ongoing_payment_codat */}
                      {/*<input id="ongoinginvoicesfromcodat" type="checkbox" value="<?php if (is_null($ongoing_invoice_codat)) {?>1<?php } else { echo $ongoing_invoice_codat;} ?>" <?php if ($show_settings == "show") { ?> checked <?php } else { if ($ongoing_invoice_codat == "1") { ?>checked<?php } else {}} ?>>
                       */}
                      <span
                        className="slider round"
                        onMouseEnter={() =>
                          this.onMouseEnterHandler('ongoingpaymentscodat')
                        }
                        onMouseLeave={() =>
                          this.onMouseLeaveHandler('ongoingpaymentscodat')
                        }
                      />
                      <span
                        className="txt10"
                        style={{
                          color: '#ff5000',
                          position: 'absolute',
                          top: '3px',
                          left: '60px'
                        }}
                      >
                        Yes
                      </span>
                      {this.state.ongoingpaymentscodat &&
                      this.state.ongoingpaymentsfrommyobtooltip ? (
                        <span
                          ref="historicalinvoicesfromthryvtooltip"
                          className="field-text-hover"
                        >
                          In order to enable ongoing payments from MYOB
                          AccountRight, ongoing invoices from MYOB AccountRight
                          must be enabled.
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </label>
                  </Col>
                </Row>

                <br></br>

                <Row style={{ justifyContent: 'center' }}>
                  <div className="col-md-5 col-lg-2">
                    <button
                      className="btn btn-primary btn-block"
                      onClick={() => this.showPrevious(0)}
                      style={{
                        border: '1px solid black',
                        height: '50px',
                        backgroundColor: 'blue',
                        display:
                          this.state.show_historical_settings == 'hide'
                            ? 'none'
                            : 'block'
                      }}
                    >
                      Back
                    </button>
                  </div>
                  &nbsp;
                  <div className="col-md-5 col-lg-2">
                    <button
                      className="btn btn-primary btn-block orange-btn"
                      onClick={this.installApp}
                      style={{
                        border: '1px solid black',
                        height: '50px',
                        display:
                          this.state.show_historical_settings == 'hide'
                            ? 'none'
                            : 'block'
                      }}
                    >
                      Install
                    </button>
                  </div>
                </Row>
                <Row style={{ marginTop: '25px', justifyContent: 'center' }}>
                  <Col size="10">
                    <Row style={{ justifyContent: 'center' }}>
                      <div className="row justify-content-center">
                        <div className="col-12 text-center">
                          <button
                            className="orange-btn btn btn-primary btn-block"
                            onClick={this.reAuth}
                            style={{
                              width: '200px',
                              height: '50px',
                              marginRight: '70px',
                              display: this.state.reAuthDisplay
                            }}
                          >
                            Re-Authenticate
                          </button>
                        </div>
                      </div>
                      &nbsp;&nbsp;
                      <div className="row justify-content-center">
                        <div className="col-12 text-center">
                          <button
                            className="orange-btn btn btn-primary btn-block"
                            onClick={this.updateApp}
                            style={{
                              width: '200px',
                              height: '50px',
                              marginRight: '70px',
                              display: this.state.updateDisplay
                            }}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Container>

        {this.state.errorsDisplay && (
          <div ref="quarantine_table_div">
            <div className="imports_table_div" style={{ marginTop: '100px' }}>
              <div style={{ textAlign: 'center', marginBottom: '25px' }}>
                <p className="title">
                  Error's List{' '}
                  <a
                    className="btn py-2 waves-effect"
                    ref="refresh-table"
                    style={{ border: 'solid #FF5000 1px' }}
                    onClick={() => this.renderTableData()}
                  >
                    Refresh <Icon icon={refreshIcon} />
                  </a>
                </p>
              </div>
              <table
                id="imports_table"
                style={{
                  margin: 'auto',
                  width: '-webkit-fill-available',
                  maxWidth: '1180px',
                  border: 'solid 1px lightgrey',
                  marginBottom: '60px'
                }}
              >
                <thead></thead>
                <tbody ref="imports_table_body">{this.renderTableData()}</tbody>
              </table>
            </div>
          </div>
        )}
      </section>
    )
  }
  changeName() {
    let text = 'text '
    text += this.state.show === true ? 'hide' : 'show'
    return text
  }
  showNext(num) {
    if (
      this.state.historicalinvoicesenabled &&
      this.state.sync_from_date_codat === ''
    ) {
      alert('select a date')
    } else if (
      this.state.historicalinvoicesfrommyobenabled &&
      this.state.sync_from_date_codat_myob === ''
    ) {
      alert('select a date')
    } else {
      this.setState(prevState => {
        const newItems = [...prevState.show]
        newItems[num] = !newItems[num]
        return { show: newItems }
      })

      // document.getElementById('historical_settings_div').style.display="none"
      //this.refs.historical_test.display="none"
      // this.setState({show_historical_settings: "hide"});
      this.setState({ showNext: true })
      this.setState({ showOngoingNext: true })
      this.setState({ showHistoricalPrevious: false })
    }
  }
  showPrevious(num) {
    this.setState(prevState => {
      const newItems = [...prevState.show]
      newItems[num] = !newItems[num]
      return { show: newItems }
    })
    this.setState({ showPrev: true })
    this.setState({ showOngoingNext: false })
    this.setState({ showHistoricalPrevious: true })
  }
}

export default MyObAccountingSettings
