import { Header, Icon, ParagraphText } from '@thryvlabs/maverick'
import PropTypes from 'prop-types'

import { CardContainer } from '../../ui/home-page-layout'

const Sections = ({ sections }) => {
  return sections.map((s, index) => {
    return (
      <CardContainer key={index}>
        <div className="card">
          <Header fontWeight="medium" variant="h3" className="header">
            {s.header}
          </Header>
          {s.statements.map(statement => {
            return (
              <div key={statement}>
                <div className="statements">
                  <Icon
                    type="solid"
                    variant="circleCheck"
                    color="green"
                    className="icon"
                  />
                  <ParagraphText
                    variant="lg"
                    color="black"
                    className="state-text"
                  >
                    {statement}
                  </ParagraphText>
                </div>
              </div>
            )
          })}
        </div>
      </CardContainer>
    )
  })
}

Sections.prototype = {
  sections: PropTypes.array
}

export default Sections
