import { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { toast, ToastContainer } from 'react-toastify'

import UninstallApp from '../../components/meta-components/uninstall-app'
import { AppsModal, LoadingModal } from '../../components/Modal'
import NavLayout from '../../components/nav-layout/nav-layout'
import { authClient } from '../../util/auth-client'

const { REACT_APP_THRYV_URL } = process.env

function MessengerNextUninstall() {
  const [modalShow, setModalShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [appInstalled, setAppInstalled] = useState(true)
  const { user } = useAuth0()
  const axiosClient = authClient()

  async function uninstallMessengerApp() {
    setIsLoading(true)
    try {
      const { data } = await axiosClient({
        method: 'patch',
        url: `/api/thryv-user/fbm/uninstall/${user.businessId}`
      })
      setIsLoading(false)
      setAppInstalled(false)
      if (data.error) {
        toast.error(data.error)
      } else {
        setModalShow(true)
        setIsLoading(false)
        setAppInstalled(false)
      }
    } catch (error) {
      toast.error(
        'Failed to uninstall Facebook Messenger integration. Please contact Thryv Support if this issue persists.'
      )
    }
  }

  const navigateBackToThryv = () => {
    window.location.href = `${REACT_APP_THRYV_URL}/app/app-market`
  }

  return (
    <>
      <ToastContainer />
      <NavLayout title={'Facebook Messenger'} />
      <UninstallApp
        appInstalled={appInstalled}
        uninstallApp={uninstallMessengerApp}
        navigateBackToThryv={navigateBackToThryv}
        appName="Facebook Messenger"
      />
      <AppsModal
        show={modalShow}
        dynData={localStorage.getItem('error')}
        appName="Facebook"
        appAction="Uninstalled"
        onHide={() => {
          setModalShow(false)
          window.location.href = `${process.env.REACT_APP_THRYV_URL}/app`
        }}
      />
      <LoadingModal
        show={isLoading}
        onHide={() => {
          setIsLoading(false)
        }}
      />
    </>
  )
}

export default MessengerNextUninstall
