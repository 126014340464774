import { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

import TwilioSettings from './TwilioSettings'
import TwilioSpamFilter from './TwilioSpamFilter'
import UninstallSuccessModal from '../../components/modals/uninstall-success-modal'
import NavLayout from '../../components/nav-layout/nav-layout'
import { authClient } from '../../util/auth-client'

const UNINSTALL_MESSAGE = `Unsubscribing from the Twilio App will delete all existing queued 
entries and they will be unrecoverable. Please clear your queue first if you still have 
notifications you would like to approve.`

const TwilioNext = () => {
  const [unsubscribed, setUnsubscribed] = useState(false)
  const [showTwilioSettings, setShowTwilioSettings] = useState(true)
  const [showSpamFilter, setShowSpamFilter] = useState(false)
  const [showSettingsButton, setShowSettingsButton] = useState(false)
  const [showSpamFilterButton, setShowSpamFilterButton] = useState(false)

  const { state } = useLocation()
  const axiosClient = authClient()

  const { twilioUser = {} } = state || {}
  const thryvId = twilioUser.thryvId

  const goToSettingsPage = () => {
    setShowTwilioSettings(true)
    setShowSpamFilter(false)
    setShowSpamFilterButton(true)
    setShowSettingsButton(false)
  }

  const goToSpamFilterPage = () => {
    setShowSpamFilter(true)
    setShowTwilioSettings(false)
    setShowSettingsButton(true)
    setShowSpamFilterButton(false)
  }

  const unsubscribeUser = async () => {
    try {
      await axiosClient({
        method: 'DELETE',
        url: `/api/twilio/users/${thryvId}`
      })
      setUnsubscribed(true)
    } catch (err) {
      toast.error(`
        Something went wrong while unsubscribing from Twilio. 
        Please contact Thryv Support if the issue persists.
      `)
    }
  }

  // only display the spam filter page for users who have completed the integration
  // and selected "Yes" for "Would you like to enable the Spam Filter?"
  useEffect(() => {
    if (twilioUser.userHasIntegration && twilioUser.quarantine === 1) {
      setShowSpamFilter(true)
      setShowSettingsButton(true)
      setShowTwilioSettings(false)
    }
  }, [])

  return (
    <>
      <ToastContainer />
      <NavLayout
        title={'Twilio'}
        showButton={twilioUser.userHasIntegration}
        handleUninstall={unsubscribeUser}
        handleSettingsClick={goToSettingsPage}
        handleSpamFilterClick={goToSpamFilterPage}
        showSettingsButton={showSettingsButton}
        showSpamFilterButton={showSpamFilterButton}
        customMessage={UNINSTALL_MESSAGE}
      />
      {showTwilioSettings && (
        <TwilioSettings
          twilioUser={twilioUser}
          setShowSettingsButton={setShowSettingsButton}
          setShowTwilioSettings={setShowTwilioSettings}
          setShowSpamFilter={setShowSpamFilter}
        />
      )}
      {showSpamFilter && <TwilioSpamFilter twilioUser={twilioUser} />}
      <UninstallSuccessModal app="Twilio" shouldShow={unsubscribed} />
    </>
  )
}

export default TwilioNext
