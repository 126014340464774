import { useNavigate } from 'react-router-dom'

import insightlyLogo from '../../assets/insightly-logo.png'
import HomePageLayout from '../../components/home-page-layouts/home-page-layout'

const Insightly = () => {
  const appName = 'Insightly'
  const navigate = useNavigate()
  const handleClick = e => {
    e.preventDefault()
    navigate('/insightly-settings')
  }
  const insightlySections = [
    {
      header: 'What will this application need access to?',
      statements: ['Your User details', 'Client details']
    },

    {
      header: 'What will this application do?',
      statements: [
        'Sync current Clients between Thryv and Insightly CRM.',
        'Sync future Clients between Thryv and Insightly CRM.',
        'Sync Insightly CRM Leads to Thryv Clients.'
      ]
    }
  ]

  const appIconStyle = {
    height: '80px',
    width: '',
    marginTop: '-6px',
    marginLeft: '15px'
  }

  return (
    <HomePageLayout
      sections={insightlySections}
      onSubmit={handleClick}
      appName={appName}
      appIconSource={insightlyLogo}
      appIconStyle={appIconStyle}
    />
  )
}

export default Insightly
