import { useNavigate } from 'react-router-dom'

import cloverLogo from '../../assets/clover.png'
import HomePageLayout from '../../components/home-page-layouts/home-page-layout'
import '../../css/App.css'

const Clover = () => {
  const navigate = useNavigate()

  const handleClick = e => {
    e.preventDefault()
    navigate('/clover-register')
  }
  const cloverSections = [
    {
      header: 'What will this application do?',
      statements: [
        'Sync current Clients between Thryv and Clover.',
        'Sync future Clients between Thryv and Clover.',
        'Sync current Payments between Thryv and Clover.',
        'Sync future Payments between Thryv and Clover.'
      ]
    }
  ]

  return (
    <HomePageLayout
      sections={cloverSections}
      onSubmit={handleClick}
      appName="Clover"
      appIconSource={cloverLogo}
      appIconStyle={{ marginLeft: '20px', height: '80px', marginTop: '-20px' }}
    />
  )
}

export default Clover
