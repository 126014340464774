import { Col, Row, Container } from '../../components/Grid'

const EmptyPage = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col size="10">
          <Row className="text-center">
            <Col size="12">
              <div style={{ fontWeight: 'bold', marginTop: '200px' }}>
                {
                  "  It looks like you've navigated to the wrong place. Please double check the URL entered, and contact Thryv Support if the issue persists. "
                }
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
export default EmptyPage
