import styled from '@emotion/styled'

export const NavContainer = styled.div`
  display: flex;
  height: 150px;
  background: #f8f9fb;
  margin-bottom: 20px;
  .thryv-logo {
    width: 200px !important;
    height: 200px;
    margin-top: -30px;
  }
  .app-name {
    margin-top: 55px;
    font-size: 30px;
    margin-left: -10px;
  }

  .nav-buttons-container {
    display: flex;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    .nav-button-uninstall-container {
      margin-right: 20px;
    }
    .nav-button-settings-container {
      margin-right: 20px;
    }
  }
`
