import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

const spinnerKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const LoadingSpinner = styled.div`
  width: 150px;
  height: 150px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #ff5000;
  border-radius: 50%;
  animation: ${spinnerKeyframes} 1.5s linear infinite;
`

export const SpinnerContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 5;
`
