import { useEffect, useState } from 'react'

import Image from '../../assets/Thryv_Signatures_Lockup_RGB.png'
import { Col, Container, Row } from '../../components/Grid'
import { Jumbotron } from '../../components/Jumbotron'
import '../../css/App.css'
import { LogoContainer } from '../../components/ui/home-page-layout'

const Signatures = () => {
  let envUrl = process.env.REACT_APP_ENV

  let signatures_redirect

  if (envUrl === 'staging') {
    signatures_redirect =
      'https://go.thryv.com/app/embedded/signaturesdevmenu/signatures'
  } else {
    signatures_redirect =
      'https://go.thryv.com/app/embedded/signaturesprod/signatures'
  }

  useEffect(() => {}, [])

  const [activeOrDisabled, setActiveOrDisabled] = useState({ disabled: true })

  const handleChange = e => {
    if (e.target.checked) {
      setActiveOrDisabled({
        disabled: false,
        isActive: false
      })
    } else {
      setActiveOrDisabled({
        disabled: true,
        isActive: true
      })
    }
  }

  const handleClick = e => {
    e.preventDefault()
    window.location.href = signatures_redirect
  }

  return (
    <Container fluid>
      <Jumbotron>
        <LogoContainer>
          <img src={Image} style={{ height: '85px' }} />
        </LogoContainer>
        <p className="lead mt-5 step1">
          <strong>STEP 1:</strong> Signatures is requesting permission to access
          your Thryv account.
        </p>
      </Jumbotron>
      <Row className="justify-content-center">
        <Col size="md-6">
          <div className="card">
            <h5 className="card-header txt17 p-4">
              What will this application do?
            </h5>
            <div className="card-body pt-3 pb-3">
              <p className="card-title pt-3 pb-3">
                <span
                  className="fa fa-check-circle mr-2"
                  style={{ color: 'green', fontSize: 24 }}
                ></span>
                Signatures is an integrated eSignature for Thryv. Signatures
                allows business to send signature requests to clients that are
                templatized, secure, and timely.
              </p>
            </div>
            <hr></hr>
            <div className="card-body pt-3 pb-3">
              <p className="card-title pt-3 pb-3">
                <span
                  className="fa fa-check-circle mr-2"
                  style={{ color: 'green', fontSize: 24 }}
                ></span>
                It effortlessly reminds signers about pending requests and upon
                completion organizes signed documents into client profiles for
                easy access.
              </p>
            </div>
            <hr></hr>
            <div className="card-body pt-3 pb-3">
              <p className="card-title pt-3 pb-3">
                <span
                  className="fa fa-check-circle mr-2"
                  style={{ color: 'green', fontSize: 24 }}
                ></span>
                Thryv Signatures is a powerful efficiency tool that allows you
                to effortlessly request tamper-proof signatures on documents in
                a safe and secure way.
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col size="md-6">
          <div className="txt12" style={{ paddingBottom: 25, paddingTop: 15 }}>
            Thryv® Marketplace is an aggregated offering of Applications that
            may be of interest or use to you. Applications not branded Thryv®
            are provided by third-party developers, licensors or distributors.
            When you link out from the Marketplace’s app details page to a
            third-party site, you agree that your business information will be
            passed to that provider’s site for the purpose of facilitating a
            transaction with that provider. Your purchase, download or use of
            third-party applications is governed by such third party’s terms and
            conditions and privacy policy, as applicable. Thryv, Inc. disclaims
            all liability for the functionality of any third-party applications
            and, unless otherwise expressly provided, for their interoperability
            with Thryv.
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col size="md-6" className="text-center">
          {activeOrDisabled.isActive ? (
            <span style={{ color: 'red' }}>
              *Please accept our Terms & Conditions.
            </span>
          ) : null}
          <form style={{ paddingBottom: 15 }}>
            <input type="checkbox" onChange={handleChange} />{' '}
            <span className="txt12">
              By checking this box, client agrees to our{' '}
              <u>
                <a
                  className="thryv-blue semibold"
                  style={{ color: '#666' }}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://corporate.thryv.com/thryv-terms/"
                >
                  Terms &amp; Conditions
                </a>
              </u>
              .
            </span>
          </form>
          <button
            className="btn btn-fill-orange"
            disabled={activeOrDisabled.disabled}
            onClick={handleClick}
          >
            Connect
          </button>
        </Col>
      </Row>
    </Container>
  )
}

export default Signatures
