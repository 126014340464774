import { useEffect, useState } from 'react'

import { Col, Container, Row } from '../../components/Grid'
import { Jumbotron } from '../../components/Jumbotron'
import { LogoContainer } from '../../components/ui/home-page-layout'

const ShopifySettings = () => {
  const [message, setMessage] = useState('')
  const params = new URLSearchParams(window.location.search)
  const status = params.get('status')

  useEffect(() => {
    if (status === 'new') {
      setMessage(
        <>
          Your Shopify app is syncing with Thryv. You will receive an email
          notification and inbox message from &quot;
          <b>
            <i>Thryv Specialist</i>
          </b>
          &quot; when the sync is complete.
        </>
      )
    } else {
      setMessage(
        <>
          Your Shopify app is installed.
          <br />
          <br />
          To be taken to your Thryv account, click{' '}
          <a href="https://go.thryv.com/app/dashboard">here.</a>
          <br />
          <br />
          If you would like to uninstall it, please navigate to your Shopify
          account.
          <br />
          For assistance and guidance, please reference our Shopify integration
          guide{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://learn.thryv.com/hc/en-us/articles/360046260572-Shopify"
          >
            here.
          </a>
        </>
      )
    }
  }, [])

  return (
    <Container fluid>
      <Jumbotron>
        <LogoContainer>
          <img
            src="https://thryvapps.wpengine.com/media/thryvappSM.png"
            style={{
              marginRight: '10px',
              height: '75px',
              width: '75px',
              marginTop: '-20px',
              borderRadius: '10%'
            }}
          />
          <i className="fa fa-exchange fa-2x"></i>
          <img
            src="https://thryvapps.wpengine.com/media/shopify.png"
            style={{
              marginLeft: '20px',
              height: '80px',
              width: '80px',
              marginTop: '-20px',
              borderRadius: '10%'
            }}
          />
        </LogoContainer>
      </Jumbotron>
      <Row className="justify-content-center">
        <Col size="md-6">
          <p className="lead mt-5 step1" style={{ textAlign: 'center' }}>
            {message}
          </p>
        </Col>
      </Row>
    </Container>
  )
}

export default ShopifySettings
