import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useLocation } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import CloverSuccessModal from './CloverSuccessModal'
import ThryvGuyExcited from '../../assets/thryvguy_excited.png'
import NavLayout from '../../components/nav-layout/nav-layout'
import { LogoContainer } from '../../components/ui/home-page-layout'
import { authClient } from '../../util/auth-client'

const CloverSettings = () => {
  const axiosClient = authClient()
  const { user } = useAuth0()
  const [isInstalled, setIsInstalled] = useState(false)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const merchantId = queryParams.get('merchant_id')
  const employeeId = queryParams.get('employee_id')
  const authCode = queryParams.get('code')

  const { REACT_APP_THRYV_URL } = process.env

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const {
          data: { accessToken }
        } = await createAccessToken(authCode)
        await sendUserData(accessToken)
        setIsInstalled(true)
      } catch (error) {
        toast.error(
          `An error occurred while getting your access token. Please contact Thryv Support if the issue persists.`
        )
      }
    }
    getAccessToken()
  }, [authCode, merchantId, employeeId])

  const createAccessToken = async authCode => {
    return axiosClient({
      method: 'post',
      url: '/api/clover/oauth',
      data: {
        authCode
      }
    })
  }

  const sendUserData = async cloverAccessToken => {
    await axiosClient({
      method: 'post',
      url: '/api/clover/send-user-data',
      data: {
        thryvId: user.businessId,
        employeeId,
        merchantId,
        cloverAccessToken
      }
    })
  }

  return (
    <>
      {isInstalled && (
        <CloverSuccessModal
          closeModal={() => {
            setIsInstalled(false)
            window.location.href = `${REACT_APP_THRYV_URL}/app`
          }}
        />
      )}
      <ToastContainer />
      <NavLayout title={'Clover'} />
      <div className="wrapper mt-5">
        <div className="container text-center">
          <p className="h1 black">
            Please wait while your Clover app is configured.
          </p>
          <LogoContainer>
            <img
              className="mx-4 mt-5"
              src={ThryvGuyExcited}
              alt="Thryv guy waiting"
            />
          </LogoContainer>
        </div>
      </div>
    </>
  )
}

export default CloverSettings
