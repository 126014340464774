import { useNavigate } from 'react-router-dom'

import mailchimpLogo from '../../assets/Mailchimp-Logo.png'
import HomePageLayout from '../../components/home-page-layouts/home-page-layout'

import '../../css/App.css'

const MailChimp = () => {
  const appName = 'Mailchimp'
  const navigate = useNavigate()
  const handleClick = e => {
    e.preventDefault()
    navigate('/mailchimp-settings')
  }
  const mailchimpSections = [
    {
      header: 'What will this application need access to?',
      statements: ['Your Mailchimp audiences']
    },

    {
      header: 'What will this application do?',
      statements: [
        'Sync current Contacts from Thryv to Mailchimp.',
        'Sync current Contacts from Mailchimp to Thryv.',
        'Ongoing two-way sync of contacts between Thryv and Mailchimp.'
      ]
    }
  ]

  const appIconStyle = {
    height: '80px',
    width: '',
    marginTop: '-20px'
  }

  return (
    <HomePageLayout
      sections={mailchimpSections}
      onSubmit={handleClick}
      appName={appName}
      appIconSource={mailchimpLogo}
      appIconStyle={appIconStyle}
    />
  )
}

export default MailChimp
