import styled from '@emotion/styled'

export const InstagramJumbotron = styled.div`
  clear: both;
  padding-top: 90px;
  text-align: center;
  margin-bottom: 20px;

  & .login-name {
    font-weight: bold;
  }

  & .text-block {
    margin-top: 10px;
  }

  & .next-instructions {
    margin-top: 3rem;
    width: 50%;
    display: inline-block;
    margin-bottom: 30px;
  }

  & .toggle1 {
    position: absolute;
    color: #d5d5d6;
    margin-top: 3px;
    margin-left: -25px;
  }
  & .toggle2 {
    color: #ff5000;
    position: absolute;
    margin-top: 3px;
    margin-left: 5px;
    margin-right: 5px;
  }
`

export const ProfileContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  gap: 0.3em;
`

export const LoginContainer = styled.div`
  text-align: center;
`

export const OptionsBar = styled.div`
  display: flex;
  justify-content: center;

  & .action-button {
    min-width: 120px;
    height: 50px;
    margin: 5rem;
  }
`

export const UninstallContainer = styled.div`
  display: grid;
  width: 100%;
  padding: 5rem 15px 5px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;

  & .uninstall-msg {
    margin-bottom: 2rem;
  }

  & .uninstall-thryv-guy {
    width: auto;
    max-height: 400px;
    margin: auto;
  }
`
export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`
