import SettingsLayout from '../../components/settings-layout/settings-layout'
import { appIdConfig } from '../../util/integry-app-id'

const JotformSettings = () => {
  const appName = 'Jotform'
  const appKey = process.env.REACT_APP_INTEGRY_APP_KEY
  const appId = appIdConfig.jotform

  return <SettingsLayout appName={appName} appKey={appKey} appId={appId} />
}

export default JotformSettings
