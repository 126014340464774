import { Header, ParagraphText } from '@thryvlabs/maverick'
import PropTypes from 'prop-types'

import Sections from './layouts/sections'
import TermsAndConditions from './layouts/terms-conditions'
import thryvLogo from '../../assets/thryvappSM.png'
import '../../css/App.css'
import {
  LogoContainer,
  HeaderContainer,
  ContextContainer,
  AppLogo,
  MainContainer,
  TermsContainer
} from '../ui/home-page-layout'

const HomePageLayout = ({
  sections,
  onSubmit,
  appName,
  warning,
  appIconSource,
  appIconStyle
}) => {
  return (
    <MainContainer>
      <HeaderContainer>
        <LogoContainer>
          <img
            src={thryvLogo}
            className="thryv-logo"
            data-testid="thryv-logo"
          />
          <i className="fa fa-exchange fa-2x"></i>
          <AppLogo appIconStyle={appIconStyle}>
            <img
              src={appIconSource}
              className="app-logo"
              data-testid="app-logo"
            />
          </AppLogo>
        </LogoContainer>
        <Header fontWeight="normal" variant="h3">
          &apos;{appName}&apos; is requesting permission to access your Thryv
          account.
        </Header>
      </HeaderContainer>

      <ContextContainer>
        {warning && (
          <TermsContainer>
            <ParagraphText
              variant="lg"
              color="thryv-black-700"
              className="terms-text warning-text"
            >
              <i
                className="fa fa-exclamation-circle icon-style"
                aria-hidden="true"
              />
              {warning}
            </ParagraphText>
          </TermsContainer>
        )}
        {sections && <Sections key={sections} sections={sections} />}
        <TermsAndConditions handleClick={onSubmit} />
      </ContextContainer>
    </MainContainer>
  )
}

Sections.prototype = {
  sections: PropTypes.array,
  onSubmit: PropTypes.func,
  appName: PropTypes.string,
  appIconSource: PropTypes.string,
  marginLeft: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  marginTop: PropTypes.string
}

export default HomePageLayout
