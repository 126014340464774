import { Header, Toggle, Select } from '@thryvlabs/maverick'

import { HOURS } from './constants'
import {
  HoursSelectionContainer,
  HoursSelectionTable,
  HourAndMerideimSelection,
  ErrorMessage
} from '../../components/ui/gbm-style'
import { compareTimes } from '../../util/compareTimes'

const SelectionRow = ({ day, setBusinessHours }) => {
  const handleOpenClose = () => {
    setBusinessHours(prevHours =>
      prevHours.map(prevDayData => {
        if (prevDayData.day === day.day) {
          return {
            ...prevDayData,
            open: !prevDayData.open,
            openBeforeClose: true,
            openTime: prevDayData.open
              ? { name: '', value: '' }
              : { name: '9:00', value: '9:00' },
            openMeridiem: prevDayData.open
              ? { name: '', value: '' }
              : { name: 'AM', value: 'AM' },
            closeTime: prevDayData.open
              ? { name: '', value: '' }
              : { name: '5:00', value: '5:00' },
            closeMeridiem: prevDayData.open
              ? { name: '', value: '' }
              : { name: 'PM', value: 'PM' }
          }
        }
        return prevDayData
      })
    )
  }

  const handleOpenTime = newTime => {
    setBusinessHours(prevHours =>
      prevHours.map(prevDayData => {
        if (prevDayData.day === day.day) {
          return {
            ...prevDayData,
            openBeforeClose: compareTimes(
              newTime.value,
              prevDayData.openMeridiem.value,
              prevDayData.closeTime.value,
              prevDayData.closeMeridiem.value
            ),
            openTime: newTime
          }
        }
        return prevDayData
      })
    )
  }

  const handleOpenMeridiem = newMeridiem => {
    setBusinessHours(prevHours =>
      prevHours.map(prevDayData => {
        if (prevDayData.day === day.day) {
          return {
            ...prevDayData,
            openBeforeClose: compareTimes(
              prevDayData.openTime.value,
              newMeridiem.value,
              prevDayData.closeTime.value,
              prevDayData.closeMeridiem.value
            ),
            openMeridiem: newMeridiem
          }
        }
        return prevDayData
      })
    )
  }

  const handleCloseTime = newTime => {
    setBusinessHours(prevHours =>
      prevHours.map(prevDayData => {
        if (prevDayData.day === day.day) {
          return {
            ...prevDayData,
            openBeforeClose: compareTimes(
              prevDayData.openTime.value,
              prevDayData.openMeridiem.value,
              newTime.value,
              prevDayData.closeMeridiem.value
            ),
            closeTime: newTime
          }
        }
        return prevDayData
      })
    )
  }

  const handleCloseMeridiem = newMeridiem => {
    setBusinessHours(prevHours =>
      prevHours.map(prevDayData => {
        if (prevDayData.day === day.day) {
          return {
            ...prevDayData,
            openBeforeClose: compareTimes(
              prevDayData.openTime.value,
              prevDayData.openMeridiem.value,
              prevDayData.closeTime.value,
              newMeridiem.value
            ),
            closeMeridiem: newMeridiem
          }
        }
        return prevDayData
      })
    )
  }

  return (
    <>
      <div>{day.day}</div>
      <Toggle
        label={day.open ? 'Open' : 'Closed'}
        toggleSize="normal"
        onChange={handleOpenClose}
        toggled={day.open}
      />
      {day.open ? (
        <HourAndMerideimSelection>
          <Select
            options={HOURS}
            selectLabel="Opening Hour"
            selectedOption={day.openTime}
            setSelectedOption={handleOpenTime}
            width="md"
          />
          <Select
            options={[
              { name: 'AM', value: 'AM' },
              { name: 'PM', value: 'PM' }
            ]}
            selectLabel="Meridiem"
            selectedOption={day.openMeridiem}
            setSelectedOption={handleOpenMeridiem}
            width="md"
          />
        </HourAndMerideimSelection>
      ) : (
        <div></div>
      )}
      {day.open ? (
        day.openBeforeClose ? (
          <div>To</div>
        ) : (
          <ErrorMessage>Invalid hours: Closing before Opening</ErrorMessage>
        )
      ) : (
        <div></div>
      )}
      {day.open ? (
        <HourAndMerideimSelection>
          <Select
            options={HOURS}
            selectLabel="Closing Hour"
            selectedOption={day.closeTime}
            setSelectedOption={handleCloseTime}
            width="md"
          />
          <Select
            options={[
              { name: 'AM', value: 'AM' },
              { name: 'PM', value: 'PM' }
            ]}
            selectLabel="Meridiem"
            selectedOption={day.closeMeridiem}
            setSelectedOption={handleCloseMeridiem}
            width="md"
          />
        </HourAndMerideimSelection>
      ) : (
        <div></div>
      )}
    </>
  )
}

export default function GBM({ businessHours, setBusinessHours }) {
  return (
    <>
      <HoursSelectionContainer>
        <Header fontWeight="bold" variant="h5">
          Business Hours
        </Header>
        <HoursSelectionTable>
          {businessHours.map(day => (
            <SelectionRow
              key={day.day}
              day={day}
              setBusinessHours={setBusinessHours}
            />
          ))}
        </HoursSelectionTable>
      </HoursSelectionContainer>
    </>
  )
}
