import { Toggle } from '@thryvlabs/maverick'

import { ButtonContainer } from './ui/switch-button-style'

export const SwitchButton = ({ defaultValue, changeFunc, disabled }) => {
  return (
    <ButtonContainer>
      <Toggle
        toggleSize="normal"
        onChange={changeFunc}
        toggled={defaultValue}
        className="import-switch"
        data-testid="switch-button"
        disabled={disabled}
      />
    </ButtonContainer>
  )
}