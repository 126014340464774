import styled from '@emotion/styled'

export const GridContainer = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`

export const GridItem = styled.div`
  padding: 20px;
  text-align: justify;
  width: 100%;

  h5 {
    margin-bottom: -0.1rem;
  }
`

export const ErrorMessage = styled.p`
  color: #e22020;
  text-align: left;
  margin-top: 4px;
`

export const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 12px;
  border-radius: 50%;
  overflow: hidden;
`

export const GBMProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin-bottom: -0.1rem;
  }
`

export const LoaderScreen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`

export const ContactPageGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

export const ContactPageGridItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
  border-right: 1px solid #ddd;

  &:nth-of-type(2n) {
    border-right: none;
  }

  @media (max-width: 600px) {
    border-right: none;
  }
`

export const IconStyle = styled.span`
  font-size: 36px;
  margin-bottom: 12px;
`

export const ContactSupportPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85vh;
  text-align: center;
  width: 100%;
  flex-direction: column;
`
export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export const GBMSelectionContainer = styled.div`
  display: flex;
  top: 45%;
  position: absolute;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50%;
  height: 200px;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  .gbm-selection-button-container {
    display: flex;
    padding-top: 40px;
    width: 50%;
    gap: 50px;
    justify-content: center;

    .gbm-selection {
      height: 40px;
      font-size: 16px;

      &.small-button {
        width: 10%;
        min-width: 80px;
      }

      &.medium-button {
        width: 45%;
        min-width: 190px;
      }
    }
  }
`
export const GBMErrorThryvGuy = styled.img`
  width: 250px;
  height: 280px;
`

export const GBMInstructionImage = styled.img`
  width: 700px;
  height: 630px;
`

export const GBMErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-top: 10%;
  padding-right: 50px;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
`

export const HoursSelectionContainer = styled.div`
  padding: 20px;
`

export const HoursSelectionTable = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 2fr 4fr 1fr 4fr;
  gap: 8px;
`

export const HourAndMerideimSelection = styled.div`
  display: flex;
  gap: 10px;
  margin-top -.5rem;

  div:nth-of-type(2) {
    width: calc(40%);
  }
`
