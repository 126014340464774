import { useNavigate } from 'react-router-dom'

import constantContactLogo from '../../assets/constant_contact_logo.png'
import HomePageLayout from '../../components/home-page-layouts/home-page-layout'

import '../../css/App.css'

const ConstantContact = () => {
  const appName = 'Constant Contact'
  const navigate = useNavigate()
  const handleClick = e => {
    e.preventDefault()
    navigate('/constant-contact-settings')
  }
  const constantContactSections = [
    {
      header: 'What will this application need access to?',
      statements: ['Your Constant Contact']
    },

    {
      header: 'What will this application do?',
      statements: ['Sync current Contacts between Thryv and Constant Contact']
    }
  ]

  const appIconStyle = {
    marginLeft: '15px',
    height: '80px',
    width: '300px',
    marginTop: '-18px'
  }

  return (
    <HomePageLayout
      sections={constantContactSections}
      onSubmit={handleClick}
      appName={appName}
      appIconSource={constantContactLogo}
      appIconStyle={appIconStyle}
    />
  )
}

export default ConstantContact
