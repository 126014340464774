import { Button, ParagraphText } from '@thryvlabs/maverick'

import GBMImage from '../../assets/gbm_error.png'
import ThryvGuy from '../../assets/thryv_guy.png'
import NavLayout from '../../components/nav-layout/nav-layout'
import {
  GBMErrorMessageContainer,
  GBMErrorThryvGuy,
  GBMInstructionImage
} from '../../components/ui/gbm-style'

const GBMError = () => {
  const navigateToThryvSettings = () => {
    window.location.href = 'https://go.thryv.com/app/setting'
  }

  return (
    <>
      <NavLayout title={`Google Business Messages`} showButton={false} />
      <div className="flex flex-col justify-center content-center items-center">
        <div className="flex flex-row justify-center">
          <GBMErrorMessageContainer>
            <ParagraphText
              data-testid="gbm-error-message"
              variant="lg"
              color="thryv-black-700"
            >
              Oops! It looks like your account is missing a Business logo in
              Thryv settings.
              <br></br>
              <br></br>A Business logo is required for the Google Business
              Messages app. We display your logo to your customers when they are
              chatting with you.
              <br></br>
              <br></br>
              Please go to Business Settings and add your logo.{' '}
              <a
                data-testid="guide-link"
                href="https://learn.thryv.com/hc/en-us/articles/360002609131-My-Business-Settings#h_1"
              >
                Here is a guide!
              </a>
              <br></br>
              <br></br>
              Once completed, you can install the Google Business Messages app.
            </ParagraphText>
            <GBMErrorThryvGuy
              data-testid="gmb-thryv-guy-img"
              src={ThryvGuy}
              alt="thryv-guy"
            ></GBMErrorThryvGuy>
          </GBMErrorMessageContainer>
          <GBMInstructionImage
            data-testid="gmb-instruction-image"
            src={GBMImage}
            alt="gbm"
          ></GBMInstructionImage>
        </div>

        <div className="py-4">
          <Button
            variant="primary"
            data-testid="go-back-thryv-button"
            onClick={navigateToThryvSettings}
          >
            Go to Thryv Business Settings
          </Button>
        </div>
      </div>
    </>
  )
}

export default GBMError
