import './index.css'
import { Modal, ModalTitle } from '@thryvlabs/maverick/dist'
import Loader from 'react-loader-spinner'

import { LoaderContainer } from '../ui/instagram-style'

export function AppsModal(props) {
  return (
    <>
      {props.show === true && (
        <Modal
          variant="default"
          title={<span style={{ color: 'green' }}>Success!</span>}
          openOnLoad
          footer
          action
          btnActionText="OK"
          btnAction={props.onHide}
          altBtn={<></>}
        >
          <ModalTitle variant="subtitle" size="lg">
            {props.appName} has been {props.appAction}!
          </ModalTitle>
        </Modal>
      )}
    </>
  )
}

export function LoadingModal(props) {
  return (
    <>
      {props.show === true && (
        <Modal
          variant="default"
          title={
            <center>
              Hang on just a few seconds while we process your request.
            </center>
          }
          openOnLoad
          footer
          altBtn={<></>}
        >
          <LoaderContainer>
            <Loader type="ThreeDots" color="#FF5000" height={80} width={80} />
          </LoaderContainer>
        </Modal>
      )}
    </>
  )
}
