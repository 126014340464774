import { useAuth0 } from '@auth0/auth0-react'
import { Header } from '@thryvlabs/maverick'
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai'

import {
  ContactPageGridContainer,
  ContactPageGridItem,
  ContactSupportPage,
  IconStyle
} from '../../components/ui/gbm-style'

const GBMNoSelection = () => {
  const { user } = useAuth0()

  const emailToHref = `mailto:support@thryv.com?&subject=GMB Ownership Request&body=ThryvID: ${user.businessId}`

  return (
    <ContactSupportPage>
      <div className="pb-3">
        <Header fontWeight="semibold" variant="h1">
          Contact Thryv Support
        </Header>
      </div>
      <ContactPageGridContainer>
        <ContactPageGridItem>
          <IconStyle>
            <AiOutlinePhone data-testid="AiOutlinePhone" />
          </IconStyle>
          <p>
            <a href="tel:8443396334">844-339-6334</a>
          </p>
        </ContactPageGridItem>

        <ContactPageGridItem>
          <IconStyle>
            <AiOutlineMail data-testid="AiOutlineMail" />
          </IconStyle>

          <a className="orange-btn" href={emailToHref}>
            Send Email
          </a>
          <p>*This email response could take up to 48 hours!</p>
        </ContactPageGridItem>
      </ContactPageGridContainer>
    </ContactSupportPage>
  )
}

export default GBMNoSelection
