export function Jumbotron({ children }) {
  return (
    <div
      style={{
        height: 400,
        clear: 'both',
        paddingTop: 120,
        textAlign: 'center',
        background: '#eceeef',
        marginBottom: 55
      }}
    >
      {children}
    </div>
  )
}
