import { Modal, ModalTitle, ParagraphText } from '@thryvlabs/maverick/'

export default function InstallSuccessModal({ app, shouldShow, closeModal }) {
  const urls = {
    RingCentral:
      'https://learn.thryv.com/hc/en-us/articles/360061219971-Ring-Central',
    Twilio: 'https://learn.thryv.com/hc/en-us/articles/360060738071-Twilio'
  }

  return (
    <>
      {shouldShow && (
        <Modal
          variant="default"
          openOnLoad
          footer
          action
          btnActionText="OK"
          btnAction={closeModal}
          altBtn={<></>}
          hideX
          modalClassNames="center"
        >
          <div className="text-center">
            <ModalTitle variant="subtitle" size="lg">
              Success!
            </ModalTitle>
            <ParagraphText variant="reg">
              You have successfully installed the {app} app for Thryv! Send
              yourself a test call by dialing your {app} number to see how it
              works. If you have questions you can refer to this{' '}
              <a
                className="thryv-blue semibold"
                target="_blank"
                rel="nofollow noreferrer"
                href={urls[app]}
              >
                guide
              </a>{' '}
              or contact our Thryv Support team! &#127881;
            </ParagraphText>
          </div>
        </Modal>
      )}
    </>
  )
}
