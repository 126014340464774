/* eslint-disable no-useless-escape */

import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import {
  Avatar,
  ParagraphText,
  Section,
  Button,
  Icon,
  Modal,
  CopyToClipboard
} from '@thryvlabs/maverick'
import { SketchPicker } from 'react-color'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import { AppsModal, LoadingModal } from '../../components/Modal'
import NavLayout from '../../components/nav-layout/nav-layout'
import {
  ChatHeader,
  ColorPickerContainer,
  PopoverWrapper,
  SectionButtonContainer,
  WebChatHeaderMessageContainer,
  WebChatMainContainer
} from '../../components/ui/web-chat-style'
import { authClient } from '../../util/auth-client'

export default function WebChat() {
  const [isInstalled, setIsInstalled] = useState(false)
  const [textColor, setTextColor] = useState()
  const [buttonColor, setButtonColor] = useState()
  const [headerColor, setHeaderColor] = useState()
  const [integrationId, setIntegrationId] = useState()
  const [showHeaderColorPicker, setShowHeaderColorPicker] = useState(false)
  const [showTextColorPicker, setShowTextColorPicker] = useState(false)
  const [showButtonColorPicker, setShowButtonColorPicker] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [appAction, setAppAction] = useState()
  const {
    user: { businessId: thryvId, dirCode }
  } = useAuth0()
  const navigate = useNavigate()
  const axiosClient = authClient()

  useEffect(() => {
    const getChatSettings = async () => {
      try {
        const {
          data: {
            user: {
              brand_color,
              conversation_color,
              action_color,
              integration_id
            }
          }
        } = await axiosClient({
          url: `/api/thryv-user/web-chat/user/${thryvId}`
        })
        setHeaderColor(brand_color)
        setTextColor(conversation_color)
        setButtonColor(action_color)
        setIntegrationId(integration_id)
        setIsInstalled(true)
      } catch (error) {
        toast.error(
          `An error occurred getting your web chat settings. Please contact Thryv Support if this issue persists.`
        )
      }
    }
    getChatSettings()
  }, [])

  useEffect(() => {
    const getColorSetting = async () => {
      try {
        const {
          data: { brandColor }
        } = await axiosClient(
          `/api/thryv-user/web-chat/color-setting/${thryvId}/${dirCode}`
        )
        setHeaderColor(brandColor)
      } catch (error) {
        toast.error(
          `An error occurred retrieving your saved color settings. Please contact Thryv Support if this issue persists.`
        )
      }
    }
    getColorSetting()
  }, [])

  const installColorSettings = async () => {
    try {
      setShowLoader(true)
      const body = {
        actionColor: buttonColor,
        brandColor: headerColor,
        conversationColor: textColor,
        thryvId
      }
      const {
        data: { script }
      } = await axiosClient({
        method: 'post',
        url: '/api/thryv-user/web-chat/install',
        data: body
      })
      setShowLoader(false)
      navigate('/webMessengerNext', {
        state: {
          script
        }
      })
    } catch (error) {
      setShowLoader(false)
      toast.error(
        'An error occurred while installing WebChat. Please contact Thryv Support if this issue persists.'
      )
    }
  }

  const updateColorSetting = async () => {
    try {
      setShowLoader(true)
      const body = {
        actionColor: buttonColor,
        brandColor: headerColor,
        conversationColor: textColor,
        thryvId
      }
      await axiosClient({
        method: 'patch',
        url: '/api/thryv-user/web-chat/update',
        data: body
      })
      setAppAction('Updated')
      setModalShow(true)
      setShowLoader(false)
    } catch (error) {
      setShowLoader(false)
      toast.error(
        'An error occurred while updating WebChat. Please contact Thryv Support if this issue persists.'
      )
    }
  }

  const uninstallSetting = async () => {
    setShowLoader(true)
    try {
      await axiosClient({
        method: 'patch',
        url: `/api/thryv-user/web-chat/uninstall/${thryvId}`
      })
      setAppAction('uninstalled')
      setModalShow(true)
      setShowLoader(false)
    } catch (error) {
      setShowLoader(false)
      toast.error(
        'An error occurred while uninstalling WebChat. Please contact Thryv Support if this issue persists.'
      )
    }
  }

  const navigateToThryvMarket = () => {
    window.location.href = 'https://go.thryv.com/app/app-market'
  }

  const userPickedColors = !headerColor || !buttonColor || !textColor

  const requiredFieldMessage = userPickedColors && (
    <div>
      <ParagraphText variant="lg" color="utility-error-danger">
        You must select your color settings before proceeding with the{' '}
        {isInstalled ? 'update.' : 'installation.'}
      </ParagraphText>
    </div>
  )
  return (
    <div>
      <ToastContainer />
      <NavLayout title={`WebChat`} showButton={false} />
      {showLoader && <LoadingModal show={showLoader} />}
      {modalShow && (
        <AppsModal
          show={modalShow}
          appName="WebChat"
          appAction={appAction}
          onHide={() => {
            setModalShow(false)
            window.location.href = 'https://go.thryv.com/app'
          }}
        />
      )}

      <WebChatHeaderMessageContainer>
        <p className="lead mt-2 step1" style={{ fontSize: 'x-large' }}>
          You can choose the color combination below which suits your website.
        </p>
        <p>
          Please customize the below colors to best suit your website.
          Don&apos;t worry, if you don&apos;t like your initial selection this
          can always be updated!
        </p>
      </WebChatHeaderMessageContainer>
      <WebChatMainContainer textColor={textColor} buttonColor={buttonColor}>
        <div className="chat-settings-container">
          <div className="chat-container">
            <div className="chat-inner-container">
              <ChatHeader backgroundColor={headerColor}>
                <Icon
                  height="16"
                  width="16"
                  type="regular"
                  variant="arrowLeft"
                  color="white"
                />
                <span>Thryv chat</span>{' '}
                <Icon
                  height="16"
                  width="16"
                  type="regular"
                  variant="x"
                  color="white"
                />
              </ChatHeader>

              <div className="chat-message">
                <div className="business-message chat mr-2 p-3">
                  Hello and thank you for using Thryv.{' '}
                </div>

                <Avatar
                  variant="name"
                  size="medium"
                  name={{
                    firstName: 'Thryv',
                    lastName: 'User'
                  }}
                  staticBackground
                  backgroundColor="#FF5000"
                />
              </div>
              <div className="chat-message">
                <Avatar
                  variant="name"
                  size="medium"
                  name={{
                    firstName: 'Thryv',
                    lastName: 'Client'
                  }}
                  staticBackground
                  backgroundColor="#99c0e3"
                />
                <div className="client-message chat ml-2 p-3">
                  I would like to schedule an introductory demo call if
                  possible.
                </div>
              </div>
              <div className="chat-message">
                <div className="business-message chat mr-2 p-3">
                  Absolutely, please use the &apos;Book&apos; button below to do
                  so.{' '}
                </div>
                <Avatar
                  variant="name"
                  size="medium"
                  name={{
                    firstName: 'Thryv',
                    lastName: 'User'
                  }}
                  staticBackground
                  backgroundColor="#FF5000"
                />
              </div>
              <div className="chat-message">
                <div className="business-message chat ml-5 p-3">
                  <p> Introductory Phone </p>
                  <center>
                    <Button variant="primary" className="chat-button">
                      Book
                    </Button>
                  </center>
                </div>
              </div>
              <div className="chat-message">
                <Avatar
                  variant="name"
                  size="medium"
                  name={{
                    firstName: 'Thryv',
                    lastName: 'Client'
                  }}
                  staticBackground
                  backgroundColor="#99c0e3"
                />
                <div className="client-message chat ml-2 p-3">Thank you!</div>
              </div>
              <div className="form-group px-3">
                {' '}
                <textarea
                  className="chat-input form-control"
                  rows="5"
                  placeholder="Type your message"
                ></textarea>{' '}
              </div>
            </div>
          </div>
          <div className="color-settings-container">
            <Section subSize="md" title="Color Settings" titleVariant="primary">
              <Section
                subSize="md"
                subtitle="Customize Messenger Header Color"
                title="Header Color"
                titleVariant="primary"
              >
                <SectionButtonContainer boxColor={headerColor}>
                  <Button
                    data-testid="header-color-button"
                    variant="secondary"
                    onClick={() => setShowHeaderColorPicker(state => !state)}
                  >
                    Pick Header Color
                  </Button>
                  <div className="reference-color-box"></div>
                </SectionButtonContainer>
                <div className="reference-color-box"></div>
                <div className="siderbar">
                  {showHeaderColorPicker && (
                    <PopoverWrapper>
                      <ColorPickerContainer
                        onClick={() => setShowHeaderColorPicker(false)}
                      />
                      <SketchPicker
                        color={headerColor}
                        onChange={color => setHeaderColor(color.hex)}
                      />
                    </PopoverWrapper>
                  )}
                </div>
              </Section>
              <Section
                subSize="md"
                subtitle="Customize Messenger Text Color"
                title="Text Color"
                titleVariant="primary"
              >
                <SectionButtonContainer boxColor={textColor}>
                  <Button
                    data-testid="text-color-button"
                    variant="secondary"
                    onClick={() => setShowTextColorPicker(state => !state)}
                  >
                    Pick Text Color
                  </Button>
                  <div className="reference-color-box"></div>
                </SectionButtonContainer>
                <div className="siderbar">
                  {showTextColorPicker && (
                    <PopoverWrapper>
                      <ColorPickerContainer
                        onClick={() => setShowTextColorPicker(false)}
                      />
                      <SketchPicker
                        color={textColor}
                        onChange={color => setTextColor(color.hex)}
                      />
                    </PopoverWrapper>
                  )}
                </div>
              </Section>
              <Section
                subSize="md"
                subtitle="Customize Messenger Button Color"
                title="Button Color"
                titleVariant="primary"
              >
                <SectionButtonContainer boxColor={buttonColor}>
                  <Button
                    data-testid="button-color-button"
                    variant="secondary"
                    onClick={() => setShowButtonColorPicker(state => !state)}
                  >
                    Pick Button Color
                  </Button>
                  <div className="reference-color-box"></div>
                </SectionButtonContainer>
                <div className="siderbar">
                  {showButtonColorPicker && (
                    <PopoverWrapper>
                      <ColorPickerContainer
                        onClick={() => setShowButtonColorPicker(false)}
                      />
                      <SketchPicker
                        color={buttonColor}
                        onChange={color => setButtonColor(color.hex)}
                      />
                    </PopoverWrapper>
                  )}
                </div>
              </Section>
            </Section>
          </div>
        </div>
        <div className="footer-container">
          {isInstalled ? (
            <>
              {requiredFieldMessage}
              <div className="buttons-container">
                <Button
                  data-testid="update-button"
                  variant="primary"
                  onClick={updateColorSetting}
                  disabled={userPickedColors}
                >
                  Update
                </Button>
                <Button
                  data-testid="uninstall-button"
                  variant="primary"
                  onClick={uninstallSetting}
                >
                  Uninstall
                </Button>
                <Button
                  data-testid="goback-button"
                  variant="primary"
                  onClick={navigateToThryvMarket}
                >
                  Go Back to thryv
                </Button>
                <Modal
                  data-testid="copy-script-modal"
                  variant="default"
                  btnText="Copy Script"
                  btnType="primary"
                  title="Script"
                >
                  <ParagraphText variant="reg">
                    If your site is not managed by Thryv you will need to add
                    the script tag below to your site in order to load the
                    WebChat widget. Please add the script to the end of your
                    header for optimal functionality.
                  </ParagraphText>
                  <CopyToClipboard
                    data-testid="copy-script-button"
                    text={`<script>
        setTimeout(() => {
            var integrationId = '${integrationId}'

            !(function (o, p, s, e, c) {
                var i,
                    a,
                    h,
                    u = [],
                    d = [];
                function t() {
                    var t = "You must provide a supported major version.";
                    try {
                        if (!c) throw new Error(t);
                        var e,
                            n = "https://cdn.smooch.io/",
                            r = "smooch";
                        if ((e = "string" == typeof this.response ? JSON.parse(this.response) : this.response).url) {
                            var o = p.getElementsByTagName("script")[0],
                                s = p.createElement("script");
                            s.async = !0;
                            var i = c.match(/([0-9]+)\.?([0-9]+)?\.?([0-9]+)?/),
                                a = i && i[1];
                            if (i && i[3]) s.src = n + r + "." + c + ".min.js";
                            else {
                                if (!(4 <= a && e["v" + a])) throw new Error(t);
                                s.src = e["v" + a];
                            }
                            o.parentNode.insertBefore(s, o);
                        }
                    } catch (e) {
                        e.message === t && console.error(e);
                    }
                }
                (o[s] = {
                    init: function () {
                        i = arguments;
                        var t = {
                            then: function (e) {
                                return d.push({ type: "t", next: e }), t;
                            },
                            catch: function (e) {
                                return d.push({ type: "c", next: e }), t;
                            },
                        };
                        return t;
                    },
                    on: function () {
                        u.push(arguments);
                    },
                    render: function () {
                        a = arguments;
                    },
                    destroy: function () {
                        h = arguments;
                    },
                }),
                    (o.__onWebMessengerHostReady__ = function (e) {
                        if ((delete o.__onWebMessengerHostReady__, (o[s] = e), i))
                            for (var t = e.init.apply(e, i), n = 0; n < d.length; n++) {
                                var r = d[n];
                                t = "t" === r.type ? t.then(r.next) : t.catch(r.next);
                            }
                        a && e.render.apply(e, a), h && e.destroy.apply(e, h);
                        for (n = 0; n < u.length; n++) e.on.apply(e, u[n]);
                    });
                var n = new XMLHttpRequest();
                n.addEventListener("load", t), n.open("GET", "https://" + e + ".webloader.smooch.io/", !0), (n.responseType = "json"), n.send();
            })(window, document, "Smooch", integrationId, "5");
            setTimeout(() => {
                Smooch.init({
                    integrationId: integrationId,
                    menuItems: {},
                    prechatCapture: {
                        enabled: true,
                        enableEmailLinking: true,
                        fields: [
                            {
                                type: 'email',
                                name: 'email',
                                label: 'Email',
                                placeholder: 'your@email.com'
                            },
                            {
                                type: 'text',
                                name: 'phone',
                                label: 'Phone',
                                placeholder: '555-555-5555'
                            },
                            {
                                type: 'text',
                                name: 'first_name',
                                label: 'First Name',
                                placeholder: 'first name'
                            },
                            {
                                type: 'text',
                                name: 'last_name',
                                label: 'Last Name',
                                placeholder: 'last name'
                            }
                        ]
                    },
                    customText: {
                      prechatCaptureGreetingText:
            "To start off, we'd like to know a little bit about you. \\n By providing your email and mobile number via this chat, you consent to our  Terms of Use and Privacy Policy (found in the footer of this site) and to us sending emails and text messages or placing voice calls to you for advertising and marketing purposes.  Giving this consent is not required as a condition of purchasing any goods or services."
                },
                }).then(
                    function () {
                        console.log("Script Ran for Sunshine");
                    },
                    function (err) {
                        console.log("Script Error for Sunshine");
                    }
                );
            }, 3000)
        }, 5000);
    </script>`}
                  />
                </Modal>
              </div>
            </>
          ) : (
            <>
              {requiredFieldMessage}
              <Button
                data-testid="install-button"
                variant="primary"
                onClick={installColorSettings}
                disabled={userPickedColors}
                className="install-button"
              >
                Install
              </Button>
            </>
          )}
        </div>
      </WebChatMainContainer>
    </div>
  )
}
