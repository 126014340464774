export function Container({ fluid, children }) {
  return (
    <div
      className={`container${fluid ? '-fluid' : ''}`}
      style={{ paddingBottom: 150 }}
    >
      {children}
    </div>
  )
}

export function Row({ fluid, children, className, style }) {
  return (
    <div
      style={style}
      className={`row${fluid ? '-fluid' : ''}${
        className ? ' ' + className : ''
      }`}
    >
      {children}
    </div>
  )
}

export function Col({ size, children, className }) {
  return (
    <div
      className={
        size
          .split(' ')
          .map(size => 'col-' + size)
          .join(' ') + `${className ? ' ' + className : ''}`
      }
    >
      {children}
    </div>
  )
}
