/* eslint-disable no-useless-escape */

import { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import {
  Button,
  CopyToClipboard,
  ParagraphText,
  Section
} from '@thryvlabs/maverick'
import { useLocation } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import { AppsModal } from '../../components/Modal'
import NavLayout from '../../components/nav-layout/nav-layout'
import {
  WebChatCodeReferenceContainer,
  WebChatHeaderMessageContainer,
  WebChatNextContainer,
  WebChatOptionsContainer
} from '../../components/ui/web-chat-style'
import { authClient } from '../../util/auth-client'

export default function WebChatNext() {
  const [modalShow, setModalShow] = useState(false)
  const { user } = useAuth0()
  const axiosClient = authClient()
  const {
    state: { script }
  } = useLocation()
  const thryvId = user.businessId

  const AlertWebTeam = async () => {
    try {
      await axiosClient({
        method: 'patch',
        url: `/api/thryv-user/web-chat/internal/${thryvId}`
      })
      setModalShow(true)
    } catch (error) {
      toast.error(
        'Something went wrong integrating WebChat. Please contact Thryv Support if this issue persists.'
      )
      console.log(error)
    }
  }

  const ReturnToThryv = async () => {
    try {
      const body = {
        thryvId,
        script
      }
      await axiosClient({
        method: 'post',
        url: '/api/thryv-user/web-chat/external',
        data: body
      })
      window.location.replace('https://go.thryv.com/app')
    } catch (error) {
      toast.error(
        'Something went wrong integrating WebChat. Please contact Thryv Support if this issue persists.'
      )
      console.log(error)
    }
  }

  return (
    <div>
      <ToastContainer />
      {modalShow && (
        <AppsModal
          show={modalShow}
          appName="WebChat"
          appAction="Installed"
          onHide={() => {
            setModalShow(false)
            window.location.href = 'https://go.thryv.com/app'
          }}
        />
      )}
      <NavLayout title={`WebChat`} showButton={false} />
      <WebChatHeaderMessageContainer>
        <p className="lead mt-2 step1" style={{ fontSize: 'x-large' }}>
          Please select whether your website is managed by Thryv or by an
          external company.
        </p>
      </WebChatHeaderMessageContainer>
      <WebChatNextContainer>
        <WebChatOptionsContainer>
          <Section title="For Internal Site" titleVariant="primary">
            <ParagraphText variant="lg">
              We&apos;re almost there! We have made a successful integration and
              just need to take one more step to get WebChat up and running. If
              you have a website managed by Thryv, click the below button and we
              will open a ticket with our web team to add WebChat to your
              website and make sure everything is running smoothly!
            </ParagraphText>
            <Button
              data-testid="alert-button"
              variant="primary"
              onClick={AlertWebTeam}
            >
              Alert Web Team
            </Button>
          </Section>

          <Section title="For External Site" titleVariant="primary">
            <ParagraphText variant="lg">
              If your site is not managed by Thryv you will need to add the
              script tag below to your site in order to load the WebChat widget.
              Please add the script to the end of your header for optimal
              functionality. If you ever need to access the script tag again
              just relaunch the WebChat app and you will find the script tag
              displayed on the page. When you are ready click the button below
              to return to your Thryv.
            </ParagraphText>
            <WebChatCodeReferenceContainer>
              <CopyToClipboard
                text={`<script>
    setTimeout(() => {
        var integrationId = '${script}'

        !(function (o, p, s, e, c) {
            var i,
                a,
                h,
                u = [],
                d = [];
            function t() {
                var t = "You must provide a supported major version.";
                try {
                    if (!c) throw new Error(t);
                    var e,
                        n = "https://cdn.smooch.io/",
                        r = "smooch";
                    if ((e = "string" == typeof this.response ? JSON.parse(this.response) : this.response).url) {
                        var o = p.getElementsByTagName("script")[0],
                            s = p.createElement("script");
                        s.async = !0;
                        var i = c.match(/([0-9]+)\.?([0-9]+)?\.?([0-9]+)?/),
                            a = i && i[1];
                        if (i && i[3]) s.src = n + r + "." + c + ".min.js";
                        else {
                            if (!(4 <= a && e["v" + a])) throw new Error(t);
                            s.src = e["v" + a];
                        }
                        o.parentNode.insertBefore(s, o);
                    }
                } catch (e) {
                    e.message === t && console.error(e);
                }
            }
            (o[s] = {
                init: function () {
                    i = arguments;
                    var t = {
                        then: function (e) {
                            return d.push({ type: "t", next: e }), t;
                        },
                        catch: function (e) {
                            return d.push({ type: "c", next: e }), t;
                        },
                    };
                    return t;
                },
                on: function () {
                    u.push(arguments);
                },
                render: function () {
                    a = arguments;
                },
                destroy: function () {
                    h = arguments;
                },
            }),
                (o.__onWebMessengerHostReady__ = function (e) {
                    if ((delete o.__onWebMessengerHostReady__, (o[s] = e), i))
                        for (var t = e.init.apply(e, i), n = 0; n < d.length; n++) {
                            var r = d[n];
                            t = "t" === r.type ? t.then(r.next) : t.catch(r.next);
                        }
                    a && e.render.apply(e, a), h && e.destroy.apply(e, h);
                    for (n = 0; n < u.length; n++) e.on.apply(e, u[n]);
                });
            var n = new XMLHttpRequest();
            n.addEventListener("load", t), n.open("GET", "https://" + e + ".webloader.smooch.io/", !0), (n.responseType = "json"), n.send();
        })(window, document, "Smooch", integrationId, "5");
        setTimeout(() => {
            Smooch.init({
                integrationId: integrationId,
                menuItems: {},
                prechatCapture: {
                    enabled: true,
                    enableEmailLinking: true,
                    fields: [
                        {
                            type: 'email',
                            name: 'email',
                            label: 'Email',
                            placeholder: 'your@email.com'
                        },
                        {
                            type: 'text',
                            name: 'phone',
                            label: 'Phone',
                            placeholder: '555-555-5555'
                        },
                        {
                            type: 'text',
                            name: 'first_name',
                            label: 'First Name',
                            placeholder: 'first name'
                        },
                        {
                            type: 'text',
                            name: 'last_name',
                            label: 'Last Name',
                            placeholder: 'last name'
                        }
                    ]
                },
                customText: {
                   prechatCaptureGreetingText:
        "To start off, we'd like to know a little bit about you. \\n By providing your email and mobile number via this chat, you consent to our  Terms of Use and Privacy Policy (found in the footer of this site) and to us sending emails and text messages or placing voice calls to you for advertising and marketing purposes.  Giving this consent is not required as a condition of purchasing any goods or services."
            },
            }).then(
                function () {
                    console.log("Script Ran for Sunshine");
                },
                function (err) {
                    console.log("Script Error for Sunshine");
                }
            );
        }, 3000)
    }, 5000);
</script>`}
              />
            </WebChatCodeReferenceContainer>
            <Button
              data-testid="goback-button"
              variant="primary"
              onClick={ReturnToThryv}
            >
              Return to Thryv
            </Button>
          </Section>
        </WebChatOptionsContainer>
      </WebChatNextContainer>
    </div>
  )
}
