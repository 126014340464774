import { useEffect, useState } from 'react'

import { Button, Header } from '@thryvlabs/maverick'
import { useNavigate, useLocation } from 'react-router-dom'
import Select from 'react-select'
import { toast, ToastContainer } from 'react-toastify'

import { Container } from '../../components/Grid'
import NavLayout from '../../components/nav-layout/nav-layout'
import { XeroJumbotron } from '../../components/ui/xero-style'
import {
  AccountExistsCard,
  SelectionDetails,
  SuccessCard
} from '../../components/xero/xero-components'
import { authClient } from '../../util/auth-client'

const xeroServiceURL = '/api/thryv-user/xero'

const customStyles = {
  container: provided => ({
    ...provided,
    display: 'inline-flex',
    border: '0 !important',
    boxShadow: 'none !important',
    outline: '0',
    backgroundColor: 'white',
    color: 'black',
    fontSize: 14,
    width: '300'
  })
}

const XeroAccountCreate = () => {
  const [invoiceAccountsOptions, setInvoiceAccountsOptions] = useState([])
  const [paymentAccountsOptions, setPaymentAccountsOptions] = useState([])
  const [accountMessages, setAccountMessages] = useState({
    invoiceSuccess: false,
    invoiceExists: false,
    paymentSuccess: false,
    paymentExists: false
  })
  const [installEnable, setInstallEnable] = useState(false)
  const [selectedInvoiceAccount, setSelectedInvoiceAccount] = useState(null)
  const [selectedPaymentAccount, setSelectedPaymentAccount] = useState(null)
  const { state } = useLocation()
  const navigate = useNavigate()
  const axiosClient = authClient()

  const {
    payload: {
      tenantId,
      currency,
      xeroRefreshToken,
      thryvId,
      checkedHistoricalClients,
      checkedHistoricalClientsFromThryv,
      checkedHistoricalInvoicePayment,
      checkedHistoricalInvoicesPaymentsThryv,
      checkedOngoingClients,
      checkedOngoingClientsThryv,
      checkedOngoingInvoicesPayments,
      checkedOngoingInvoicesPaymentsThryv,
      checkedAddressValue,
      syncFromDateThryv,
      syncFromDateXero
    } = {}
  } = state || {}

  const handleAccountCreationMessages = key => {
    setAccountMessages(prevSettings => ({
      ...prevSettings,
      [key]: !prevSettings[key]
    }))
  }

  const handleChangeInvoices = ({ label, value, accountID }) => {
    setSelectedInvoiceAccount({ label, value, accountID })
  }

  const handleChangePayments = ({ label, value, accountID }) => {
    setSelectedPaymentAccount({ label, value, accountID })
  }

  const installXero = async () => {
    const payload = {
      historicalClientsValue: checkedHistoricalClients,
      historicalClientsFromThryvValue: checkedHistoricalClientsFromThryv,
      historicalInvoicePaymentValue: checkedHistoricalInvoicePayment,
      historicalInvoicesPaymentsThryvValue:
        checkedHistoricalInvoicesPaymentsThryv,
      ongoingClientsValue: checkedOngoingClients,
      ongoingClientsThryvValue: checkedOngoingClientsThryv,
      ongoingInvoicesPaymentsValue: checkedOngoingInvoicesPayments,
      ongoingInvoicesPaymentsThryvValue: checkedOngoingInvoicesPaymentsThryv,
      address: checkedAddressValue,
      invoiceAccountId: selectedInvoiceAccount.accountID,
      paymentAccountId: selectedPaymentAccount.accountID,
      syncFromDateThryv,
      syncFromDateXero,
      tenantId,
      currency,
      xeroRefreshToken,
      thryvId
    }

    try {
      await axiosClient({
        method: 'post',
        url: `${xeroServiceURL}/install`,
        data: payload
      })
      navigate('/xero-success')
    } catch (err) {
      toast.error(
        'Something went wrong while installing Xero. If the problem persists please contact Thryv support.'
      )
    }
  }

  const createXeroAccount = async accountName => {
    const payload = {
      thryvId,
      tenantId,
      xeroRefreshToken,
      accountName
    }

    try {
      const {
        data: { accountId, alreadyExists }
      } = await axiosClient({
        method: 'post',
        url: `${xeroServiceURL}/create-account`,
        data: payload
      })

      const isInvoice = accountName === 'Thryv Invoices'
      const setAccountsOptions = isInvoice
        ? setInvoiceAccountsOptions
        : setPaymentAccountsOptions
      const handleAcctChange = isInvoice
        ? handleChangeInvoices
        : handleChangePayments

      const newAccount = {
        label: accountName,
        value: isInvoice ? 'Thryv1' : 'Thryv2',
        accountID: accountId
      }

      setAccountsOptions(prevAccounts => [...prevAccounts, newAccount])
      handleAcctChange(newAccount)

      if (alreadyExists) {
        handleAccountCreationMessages(
          accountName === 'Thryv Invoices' ? 'invoiceExists' : 'paymentExists'
        )
      }
      if (!alreadyExists) {
        handleAccountCreationMessages(
          accountName === 'Thryv Invoices' ? 'invoiceSuccess' : 'paymentSuccess'
        )
      }
    } catch (err) {
      toast.error(
        'Something went wrong while creating a Xero account. If the problem persists please contact Thryv support.'
      )
    }
  }

  useEffect(() => {
    async function getInvoicePaymentAccounts() {
      try {
        const { data } = await axiosClient(
          `${xeroServiceURL}/invoice-payment/${tenantId}/${xeroRefreshToken}/${thryvId}`
        )

        const paymentAccountsOptions = data.paymentAccounts.map(
          paymentAccount => ({
            label: paymentAccount.Name,
            value: paymentAccount.Code,
            accountID: paymentAccount.AccountID
          })
        )

        const invoiceAccountsOptions = data.invoiceAccounts.map(
          invoiceAccount => ({
            label: invoiceAccount.Name,
            value: invoiceAccount.Code,
            accountID: invoiceAccount.AccountID
          })
        )

        setPaymentAccountsOptions(paymentAccountsOptions)
        setInvoiceAccountsOptions(invoiceAccountsOptions)
      } catch (err) {
        toast.error(
          'Something went wrong while getting payment and invoice accounts. If the problem persists please contact Thryv support.'
        )
      }
    }
    getInvoicePaymentAccounts()
  }, [tenantId, xeroRefreshToken, thryvId])

  useEffect(() => {
    if (selectedInvoiceAccount && selectedPaymentAccount) {
      setInstallEnable(true)
    }
  }, [selectedInvoiceAccount, selectedPaymentAccount])

  return (
    <>
      <NavLayout title="Xero" />
      <Container fluid>
        <ToastContainer />
        <XeroJumbotron>
          <div className="mt-5 center mx-auto mb-5">
            <Header fontWeight="bold" variant="h6">
              Please select the sales and payment accounts Thryv will use for
              posting transactions. Please contact your accountant if you need
              assisstance.
            </Header>
          </div>
          <div className="card w-75 mt-2 center mx-auto">
            <Header fontWeight="bold" variant="h6" className="mt-5">
              Invoices
            </Header>
            <div className="row mt-5 text-left">
              <SelectionDetails
                optionName="Invoices"
                createXeroAccount={createXeroAccount}
              />
              <div className="col-2">
                <Select
                  options={invoiceAccountsOptions}
                  onChange={handleChangeInvoices}
                  getOptionLabel={option => `Account Name : ${option.label}`}
                  styles={customStyles}
                  placeholder="Select Invoice Account"
                />
              </div>
              <div className="col-9 mt-5 mb-4">
                {accountMessages.invoiceSuccess && (
                  <SuccessCard option="Invoices" />
                )}
                {accountMessages.invoiceExists && (
                  <AccountExistsCard option="Invoices" />
                )}
              </div>
            </div>
          </div>
          <div className="card w-75 mt-5 center mx-auto">
            <Header fontWeight="bold" variant="h6" className="mt-5">
              Payments
            </Header>
            <div className="row mt-5 text-left">
              <SelectionDetails
                optionName="Payments"
                createXeroAccount={createXeroAccount}
              />
              <div className="col-2">
                <Select
                  options={paymentAccountsOptions}
                  onChange={handleChangePayments}
                  getOptionLabel={option => `Account Name : ${option.label}`}
                  styles={customStyles}
                  placeholder="Select Payment Account"
                />
              </div>
              <div className="col-9 mt-5 mb-4">
                {accountMessages.paymentSuccess && (
                  <SuccessCard option="Payments" />
                )}
                {accountMessages.paymentExists && (
                  <AccountExistsCard option="Payments" />
                )}
              </div>
            </div>
          </div>
          <div className="button-align-xero-final-page">
            <Button variant="primary" onClick={() => navigate(-1)}>
              Back
            </Button>
            <Button
              variant="primary"
              onClick={installXero}
              disabled={!installEnable}
            >
              Install
            </Button>
          </div>
        </XeroJumbotron>
      </Container>
    </>
  )
}

export default XeroAccountCreate
