import { Button, Header } from '@thryvlabs/maverick'

import thryvGuyLogo from '../../assets/thryv_guy.png'
import { OptionsBar, UninstallContainer } from '../ui/instagram-style'

function UninstallApp({
  appInstalled,
  uninstallApp,
  navigateBackToThryv,
  appName
}) {
  return (
    <>
      <UninstallContainer>
        {appInstalled ? (
          <>
            <Header variant="h4" fontWeight="semibold">
              The application has already been installed. Would you like to
              uninstall?
            </Header>
            <OptionsBar>
              <div>
                <Button
                  variant="primary"
                  onClick={uninstallApp}
                  className="action-button"
                >
                  Uninstall
                </Button>
                <Button
                  variant="secondary"
                  onClick={navigateBackToThryv}
                  className="action-button"
                >
                  Go Back to Thryv
                </Button>
              </div>
            </OptionsBar>
          </>
        ) : (
          <>
            <img
              className="uninstall-thryv-guy"
              src={thryvGuyLogo}
              alt="Thryv Guy mascot"
            />
            <Header variant="h5" fontWeight="bold">
              You have uninstalled {appName} from Thryv.
            </Header>
            <Header
              variant="h5"
              fontWeight="semibold"
              className="uninstall-msg"
            >
              It is safe to close this tab at any time.
            </Header>
          </>
        )}
      </UninstallContainer>
    </>
  )
}
export default UninstallApp
