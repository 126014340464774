import styled from '@emotion/styled'

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .thryv-logo {
    margin-right: 15px;
    height: 75px;
    width: 75px;
    margin-top: -20px;
    border-radius: '10%';
  }
  margin-bottom: 20px;
`
export const HeaderContainer = styled.div`
  clear: both;
  padding-top: 120px;
  text-align: center;
  background: #f8f9fb;
  margin-bottom: 20px;
`
export const CardContainer = styled.div`
  margin-bottom: 20px;
  .header {
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 1rem;
    background: #f8f9fb;
  }
  .card {
    border-radius: 0.5rem;
  }
  .statements {
    display: flex;
    margin-left: 1.5em;
    padding: 5px;
    margin-top: 5px;
  }
  .state-text {
    font-size: 22px !important;
    margin-left: 8px;
    padding: 25px;
  }
  .icon {
    margin-top: 25px;
    min-width: 20px;
  }
`
export const ContextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
  margin-top: 20px;
`
export const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom: 100px;
  padding: 15px;
  .terms-text {
    text-align: justify;
  }
  .submit-button {
    margin-top: 15px;
    height: 50px;
    font-size: 20px;
  }
  .icon-style {
    color: red;
    font-size: 25px;
    margin-right: 1rem;
  }
  .warning-text {
    margin-bottom: -50px;
  }
`

export const CheckBoxContainer = styled.div`
  display: flex;
  a {
    color: #666;
    margin-left: 5px;
    margin-top: -3px;
    text-decoration: underline;
  }
`
export const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
`
export const AppLogo = styled.div`
  img {
    margin-left: ${props => props.appIconStyle.marginLeft};
    height: ${props => props.appIconStyle.height};
    width: ${props => props.appIconStyle.width};
    margin-top: ${props => props.appIconStyle.marginTop};
    border-radius: 10%;
  }
`
export const WrapperContainer = styled.div`
  width: 100%;
`
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
export const Col = styled.div`
  position: relative;
  width: 100%;
  padding-right: 10%;
  padding-left: 10%;
`
