import { useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import { Route, Routes } from 'react-router-dom'

import '../../css/App.css'
import EmptyPage from './EmptyPage'
import Clover from '../../pages/Clover/Clover'
import CloverError from '../../pages/Clover/CloverError'
import CloverRegister from '../../pages/Clover/CloverRegister'
import CloverRegistered from '../../pages/Clover/CloverRegistered'
import CloverSettings from '../../pages/Clover/CloverSettings'
import ConstantContact from '../../pages/ConstantContact/ConstantContact'
import ConstantContactSettings from '../../pages/ConstantContact/ConstantContactSettings'
import Copper from '../../pages/Copper/Copper'
import CopperSettings from '../../pages/Copper/CopperSettings'
import ErrorModal from '../../pages/ErrorModal'
import { Messenger } from '../../pages/FacebookMessenger/Messenger'
import MessengerNext from '../../pages/FacebookMessenger/MessengerNext'
import MessengerNextUninstall from '../../pages/FacebookMessenger/MessengerNextUninstall'
import { GoogleAnalytics } from '../../pages/GoogleAnalyticsv4/GoogleAnalytics'
import GBM from '../../pages/GoogleBusinessMessages/GBM'
import GBMInstall from '../../pages/GoogleBusinessMessages/GBMInstall'
import GBMNoSelection from '../../pages/GoogleBusinessMessages/GBMNoSelection'
import GBMSelection from '../../pages/GoogleBusinessMessages/GBMSelection'
import GBMUninstall from '../../pages/GoogleBusinessMessages/GBMUninstall'
import GBMError from '../../pages/GoogleBusinessMessages/GMBError'
import GoogleContacts from '../../pages/GoogleContacts/GoogleContacts'
import GoogleContactsSettings from '../../pages/GoogleContacts/GoogleContactsSettings'
import GoogleSheets from '../../pages/GoogleSheets/GoogleSheets'
import GoogleSheetsSettings from '../../pages/GoogleSheets/GoogleSheetsSettings'
import HubSpot from '../../pages/HubSpot/HubSpot'
import HubSpotSettings from '../../pages/HubSpot/HubSpotSettings'
import Insightly from '../../pages/Insightly/Insightly'
import InsightlySettings from '../../pages/Insightly/InsightlySettings'
import { Instagram } from '../../pages/InstagramMessenger/Instagram'
import InstagramNext from '../../pages/InstagramMessenger/InstagramNext'
import InstagramNextUninstall from '../../pages/InstagramMessenger/InstagramNextUninstall'
import Jotform from '../../pages/Jotform/Jotform'
import JotformSettings from '../../pages/Jotform/JotformSettings'
import Keap from '../../pages/Keap/Keap'
import KeapSettings from '../../pages/Keap/KeapSettings'
import Mailchimp from '../../pages/MailChimp/Mailchimp'
import MailchimpSettings from '../../pages/MailChimp/MailchimpSettings'
import MyObAccounting from '../../pages/MyObAccounting/MyObAccounting'
import MyObAccountingSettings from '../../pages/MyObAccounting/MyObAccountingSettings'
import QuickbooksOnline from '../../pages/QuickbooksOnline/QuickbooksOnline'
import QuickbooksOnlineSettings from '../../pages/QuickbooksOnline/QuickbooksOnlineSettings'
import RingCentral from '../../pages/RingCentral/RingCentral'
import SettingsSpamFilterContainer from '../../pages/RingCentral/SettingsSpamFilterContainer'
import Shopify from '../../pages/Shopify/Shopify'
import ShopifySettings from '../../pages/Shopify/ShopifySettings'
import Signatures from '../../pages/Signatures/Signatures'
import Twilio from '../../pages/Twilio/Twilio'
import TwilioNext from '../../pages/Twilio/TwilioNext'
import Typeform from '../../pages/Typeform/Typeform'
import TypeformSettings from '../../pages/Typeform/TypeformSettings'
import WebChat from '../../pages/WebChat/WebChat'
import WebChatNext from '../../pages/WebChat/WebChatNext'
import { Xero } from '../../pages/Xero/Xero'
import XeroAccountCreate from '../../pages/Xero/XeroAccountCreate'
import XeroSettings from '../../pages/Xero/XeroSettings'
import XeroSettingsOptions from '../../pages/Xero/XeroSettingsOptions'
import XeroSuccess from '../../pages/Xero/XeroSuccess'
import Yext from '../../pages/Yext/Yext'

function FullPageErrorFallback({ error }) {
  return (
    <div
      role="alert"
      style={{
        color: '#ef5350',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <p>Uh oh... There&apos;s a problem. Try refreshing the app.</p>
      <pre>{error.message}</pre>
    </div>
  )
}

const { REACT_APP_LOGROCKET_APP_ID } = process.env

const App = () => {
  const { user } = useAuth0()
  const thryv_id = user?.businessId

  //Logrocket for monitoring users sessions for error logs
  useEffect(() => {
    LogRocket.init(`${REACT_APP_LOGROCKET_APP_ID}`)
    setupLogRocketReact(
      LogRocket.identify('Thryv Id', {
        name: thryv_id,
        email: thryv_id
      })
    )
  }, [thryv_id])

  return (
    <Sentry.ErrorBoundary fallback={FullPageErrorFallback}>
      <Routes>
        <Route exact path="/yext" element={<Yext />} />
        <Route exact path="/twilio" element={<Twilio />} />
        <Route exact path="/twilio-settings" element={<TwilioNext />} />
        <Route exact path="/myob-accounting" element={<MyObAccounting />} />
        <Route
          exact
          path="/myob-accounting-settings"
          element={<MyObAccountingSettings />}
        />
        <Route exact path="/xero" element={<Xero />} />
        <Route
          exact
          path="/xero-settings-options"
          element={<XeroSettingsOptions />}
        />
        <Route exact path="/xero-settings" element={<XeroSettings />} />
        <Route
          exact
          path="/xero-create-account"
          element={<XeroAccountCreate />}
        />
        <Route exact path="/xero-success" element={<XeroSuccess />} />
        <Route exact path="/ga4" element={<GoogleAnalytics />} />
        <Route exact path="/messenger" element={<Messenger />} />
        <Route exact path="/messenger-next" element={<MessengerNext />} />
        <Route
          exact
          path="/messenger-next-uninstall"
          element={<MessengerNextUninstall />}
        />
        <Route exact path="/webMessenger" element={<WebChat />} />
        <Route exact path="/webMessengerNext" element={<WebChatNext />} />
        <Route exact path="/instagram" element={<Instagram />} />
        <Route exact path="/instagram-next" element={<InstagramNext />} />
        <Route
          exact
          path="/instagram-next-uninstall"
          element={<InstagramNextUninstall />}
        />
        <Route exact path="/mailchimp" element={<Mailchimp />} />
        <Route
          exact
          path="/mailchimp-settings"
          element={<MailchimpSettings />}
        />
        <Route exact path="/hubspot" element={<HubSpot />} />
        <Route exact path="/hubspot-settings" element={<HubSpotSettings />} />
        <Route exact path="/insightly" element={<Insightly />} />
        <Route
          exact
          path="/insightly-settings"
          element={<InsightlySettings />}
        />
        <Route exact path="/keap" element={<Keap />} />
        <Route exact path="/keap-settings" element={<KeapSettings />} />
        <Route exact path="/jotform" element={<Jotform />} />
        <Route exact path="/jotform-settings" element={<JotformSettings />} />
        <Route exact path="/copper" element={<Copper />} />
        <Route exact path="/copper-settings" element={<CopperSettings />} />
        <Route exact path="/constant-contact" element={<ConstantContact />} />
        <Route exact path="/google-contacts" element={<GoogleContacts />} />
        <Route
          exact
          path="/google-contacts-settings"
          element={<GoogleContactsSettings />}
        />
        <Route
          exact
          path="/constant-contact-settings"
          element={<ConstantContactSettings />}
        />
        <Route exact path="/error-modal" element={<ErrorModal />} />
        <Route exact path="/google" element={<GBM />} />
        <Route exact path="/gbm-next" element={<GBMInstall />} />
        <Route exact path="/gbm-selection" element={<GBMSelection />} />
        <Route exact path="/gbm-uninstall" element={<GBMUninstall />} />
        <Route exact path="/gbm-no-selection" element={<GBMNoSelection />} />
        <Route exact path="/gbm-error" element={<GBMError />} />
        <Route exact path="/shopify" element={<Shopify />} />
        <Route exact path="/shopify-settings" element={<ShopifySettings />} />
        <Route exact path="/signatures" element={<Signatures />} />
        <Route exact path="/ringcentral" element={<RingCentral />} />
        <Route
          exact
          path="/ringcentral-settings"
          element={<SettingsSpamFilterContainer />}
        />
        <Route exact path="/quickbooks-online" element={<QuickbooksOnline />} />
        <Route
          exact
          path="/quickbooks-online-settings"
          element={<QuickbooksOnlineSettings />}
        />
        <Route exact path="/clover" element={<Clover />} />
        <Route exact path="/clover-register" element={<CloverRegister />} />
        <Route exact path="/clover-registered" element={<CloverRegistered />} />
        <Route exact path="/clover-settings" element={<CloverSettings />} />
        <Route exact path="/clover-errors/:id" element={<CloverError />} />
        <Route exact path="/typeform" element={<Typeform />} />
        <Route exact path="/typeform-settings" element={<TypeformSettings />} />
        <Route exact path="/google-sheets" element={<GoogleSheets />} />
        <Route
          exact
          path="/google-sheets-settings"
          element={<GoogleSheetsSettings />}
        />
        <Route exact path="/" element={<EmptyPage />} />
        <Route exact path="*" element={<EmptyPage />} />
      </Routes>
    </Sentry.ErrorBoundary>
  )
}

export default App
