import { useNavigate } from 'react-router-dom'

import keapLogo from '../../assets/keapLogo.png'
import HomePageLayout from '../../components/home-page-layouts/home-page-layout'

import '../../css/App.css'

const Keap = () => {
  const appName = 'Keap'
  const navigate = useNavigate()
  const handleClick = e => {
    e.preventDefault()
    navigate('/keap-settings')
  }
  const keapSections = [
    {
      header: 'What will this application need access to?',
      statements: ['Customers', 'Invoices', 'Payments']
    },

    {
      header: 'What will this application do?',
      statements: [
        'Sync current Customers between Thryv and Keap.',
        'Sync future Customers between Thryv and Keap.',
        'Sync current Invoices between Thryv and Keap.',
        'Sync future Invoices between Thryv and Keap.',
        'Sync current Payments between Thryv and Keap.',
        'Sync future Payments between Thryv and Keap.'
      ]
    }
  ]

  const appIconStyle = {
    height: '80px',
    width: '',
    marginTop: '-6px',
    marginLeft: '15px'
  }

  return (
    <HomePageLayout
      sections={keapSections}
      onSubmit={handleClick}
      appName={appName}
      appIconSource={keapLogo}
      appIconStyle={appIconStyle}
    />
  )
}

export default Keap