import { useAuth0 } from '@auth0/auth0-react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import yextLogo from '../../assets/YEXT_BIG.png'
import HomePageLayout from '../../components/home-page-layouts/home-page-layout'
import '../../css/App.css'
import { appIconStyle } from '../../components/ui/app-icon-style'
import { authClient } from '../../util/auth-client'

const Yext = () => {
  const { user } = useAuth0()
  const axiosClient = authClient()

  const handleClick = async e => {
    e.preventDefault()

    try {
      const {
        data: { redirectUrl }
      } = await axiosClient({
        method: 'post',
        url: `/api/yext/connect`,
        data: { thryvId: user.businessId }
      })
      window.location.href = redirectUrl
    } catch (error) {
      toast.error(
        `An error occurred while connecting Yext. Please contact Thryv Support if the issue persists.`
      )
    }
  }

  return (
    <>
      <HomePageLayout
        onSubmit={handleClick}
        appName="Yext"
        appIconSource={yextLogo}
        appIconStyle={appIconStyle}
      />
      <ToastContainer />
    </>
  )
}

export default Yext
