import { useCallback, useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import Loader from 'react-loader-spinner'

const UnauthenticatedApp = () => {
  const { loginWithRedirect } = useAuth0()

  const login = useCallback(async () => {
    const { search, pathname } = window.location
    const returnTo = `${pathname}${search}`

    await loginWithRedirect({ appState: { returnTo } })
  }, [loginWithRedirect])

  useEffect(() => {
    login()
  }, [login])

  return (
    <div data-testid="loader" className="centerSpinner">
      <Loader
        className="loading-dots"
        type="ThreeDots"
        color="#FF5000"
        height={80}
        width={80}
      />
    </div>
  )
}

export default UnauthenticatedApp
