import { useState } from 'react'

import {
  Avatar,
  Button,
  Header,
  Icon,
  PaginatedList,
  Radio,
  Table,
  TableHeader,
  TableInfo,
  TableMenu,
  TableRow
} from '@thryvlabs/maverick'

import {
  FieldContainer,
  HeaderContainer,
  MediumContainer,
  MenuContainer,
  MessageScrollBox,
  TableContainer,
  TextBox
} from '../ui/twilio-style'

export default function TwilioSpamFilterTable({
  getUserQueue,
  userQueue,
  handleCallAction,
  handleFieldSelection
}) {
  const [currentDate] = useState(new Date())

  const tableDescription = `
    The spam filter will queue incoming calls and SMS 
    from contacts that do not already exist in Thryv.
  `

  const tableColumns = [
    { fieldName: 'Name', widthPercent: 19 },
    { fieldName: 'Email', widthPercent: 20 },
    { fieldName: 'Phone Number', widthPercent: 12 },
    { fieldName: 'Type', widthPercent: 8 },
    { fieldName: 'Date', widthPercent: 12 },
    { fieldName: 'Message', widthPercent: 19 },
    { fieldName: 'Action', widthPercent: 10 }
  ]

  return (
    <TableContainer>
      <MediumContainer>
        <Table>
          <TableHeader title="Spam Filter" description={tableDescription}>
            <Button variant="primary" onClick={getUserQueue}>
              Refresh
            </Button>
          </TableHeader>
          <TableMenu>
            <MenuContainer>
              {tableColumns.map(field => (
                <HeaderContainer
                  key={field.fieldName}
                  widthPercent={field.widthPercent}
                >
                  <Header
                    variant="h6"
                    fontWeight="semibold"
                    className="table-header"
                  >
                    {field.fieldName}
                  </Header>
                </HeaderContainer>
              ))}
            </MenuContainer>
          </TableMenu>
          <TableInfo
            date={currentDate}
            data={userQueue}
            label={userQueue?.length > 1 ? 'Calls' : 'Call'}
          />
          <PaginatedList list={userQueue} itemsPerPage={10}>
            <TwilioTableRow
              handleCallAction={handleCallAction}
              handleFieldSelection={handleFieldSelection}
            />
          </PaginatedList>
        </Table>
      </MediumContainer>
    </TableContainer>
  )
}

function TwilioTableRow({
  callsid,
  thryvid,
  email,
  first_name,
  last_name,
  caller,
  type,
  timestamp,
  message,
  handleFieldSelection,
  handleCallAction
}) {
  const [action, setAction] = useState()
  const emptyField = 'N/A'

  const handleRadioButton = event => {
    const eventAction = event.target.value
    const call = {
      thryv_id: thryvid,
      callSid: callsid,
      [eventAction]: true
    }
    setAction(previous => {
      return previous !== eventAction ? eventAction : undefined
    })
    handleCallAction(call)
  }

  return (
    <TableRow variant="static" compact>
      <FieldContainer width="19%">
        {
          <Avatar
            variant="name"
            size="small"
            name={{
              firstName: first_name,
              lastName: last_name
            }}
          />
        }
        {first_name && <TextBox>{first_name}</TextBox>}
        {last_name && <TextBox>{last_name}</TextBox>}
        <Button
          variant="text"
          level={1}
          className="btn"
          onClick={() =>
            handleFieldSelection({ first_name, last_name, callsid })
          }
        >
          <Icon type="solid" variant="edit" width="20px" />
        </Button>
      </FieldContainer>
      <FieldContainer width="20%">
        <TextBox>{email || emptyField}</TextBox>
        <Button
          variant="text"
          level={1}
          className="btn"
          onClick={() => handleFieldSelection({ email, callsid })}
        >
          <Icon type="solid" variant="edit" width="20px" />
        </Button>
      </FieldContainer>
      <FieldContainer width="12%">
        <TextBox>{caller || emptyField}</TextBox>
      </FieldContainer>
      <FieldContainer width="8%">
        <TextBox>{type || emptyField}</TextBox>
      </FieldContainer>
      <FieldContainer width="12%">
        <TextBox>
          {(!!timestamp && new Date(timestamp).toLocaleString()) || emptyField}
        </TextBox>
      </FieldContainer>
      <FieldContainer className="message-text-box" width="19%">
        {message ? (
          <MessageScrollBox>
            <TextBox>{message}</TextBox>
          </MessageScrollBox>
        ) : (
          <TextBox>{emptyField}</TextBox>
        )}
      </FieldContainer>
      <FieldContainer width="10%">
        <div className="flex flex-column">
          <Radio
            label="Release"
            value="release"
            hasLabel
            handleOnChange={handleRadioButton}
            toggled={action === 'release'}
          />
          <Radio
            label="Delete"
            value="delete"
            hasLabel
            handleOnChange={handleRadioButton}
            toggled={action === 'delete'}
          />
        </div>
      </FieldContainer>
    </TableRow>
  )
}
