import styled from '@emotion/styled'

export const WebChatHeaderMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  text-align: center;
  background-color: #f8f9fb;
`
export const WebChatMainContainer = styled.div`
  display: flex;
  flex-direction: column;

  .chat-settings-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .chat-container {
      .chat-message {
        display: flex;
        flex-direction: row;
        padding: 1rem;
        .chat-button {
          background-color: ${props => props.buttonColor};
          border: none;
          color: white;
          border-radius: 50px;
        }
        .business-message {
          background-color: ${props => props.textColor};
        }
        .client-message {
          background-color: rgba(0, 0, 0, 0.03);
        }
      }
      padding: 20px;
      .chat-inner-container {
        border: none;
      }
      .chat-input {
        border-radius: 100px;
        height: 40px;
      }
    }

    .color-settings-container {
      width: 50%;
      max-width: 700px;
      padding: 20px;
    }
  }
  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    .buttons-container {
      display: flex;
      gap: 20px;
    }
    .install-button {
      width: 150px;
    }
  }
`
export const PopoverWrapper = styled.div`
  position: absolute;
  z-index: 2;
`
export const ColorPickerContainer = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`

export const ChatHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  color: white;
  border-radius: 25px 25px 0px 0px;
  background-color: ${props => props.backgroundColor};
`
export const SectionButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  .reference-color-box {
    height: 30px;
    width: 30px;
    background-color: ${props => props.boxColor};
  }
`

export const WebChatNextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 20px;
`

export const WebChatOptionsContainer = styled.div`
  width: 70%;
  display: flex;
  gap: 30px;
`

export const WebChatCodeReferenceContainer = styled.div`
  margin: 1.5em 0 3em;
  padding: 20px;
  max-width: 100%;
  background: #f8f9fb;
`
