import styled from '@emotion/styled'

export const XeroJumbotron = styled.div`
  clear: both;
  padding-top: 90px;
  text-align: center;
  margin-bottom: 20px;

  & .button {
    width: 200px;
    height: 50px;
    margin-top: 20px;
  }
  & .content-box {
    width: 90%;
  }
  & .account-exists-header {
    color: #ff5000;
  }
`
export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  align-items: center;
  padding: 30px;
  .address-text {
    max-width: 500px;
    text-align: justify;
  }
`
export const RowContainer = styled.div`
  width: 80%;
  margin: auto;
`
export const PostInstallationContainer = styled.div`
  margin: auto;
  max-width: 50%;

  .centered-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text-container {
    width: 50%;
  }
`