import { useAuth0 } from '@auth0/auth0-react'

import ringCentralLogo from '../../assets/RingCentralLogo.svg'
import HomePageLayout from '../../components/home-page-layouts/home-page-layout'
import { authClient } from '../../util/auth-client'

function RingCentral() {
  const {
    user: { businessId: thryvId }
  } = useAuth0()
  const axiosClient = authClient()

  const {
    REACT_APP_BACKEND_URL,
    REACT_APP_RING_CENTRAL_OAUTH_URL,
    REACT_APP_RING_CENTRAL_CLIENT_ID
  } = process.env
  const ringcentralServiceURL = `${REACT_APP_BACKEND_URL}/api/ring-central`

  const handleClick = async e => {
    e.preventDefault()

    try {
      await axiosClient({
        url: `${ringcentralServiceURL}/user`,
        method: 'POST',
        data: { thryvId }
      })
      window.location.href = `${REACT_APP_RING_CENTRAL_OAUTH_URL}?response_type=code&redirect_uri=${ringcentralServiceURL}/oauth/&client_id=${REACT_APP_RING_CENTRAL_CLIENT_ID}&state=${thryvId}`
    } catch (err) {
      console.log(err.message)
    }
  }

  const ringCentralSections = [
    {
      header: 'What will this application do?',
      statements: [
        'Import all existing contacts from RingCentral to Thryv in just a few clicks.',
        'Subscribe to receiving New Voicemail notifications to your Thryv inbox.',
        'Subscribe to receiving incoming call notifications to your Thryv inbox.'
      ]
    }
  ]
  const appIconStyle = {
    marginLeft: '15px',
    height: '80px',
    width: '80px',
    marginTop: '-20px'
  }

  const ringCentralWarning =
    'For the integration to work at its best, we advise a user with Admin permission in the RingCentral account to install the Thryv RingCentral app in Thryv. This is to make sure that all the functions of the app, such as notifications and importing contacts, are enabled.'

  return (
    <HomePageLayout
      warning={ringCentralWarning}
      sections={ringCentralSections}
      onSubmit={handleClick}
      appName="RingCentral"
      appIconSource={ringCentralLogo}
      appIconStyle={appIconStyle}
    />
  )
}

export default RingCentral
