import { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Button, Header } from '@thryvlabs/maverick'
import { ToastContainer, toast } from 'react-toastify'

import { AppsModal } from '../../components/Modal'
import NavLayout from '../../components/nav-layout/nav-layout'
import {
  GBMSelectionContainer,
  MainContainer
} from '../../components/ui/gbm-style'
import { authClient } from '../../util/auth-client'

const GBMUninstall = () => {
  const [modalShow, setModalShow] = useState(false)
  const { user } = useAuth0()
  const axiosClient = authClient()

  const uninstallApp = async () => {
    try {
      await axiosClient({
        method: 'patch',
        url: `/api/thryv-user/gbm/uninstall/${user.businessId}`
      })
      setModalShow(true)
    } catch (err) {
      toast.error(
        'An error occurred while uninstalling Google Business Messages. Please contact Thryv Support if the issue persists.'
      )
    }
  }

  const RedirectToAppMarket = () => {
    window.location.href = 'https://go.thryv.com/app/app-market'
  }

  return (
    <>
      <ToastContainer />
      <NavLayout title={`Google Business Messages`} showButton={false} />
      <MainContainer>
        <GBMSelectionContainer>
          <Header fontWeight="medium" variant="h2">
            <span data-testid="gbm-selection-title-header">
              The application has already been installed. Would you like to
              uninstall ?
            </span>
          </Header>

          <div className="gbm-selection-button-container">
            <Button
              variant="primary"
              className="gbm-selection medium-button"
              onClick={uninstallApp}
              data-testid="uninstall-button"
            >
              Uninstall
            </Button>

            <Button
              variant="primary"
              className="gbm-selection medium-button"
              onClick={RedirectToAppMarket}
              data-testid="redirect-button"
            >
              Go Back to Thryv
            </Button>
          </div>

          {
            <AppsModal
              show={modalShow}
              appName="Google Business Messages"
              appAction="Uninstalled"
              onHide={() => {
                setModalShow(false)
                window.location.href = 'https://go.thryv.com/app'
              }}
            />
          }
        </GBMSelectionContainer>
      </MainContainer>
    </>
  )
}

export default GBMUninstall
