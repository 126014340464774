import { Component } from 'react'

import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Col, Container, Row } from '../../components/Grid'
import { Jumbotron } from '../../components/Jumbotron'
import { OAuth } from '../../redux/actions/OAuth'

class MyObAccounting extends Component {
  constructor(props) {
    const navigate = useNavigate()
    super(props)
    this.state = {
      disabled: true,
      code: '', // code get's set by getAuthCode function
      isOpen: true
    }

    this.oAuthData = {
      client_id: process.env.REACT_APP_OBACCOUNTING_CLIENT_ID,
      client_secret: process.env.REACT_APP_OBACCOUNTING_CLIENT_SECRET,
      redirect_uri: process.env.REACT_APP_OBACCOUNTING_REDIRECT_URI,
      grant_type: 'authorization_code',
      code: '' // code get's set by getAuthCode function
    }
  }

  async componentDidMount() {
    const setCode = await this.getAuthCode()

    if (setCode) {
      this.oAuthData.code = this.state.code
    }
  }

  toggleModal = () => {
    setTimeout(() => {
      this.setState({ isOpen: !this.state.isOpen })
    }, 200)
  }

  getAuthCode = () => {
    const storeAuthCode = []
    let authCode = window.location.search.substring(1)
    let code = authCode.split('&')
    // looping through array of auth code and state to isolate just the auth code
    for (
      let authCodeIteration = 0;
      authCodeIteration < code.length;
      authCodeIteration++
    ) {
      let pair = code[authCodeIteration].split('=')
      storeAuthCode.push(pair[1])
    }
    // reversing array because can't seem to be able to get specific index in the array
    if (storeAuthCode.length !== 0) {
      storeAuthCode.reverse()
    }
    for (
      let codeIteration = 0;
      codeIteration < storeAuthCode.length;
      codeIteration++
    ) {
      const gotAuthCode = storeAuthCode[codeIteration]
      // setting code value to auth code
      this.setState({
        code: gotAuthCode
      })
    }
    return storeAuthCode
  }

  handleChange = e => {
    if (e.target.checked) {
      this.setState({
        disabled: false,
        isActive: false
      })
    } else {
      this.setState({
        disabled: true,
        isActive: true
      })
    }
  }

  handleClick = e => {
    e.preventDefault()
    this.navigate('/myob-accounting-settings')
  }

  render() {
    return (
      <Container fluid>
        <Jumbotron fluid>
          <Container text-center>
            <img
              src="http://thryvapps.wpengine.com/media/thryvappSM.png"
              style={{
                marginRight: '10px',
                height: '80px',
                width: '80px',
                marginTop: '-20px',
                borderRadius: '10%'
              }}
            />

            <i className="fa fa-exchange fa-2x"></i>
            <img
              src="https://thryvapps.staging.wpengine.com/media/myob-logo.png"
              style={{
                marginLeft: '16px',
                height: '80px',
                width: '80px',
                marginTop: '-20px',
                borderRadius: '10%'
              }}
            />

            <p className="lead mt-5 step1">
              <strong>STEP 1:</strong> MYOB AccountRight is requesting
              permission to access your Thryv account.
            </p>
          </Container>
        </Jumbotron>
        <Row className="justify-content-center">
          <Col size="md-6">
            <div className="card">
              <h5 className="card-header txt17 p-4">
                What will this application do?
              </h5>
              <div className="card-body ">
                <p className="card-title pt-3" style={{ fontSize: 20 }}>
                  <span
                    className="fa fa-check-circle ml-4 mr-2"
                    style={{ color: 'green', fontSize: 20 }}
                  ></span>
                  Keep contacts, invoices and payments consolidated in one
                  place.
                </p>
              </div>
              <hr></hr>
              <div className="card-body ">
                <p className="card-title " style={{ fontSize: 20 }}>
                  <span
                    className="fa fa-check-circle ml-4 mr-2"
                    style={{ color: 'green', fontSize: 20 }}
                  ></span>
                  Streamline your bookkeeping and money management processes.
                </p>
              </div>
              <hr></hr>
              <div className="card-body">
                <p className="card-title pb-3" style={{ fontSize: 20 }}>
                  <span
                    className="fa fa-check-circle ml-4 mr-2"
                    style={{ color: 'green', fontSize: 20 }}
                  ></span>
                  Stay organized and view in real-time the status of your
                  revenue stream.
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col size="md-6">
            <div
              className="txt12"
              style={{ paddingBottom: 25, paddingTop: 15, fontSize: '12px' }}
            >
              Thryv® Marketplace is an aggregated offering of Applications that
              may be of interest or use to you. Applications not branded Thryv®
              are provided by third-party developers, licensors or distributors.
              When you link out from the Marketplace’s app details page to a
              third-party site, you agree that your business information will be
              passed to that provider’s site for the purpose of facilitating a
              transaction with that provider. Your purchase, download or use of
              third-party applications is governed by such third party’s terms
              and conditions and privacy policy, as applicable. Thryv, Inc.
              disclaims all liability for the functionality of any third-party
              applications and, unless otherwise expressly provided, for their
              interoperability with Thryv.
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col size="md-6" className="text-center">
            {this.state.isActive ? (
              <span style={{ color: 'red' }}>
                *Please accept our Terms & Conditions.
              </span>
            ) : null}
            <form style={{ paddingBottom: 15 }}>
              <input type="checkbox" onChange={this.handleChange} />{' '}
              <span className="txt12" style={{ fontSize: '12px' }}>
                By checking this box, client agrees to our{' '}
                <u>
                  <a
                    className="thryv-blue semibold"
                    style={{ color: '#666' }}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    href="https://corporate.thryv.com/thryv-terms/"
                  >
                    Terms &amp; Conditions
                  </a>
                </u>
                .
              </span>
            </form>
            <button
              className="orange-btn "
              disabled={this.state.disabled}
              onClick={this.handleClick}
            >
              Connect to MYOB Accounting
            </button>
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    oauth: state.oauth
  }
}
export default connect(mapStateToProps, { OAuth })(MyObAccounting)
