import { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import {
  Button,
  ParagraphText,
  Header,
  Table,
  TableMenu,
  TableContent,
  TableRow,
  Avatar
} from '@thryvlabs/maverick'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import { LoginSocialFacebook } from 'reactjs-social-login'

import { AppsModal, LoadingModal } from '../../components/Modal'
import NavLayout from '../../components/nav-layout/nav-layout'
import {
  InstagramJumbotron,
  LoginContainer,
  OptionsBar,
  ProfileContainer
} from '../../components/ui/instagram-style'
import { authClient } from '../../util/auth-client'

const { REACT_APP_FACEBOOK_ID, REACT_APP_THRYV_URL } = process.env

const AccountRow = ({ id, name, category }) => (
  <>
    <span className="text-btn-links font-semibold font-montserrat text-thryv-black-500">
      {id}
    </span>
    <span className="text-btn-links font-semibold font-montserrat text-thryv-black-500">
      {name}
    </span>
    <span className="text-btn-links font-semibold font-montserrat text-thryv-black-500">
      {category}
    </span>
  </>
)
function InstagramNext() {
  const [login, setLogin] = useState(false)
  const [facebookUserData, setFacebookUserData] = useState({})
  const [accountInfo, setAccountInfo] = useState([])
  const [picture, setPicture] = useState('')
  const [userDataReceived, setUserDataReceived] = useState(false)
  const [payloadAccessToken, setPayloadAccessToken] = useState()
  const [successModalShow, setSuccessModalShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useAuth0()
  const tableMenu = ['Page ID', 'Page Name', 'Category']
  const axiosClient = authClient()

  const facebookLoginScope =
    'email,' +
    'instagram_basic,' +
    'instagram_manage_messages,' +
    'pages_manage_metadata,' +
    'pages_show_list,' +
    'public_profile,' +
    'business_management'

  const messagingScope = facebookLoginScope + ',pages_messaging'

  const getPageAccessToken = async accessToken => {
    try {
      const response = await axios.get(
        `https://graph.facebook.com/me/accounts?access_token=${accessToken}`,
        {
          headers: {
            'Cache-Control': 'no-cache',
            'Access-Control-Allow-Origin': '*'
          }
        }
      )

      setAccountInfo(response.data.data)
      setUserDataReceived(true)
    } catch (error) {
      toast.error(
        'Failed to retrieve access token. Please contact Thryv Support if this issue persists.'
      )
    }
  }

  const installInstagramApp = async () => {
    setIsLoading(true)

    const pageAccessTokens = accountInfo.map(account => ({
      page_access_token: account.access_token,
      page_name: account.name
    }))

    const payload = {
      thryvId: user.businessId,
      payloadAccessToken,
      pageAccessTokens,
      instagramUserId: facebookUserData.userID
    }

    try {
      await axiosClient({
        method: 'post',
        url: '/api/thryv-user/instagram/install',
        data: payload
      })
      setSuccessModalShow(true)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast.error(
        'Failed to install Instagram Messenger integration. Please contact Thryv Support if this issue persists.'
      )
    }
  }

  const onLoginResolve = ({ data }) => {
    setFacebookUserData(data)
    getPageAccessToken(data.accessToken)
    setPayloadAccessToken(data.accessToken)
    setPicture(data.picture.data.url)
    setLogin(true)
  }

  const onLoginReject = () => {
    toast.error(
      'There was an error with the login process. Please contact Thryv Support if this issue persists.'
    )
  }

  return (
    <>
      <ToastContainer />
      <NavLayout title={'Instagram Messenger'} />
      <InstagramJumbotron>
        {login && (
          <ProfileContainer>
            <Avatar
              className={'ml-[10px]'}
              variant="image"
              imageUrl={picture}
              size="default"
            />
            <ParagraphText variant="lg">
              You&apos;re logged in as:{' '}
            </ParagraphText>
            <ParagraphText variant="lg" className="login-name">
              {facebookUserData.name}
            </ParagraphText>
          </ProfileContainer>
        )}
        {login ? (
          <div className="next-instructions">
            <Header variant="h3" fontWeight="normal">
              Below are the pages you have selected to connect with Thryv.
            </Header>
            <ParagraphText variant="lg" className="text-block">
              Don&apos;t see your business page? Click on &apos;Add Pages&apos;
              followed by &apos;Edit Settings&apos; to get started. Please note
              that, due to the connection between Facebook and Instagram, only
              the Facebook page that your Business Instagram is connected to
              will appear below.
            </ParagraphText>
            <ParagraphText variant="reg" className="text-block">
              ** For this integration to work you must ensure that your
              Instagram business page is linked to a Facebook business page.
            </ParagraphText>
          </div>
        ) : (
          <Header variant="h1" fontWeight="semibold" className="text-block">
            Please use the button below to login to your Facebook Business Page.
          </Header>
        )}
      </InstagramJumbotron>

      <div className="container justify-content-center">
        {userDataReceived ? (
          <Table>
            <TableMenu>
              <div className="flex items-center w-full">
                {tableMenu.map((header, i) => (
                  <h6
                    className="text-thryv-steel font-semibold font-montserrat text-[14px] capitalize flex-grow w-1/3"
                    key={i}
                  >
                    {header}
                  </h6>
                ))}
              </div>
            </TableMenu>
            {userDataReceived && (
              <TableContent>
                {accountInfo &&
                  accountInfo.map(info => (
                    <TableRow key={info.id} variant="static">
                      <AccountRow
                        id={info.id}
                        name={info.name}
                        category={info.category}
                      />
                      {login && (
                        <LoginSocialFacebook
                          appId={REACT_APP_FACEBOOK_ID}
                          fields="name,email,picture"
                          scope={facebookLoginScope}
                          onResolve={onLoginResolve}
                          onReject={onLoginReject}
                        >
                          <Button variant="text" level={1}>
                            Edit
                          </Button>
                        </LoginSocialFacebook>
                      )}
                    </TableRow>
                  ))}
              </TableContent>
            )}
          </Table>
        ) : (
          <LoginContainer>
            {!login && (
              <LoginSocialFacebook
                appId={REACT_APP_FACEBOOK_ID}
                fields="name,email,picture"
                scope={facebookLoginScope}
                onResolve={onLoginResolve}
                onReject={onLoginReject}
              >
                <Button variant="primary">Login with Facebook</Button>
              </LoginSocialFacebook>
            )}
          </LoginContainer>
        )}

        {userDataReceived && (
          <OptionsBar>
            <LoginSocialFacebook
              appId={REACT_APP_FACEBOOK_ID}
              fields="name,email,picture"
              scope={messagingScope}
              onResolve={onLoginResolve}
              onReject={onLoginReject}
            >
              <Button variant="secondary" className="action-button">
                Add Pages
              </Button>
            </LoginSocialFacebook>
            <Button
              variant="primary"
              onClick={installInstagramApp}
              className="action-button"
            >
              Install
            </Button>
          </OptionsBar>
        )}
        <AppsModal
          show={successModalShow}
          appAction="Installed"
          appName="Instagram"
          onHide={() => {
            setSuccessModalShow(false)
            window.location.href = `${REACT_APP_THRYV_URL}/app/app-market`
          }}
        />
        <LoadingModal
          show={isLoading}
          onHide={() => {
            setIsLoading(false)
          }}
        />
      </div>
    </>
  )
}

export default InstagramNext
