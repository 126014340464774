import { Header } from '@thryvlabs/maverick'

import { Col, Row } from '../../../components/Grid'
import { SwitchButton } from '../../../components/switch-button'
import {
  SettingsListItemContainer,
  WarningText
} from '../../../components/ui/ringcentral-style'

const SettingsItem = ({
  header,
  statement,
  warning,
  itemValue,
  onChangeItem
}) => {
  return (
    <>
      <Row>
        <Col size="2" className="text-center"></Col>
        <Col size="7" className="text-left">
          <span>
            <Header fontWeight="semibold" variant="h7">
              {header}
            </Header>
            <SettingsListItemContainer>
              <li>{statement}</li>
              {warning && (
                <li>
                  <WarningText>{warning}</WarningText>
                </li>
              )}
            </SettingsListItemContainer>
          </span>
        </Col>

        <Col size="3" className="text-left">
          <SwitchButton defaultValue={itemValue} changeFunc={onChangeItem} />
        </Col>
      </Row>
      <br></br>
    </>
  )
}

export default SettingsItem
