const timeZoneConverter = timeZone => {
  let convertedTimeZone

  switch (timeZone) {
    case 'America/Atikokan':
      convertedTimeZone = 'Eastern Time (US & Canada)'
      break
    case 'America/Blanc-Sablon':
      convertedTimeZone = 'Atlantic Time (Canada)'
      break
    case 'America/Cambridge_Bay':
      convertedTimeZone = 'Mountain Time (US & Canada)'
      break
    case 'America/Creston':
      convertedTimeZone = 'Mountain Time (US & Canada)'
      break
    case 'America/Dawson':
      convertedTimeZone = 'Mountain Time (US & Canada)'
      break
    case 'America/Dawson_Creek':
      convertedTimeZone = 'Mountain Time (US & Canada)'
      break
    case 'America/Edmonton':
      convertedTimeZone = 'Mountain Time (US & Canada)'
      break
    case 'America/Fort_Nelson':
      convertedTimeZone = 'Mountain Time (US & Canada)'
      break
    case 'America/Glace_Bay':
      convertedTimeZone = 'Atlantic Time (Canada)'
      break
    case 'America/Goose_Bay':
      convertedTimeZone = 'Atlantic Time (Canada)'
      break
    case 'America/Halifax':
      convertedTimeZone = 'Atlantic Time (Canada)'
      break
    case 'America/Inuvik':
      convertedTimeZone = 'Mountain Time (US & Canada)'
      break
    case 'America/Iqaluit':
      convertedTimeZone = 'Eastern Time (US & Canada)'
      break
    case 'America/Moncton':
      convertedTimeZone = 'Atlantic Time (Canada)'
      break
    case 'America/Nipigon':
      convertedTimeZone = 'Eastern Time (US & Canada)'
      break
    case 'America/Pangnirtung':
      convertedTimeZone = 'Eastern Time (US & Canada)'
      break
    case 'America/Rainy_River':
      convertedTimeZone = 'Central Time (US & Canada)'
      break
    case 'America/Rankin_Inlet':
      convertedTimeZone = 'Central Time (US & Canada)'
      break
    case 'America/Regina':
      convertedTimeZone = 'Central Time (US & Canada)'
      break
    case 'America/Resolute':
      convertedTimeZone = 'Central Time (US & Canada)'
      break
    case 'America/St_Johns':
      convertedTimeZone = 'Newfoundland'
      break
    case 'America/Swift_Current':
      convertedTimeZone = 'Central Time (US & Canada)'
      break
    case 'America/Thunder_Bay':
      convertedTimeZone = 'Eastern Time (US & Canada)'
      break
    case 'America/Toronto':
      convertedTimeZone = 'Eastern Time (US & Canada)'
      break
    case 'America/Vancouver':
      convertedTimeZone = 'Mountain Time (US & Canada)'
      break
    case 'America/Whitehorse':
      convertedTimeZone = 'Mountain Time (US & Canada)'
      break
    case 'America/Winnipeg':
      convertedTimeZone = 'Central Time (US & Canada)'
      break
    case 'America/Yellowknife':
      convertedTimeZone = 'Mountain Time (US & Canada)'
      break
    case 'Pacific/Midway':
      convertedTimeZone = 'Midway Island'
      break
    case 'Pacific/Pago_Pago':
      convertedTimeZone = 'American Samoa'
      break
    case 'Pacific/Honolulu':
    case 'America/Adak':
      convertedTimeZone = 'Hawaii'
      break
    case 'America/Barbados':
      convertedTimeZone = 'Atlantic Time (Barbados)'
      break
    case 'America/Cayman':
      convertedTimeZone = 'Eastern Time (Cayman)'
      break
    case 'America/Juneau':
    case 'America/Anchorage':
    case 'America/Yakutat':
    case 'America/Sitka':
    case 'America/Nome':
    case 'America/Metlakatla':
      convertedTimeZone = 'Alaska'
      break
    case 'America/Los_Angeles':
      convertedTimeZone = 'Pacific Time (US & Canada)'
      break
    case 'America/Tijuana':
      convertedTimeZone = 'Tijuana'
      break
    case 'America/Denver':
    case 'America/Boise':
    case 'America/North_Dakota/Center':
    case 'America/North_Dakota/New_Salem':
    case 'America/North_Dakota/Beulah':
      convertedTimeZone = 'Mountain Time (US & Canada)'
      break
    case 'America/Detroit':
      convertedTimeZone = 'Eastern Time (US & Canada)'
      break
    case 'America/Phoenix':
      convertedTimeZone = 'Arizona'
      break
    case 'America/Chihuahua':
      convertedTimeZone = 'Chihuahua'
      break
    case 'America/Mazatlan':
      convertedTimeZone = 'Mazatlan'
      break
    case 'America/Chicago':
      convertedTimeZone = 'Central Time (US & Canada)'
      break
    case 'America/Mexico_City':
      convertedTimeZone = 'Mexico City'
      break
    case 'America/Monterrey':
      convertedTimeZone = 'Monterrey'
      break
    case 'America/Guatemala':
      convertedTimeZone = 'Central America'
      break
    case 'America/New_York':
      convertedTimeZone = 'Eastern Time (US & Canada)'
      break
    case 'America/Indiana/Indianapolis':
    case 'America/Indiana/Vincennes':
      convertedTimeZone = 'Indiana (East)'
      break
    case 'America/Bogota':
      convertedTimeZone = 'Bogota'
      break
    case 'America/Lima':
      convertedTimeZone = 'Lima'
      break
    case 'America/Caracas':
      convertedTimeZone = 'Caracas'
      break
    case 'America/La_Paz':
      convertedTimeZone = 'La Paz'
      break
    case 'America/Santiago':
      convertedTimeZone = 'Santiago'
      break
    case 'America/Sao_Paulo':
      convertedTimeZone = 'Brasilia'
      break
    case 'America/Argentina/Buenos_Aires':
      convertedTimeZone = 'Buenos Aires'
      break
    case 'America/Montevideo':
      convertedTimeZone = 'Montevideo'
      break
    case 'America/Guyana':
      convertedTimeZone = 'Georgetown'
      break
    case 'America/Godthab':
      convertedTimeZone = 'Greenland'
      break
    case 'Atlantic/South_Georgia':
      convertedTimeZone = 'Mid-Atlantic'
      break
    case 'Atlantic/Azores':
      convertedTimeZone = 'Azores'
      break
    case 'Atlantic/Cape_Verde':
      convertedTimeZone = 'Cape Verde Is.'
      break
    case 'Europe/Dublin':
      convertedTimeZone = 'Dublin'
      break
    case 'Europe/Lisbon':
      convertedTimeZone = 'Lisbon'
      break
    case 'Europe/London':
      convertedTimeZone = 'London'
      break
    case 'Africa/Casablanca':
      convertedTimeZone = 'Casablanca'
      break
    case 'Africa/Monrovia':
      convertedTimeZone = 'Monrovia'
      break
    case 'Etc/UTC':
      convertedTimeZone = 'UTC'
      break
    case 'Europe/Belgrade':
      convertedTimeZone = 'Belgrade'
      break
    case 'Europe/Bratislava':
      convertedTimeZone = 'Bratislava'
      break
    case 'Europe/Budapest':
      convertedTimeZone = 'Budapest'
      break
    case 'Europe/Ljubljana':
      convertedTimeZone = 'Ljubljana'
      break
    case 'Europe/Prague':
      convertedTimeZone = 'Prague'
      break
    case 'Europe/Sarajevo':
      convertedTimeZone = 'Sarajevo'
      break
    case 'Europe/Skopje':
      convertedTimeZone = 'Skopje'
      break
    case 'Europe/Warsaw':
      convertedTimeZone = 'Warsaw'
      break
    case 'Europe/Zagreb':
      convertedTimeZone = 'Zagreb'
      break
    case 'Europe/Brussels':
      convertedTimeZone = 'Brussels'
      break
    case 'Europe/Copenhagen':
      convertedTimeZone = 'Copenhagen'
      break
    case 'Europe/Madrid':
      convertedTimeZone = 'Madrid'
      break
    case 'Europe/Paris':
      convertedTimeZone = 'Paris'
      break
    case 'Europe/Amsterdam':
      convertedTimeZone = 'Amsterdam'
      break
    case 'Europe/Berlin':
      convertedTimeZone = 'Berlin'
      break
    case 'Europe/Rome':
      convertedTimeZone = 'Rome'
      break
    case 'Europe/Stockholm':
      convertedTimeZone = 'Stockholm'
      break
    case 'Europe/Vienna':
      convertedTimeZone = 'Vienna'
      break
    case 'Africa/Algiers':
      convertedTimeZone = 'West Central Africa'
      break
    case 'Europe/Bucharest':
      convertedTimeZone = 'Bucharest'
      break
    case 'Africa/Cairo':
      convertedTimeZone = 'Cairo'
      break
    case 'Europe/Helsinki':
      convertedTimeZone = 'Helsinki'
      break
    case 'Europe/Kiev':
      convertedTimeZone = 'Kyiv'
      break
    case 'Europe/Riga':
      convertedTimeZone = 'Riga'
      break
    case 'Europe/Sofia':
      convertedTimeZone = 'Sofia'
      break
    case 'Europe/Tallinn':
      convertedTimeZone = 'Tallinn'
      break
    case 'Europe/Vilnius':
      convertedTimeZone = 'Vilnius'
      break
    case 'Europe/Athens':
      convertedTimeZone = 'Athens'
      break
    case 'Europe/Istanbul':
      convertedTimeZone = 'Istanbul'
      break
    case 'Europe/Minsk':
      convertedTimeZone = 'Minsk'
      break
    case 'Asia/Jerusalem':
      convertedTimeZone = 'Jerusalem'
      break
    case 'Africa/Harare':
      convertedTimeZone = 'Harare'
      break
    case 'Africa/Johannesburg':
      convertedTimeZone = 'Pretoria'
      break
    case 'Europe/Kaliningrad':
      convertedTimeZone = 'Kaliningrad'
      break
    case 'Europe/Moscow':
      convertedTimeZone = 'Moscow'
      break
    case 'Europe/Volgograd':
      convertedTimeZone = 'Volgograd'
      break
    case 'Europe/Samara':
      convertedTimeZone = 'Samara'
      break
    case 'Asia/Kuwait':
      convertedTimeZone = 'Kuwait'
      break
    case 'Asia/Riyadh':
      convertedTimeZone = 'Riyadh'
      break
    case 'Africa/Nairobi':
      convertedTimeZone = 'Nairobi'
      break
    case 'Asia/Baghdad':
      convertedTimeZone = 'Baghdad'
      break
    case 'Asia/Tehran':
      convertedTimeZone = 'Tehran'
      break
    case 'Asia/Muscat':
      convertedTimeZone = 'Muscat'
      break
    case 'Asia/Baku':
      convertedTimeZone = 'Baku'
      break
    case 'Asia/Tbilisi':
      convertedTimeZone = 'Tbilisi'
      break
    case 'Asia/Yerevan':
      convertedTimeZone = 'Yerevan'
      break
    case 'Asia/Kabul':
      convertedTimeZone = 'Kabul'
      break
    case 'Asia/Yekaterinburg':
      convertedTimeZone = 'Ekaterinburg'
      break
    case 'Asia/Karachi':
      convertedTimeZone = 'Karachi'
      break
    case 'Asia/Tashkent':
      convertedTimeZone = 'Tashkent'
      break
    case 'Asia/Kolkata':
      convertedTimeZone = 'Mumbai'
      break
    case 'Asia/Kathmandu':
      convertedTimeZone = 'Kathmandu'
      break
    case 'Asia/Dhaka':
      convertedTimeZone = 'Astana'
      break
    case 'Asia/Colombo':
      convertedTimeZone = 'Sri Jayawardenepura'
      break
    case 'Asia/Almaty':
      convertedTimeZone = 'Almaty'
      break
    case 'Asia/Novosibirsk':
      convertedTimeZone = 'Novosibirsk'
      break
    case 'Asia/Rangoon':
      convertedTimeZone = 'Rangoon'
      break
    case 'Asia/Bangkok':
      convertedTimeZone = 'Bangkok'
      break
    case 'Asia/Jakarta':
      convertedTimeZone = 'Jakarta'
      break
    case 'Asia/Krasnoyarsk':
      convertedTimeZone = 'Krasnoyarsk'
      break
    case 'Asia/Shanghai':
      convertedTimeZone = 'Beijing'
      break
    case 'Asia/Chongqing':
      convertedTimeZone = 'Chongqing'
      break
    case 'Asia/Hong_Kong':
      convertedTimeZone = 'Hong Kong'
      break
    case 'Asia/Urumqi':
      convertedTimeZone = 'Urumqi'
      break
    case 'Asia/Kuala_Lumpur':
      convertedTimeZone = 'Kuala Lumpur'
      break
    case 'Asia/Singapore':
      convertedTimeZone = 'Singapore'
      break
    case 'Asia/Taipei':
      convertedTimeZone = 'Taipei'
      break
    case 'Australia/Perth':
      convertedTimeZone = 'Perth'
      break
    case 'Australia/Eucla':
      convertedTimeZone = 'Australian Central Western Standard Time'
      break
    case 'Asia/Irkutsk':
      convertedTimeZone = 'Irkutsk'
      break
    case 'Asia/Ulaanbaatar':
      convertedTimeZone = 'Ulaanbaatar'
      break
    case 'Asia/Seoul':
      convertedTimeZone = 'Seoul'
      break
    case 'Asia/Tokyo':
      convertedTimeZone = 'Tokyo'
      break
    case 'Asia/Yakutsk':
      convertedTimeZone = 'Yakutsk'
      break
    case 'Australia/Darwin':
      convertedTimeZone = 'Darwin'
      break
    case 'Australia/Adelaide':
      convertedTimeZone = 'Adelaide'
      break
    case 'Australia/Melbourne':
      convertedTimeZone = 'Melbourne'
      break
    case 'Australia/Sydney':
      convertedTimeZone = 'Sydney'
      break
    case 'Australia/Brisbane':
      convertedTimeZone = 'Brisbane'
      break
    case 'Australia/Hobart':
      convertedTimeZone = 'Hobart'
      break
    case 'Asia/Vladivostok':
      convertedTimeZone = 'Vladivostok'
      break
    case 'Pacific/Guam':
      convertedTimeZone = 'Guam'
      break
    case 'Pacific/Port_Moresby':
      convertedTimeZone = 'Port Moresby'
      break
    case 'Asia/Magadan':
      convertedTimeZone = 'Magadan'
      break
    case 'Asia/Srednekolymsk':
      convertedTimeZone = 'Srednekolymsk'
      break
    case 'Pacific/Guadalcanal':
      convertedTimeZone = 'Solomon Is.'
      break
    case 'Pacific/Noumea':
      convertedTimeZone = 'New Caledonia'
      break
    case 'Pacific/Fiji':
      convertedTimeZone = 'Fiji'
      break
    case 'Asia/Kamchatka':
      convertedTimeZone = 'Kamchatka'
      break
    case 'Pacific/Majuro':
      convertedTimeZone = 'Marshall Is.'
      break
    case 'Pacific/Auckland':
      convertedTimeZone = 'Auckland'
      break
    case 'Pacific/Tongatapu':
      convertedTimeZone = "Nuku'alofa"
      break
    case 'Pacific/Fakaofo':
      convertedTimeZone = 'Tokelau Is.'
      break
    case 'Pacific/Chatham':
      convertedTimeZone = 'Chatham Is.'
      break
    case 'Pacific/Apia':
      convertedTimeZone = 'Samoa'
      break
    default:
      convertedTimeZone = ''
      break
  }

  return convertedTimeZone
}

export { timeZoneConverter }
