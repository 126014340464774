import axios from 'axios'

export const STARTFETCHING = 'FETCHING'
export const FETCHEDOAUTH = 'FETCHEDOAUTH'
export const FAILDFETCH = 'FAILDFETCH'

const startFetching = () => ({
  type: STARTFETCHING
})

const fetchedOAuth = data => ({
  type: FETCHEDOAUTH,
  payload: {
    ...data
  }
})

const faildFetch = error => ({
  type: FAILDFETCH,
  payload: {
    error
  }
})

export const OAuth = oauthData => dispatch => {
  dispatch(startFetching())
  axios
    .post('https://api.thryv.com/oauth/token', oauthData)
    .then(response => {
      const idTokenResponse = response.data.id_token
      let idToken = idTokenResponse.split('.')
      idToken.splice(0, 1)
      idToken.splice(1, 1)
      const base64 = window.atob(idToken)
      const decodedData = JSON.parse(base64)

      const responseAndDecodedData = {
        response: response.data,
        usersData: decodedData
      }
      localStorage.setItem('business_id', decodedData.business_id)
      localStorage.setItem('access_token', response.data.access_token)
      localStorage.setItem('business_name', decodedData.business_name)
      localStorage.setItem(
        'validation_access_token',
        response.data.access_token
      )
      dispatch(fetchedOAuth(responseAndDecodedData))
    })
    .catch(error => {
      dispatch(faildFetch(error))
    })
}
