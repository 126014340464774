import { Button, Header } from '@thryvlabs/maverick'
import { useNavigate } from 'react-router-dom'

import NavLayout from '../../components/nav-layout/nav-layout'
import {
  GBMSelectionContainer,
  MainContainer
} from '../../components/ui/gbm-style'

const GBMSelection = () => {
  const navigate = useNavigate()

  const handleYesButton = () => {
    navigate('/google')
  }

  const handleNoButton = () => {
    navigate('/gbm-no-selection')
  }

  return (
    <>
      <NavLayout title={`Google Business Messages`} showButton={false} />
      <MainContainer>
        <GBMSelectionContainer className="col-md-8 mx-auto">
          <Header fontWeight="medium" variant="h2">
            <span data-testid="gbm-selection-title-header">
              Do you have access to your Google Business Profile?
            </span>
          </Header>
          <div className="gbm-selection-button-container">
            <Button
              variant="primary"
              className="gbm-selection small-button"
              onClick={handleYesButton}
              data-testid="yes-button"
            >
              Yes
            </Button>

            <Button
              variant="primary"
              className="gbm-selection small-button"
              onClick={handleNoButton}
              data-testid="no-button"
            >
              No
            </Button>
          </div>
        </GBMSelectionContainer>
      </MainContainer>
    </>
  )
}

export default GBMSelection
