import { useNavigate } from 'react-router-dom'

import GoogleContactsLogo from '../../assets/googleContactsLogo.png'
import HomePageLayout from '../../components/home-page-layouts/home-page-layout'

import '../../css/App.css'

const GoogleContacts = () => {
  const navigate = useNavigate()
  const handleClick = e => {
    e.preventDefault()
    navigate('/google-contacts-settings')
  }
  const googleContactsSections = [
    {
      header: 'What will this application need access to?',
      statements: ['Your User details', 'Client details']
    },
    {
      header: 'What will this application do?',
      statements: [
        'Sync current Clients between Thryv and Google Contacts.',
        'Sync future Clients between Thryv and Google Contacts.'
      ]
    }
  ]

  return (
    <HomePageLayout
      sections={googleContactsSections}
      onSubmit={handleClick}
      appName="Google Contacts"
      appIconSource={GoogleContactsLogo}
      appIconStyle={{ marginLeft: '10px', height: '25vh' }}
    />
  )
}

export default GoogleContacts
