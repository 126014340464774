import { useEffect, useState } from 'react'

import { Header } from '@thryvlabs/maverick'

import logo from '../../assets/thryv_guy_alternative.png'
import NavLayout from '../../components/nav-layout/nav-layout'
import { InstagramJumbotron } from '../../components/ui/instagram-style'

const XeroSuccess = () => {
  const [seconds, setSeconds] = useState(10)

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000)
    } else {
      window.location.replace('https://go.thryv.com/app')
    }
  }, [seconds])

  return (
    <>
      <NavLayout title="Xero" />
      <InstagramJumbotron>
        <div className="next-instructions">
          <img src={logo} alt="Thryv Guy mascot" className="thryv-guy " />
          <Header fontWeight="bold" variant="h1">
            Congratulations! Your account is now connected.
          </Header>
          <Header fontWeight="light" variant="h3">
            We are busy syncing your data in the background. You are not
            required to do anything further. You will be notified by email once
            the Sync is complete.
          </Header>
          <Header fontWeight="light" variant="h5">
            You will be redirected to your Thryv Dashboard in {seconds}
          </Header>
        </div>
      </InstagramJumbotron>
    </>
  )
}

export default XeroSuccess
