import SettingsLayout from '../../components/settings-layout/settings-layout'
import { appIdConfig } from '../../util/integry-app-id'

const GoogleContactsSettings = () => {
  return (
    <SettingsLayout
      appName="Google Contacts"
      appKey={process.env.REACT_APP_INTEGRY_APP_KEY}
      appId={appIdConfig.googleContacts}
    />
  )
}

export default GoogleContactsSettings
